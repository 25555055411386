import React, { useState } from 'react';
import Input from '../Input';
import { useMobile } from '../MobileContext';
import Modal from '../Modal';

import { ReactComponent as CloseIcon } from '../images/x.svg';

const CancelSubscriptionModal = ({setCancelSubscriptionModalVisible, cancelSubscription}) => {
  const { isMobile } = useMobile();
  const [answer, setAnswer] = useState('');

  return (
    <Modal isOpen={true}>
      <div style={{
             background: 'linear-gradient(gray, transparent)',
             zIndex: '101',
             position: 'absolute',
             transform: 'translate(-50%, -50%)',
             top: '50%',
             left: '50%',
             width: isMobile ? '95%' : '900px',
             borderRadius: '10px',
           }}>
        <div className='modal'
             style={{
               margin: '1px',
               borderRadius: '10px',
               zIndex: '101',
               display: 'flex',
               justifyContent: 'center',
               flexDirection: 'column',
               padding: '20px',
             }}>
          <div style={{
                 display: 'flex',
                 flexDirection: 'row',
                 alignItems: 'center',
                 justifyContent: 'space-between',
                 width: '100%',
               }}>
            <h3>Are you sure you want to cancel the subscription</h3>
            <CloseIcon className='image'
                       style={{width: '20px',
                               height: '20px',
                              }}
                       onClick={() => {
                         setCancelSubscriptionModalVisible(false);
                       }}/>
          </div>

          <form onSubmit={(e) => {
                  e.preventDefault();

                  if (answer === 'Yes') {
                    cancelSubscription();
                  }

                  setCancelSubscriptionModalVisible(false);
                }}>

            <span> Are you sure you want to cancel the subscription? </span>
            <span> To confirm you wish to cancel the subscription, type "Yes" in the box below and click Cancel.</span>
            <span> The subscription will be cancelled after the end of the paid period.</span>
            <Input onChange={(e) => setAnswer(e.target.value)} />

            <div style={{display: 'flex',
                         flexDirection: 'row',
                         alignItems: 'center',
                         marginTop: '10px',
                         gap: '10px',
                         justifyContent: 'center',
                        }}>
              <button type="button"
                      style={{alignItems: 'center',
                              ...(isMobile ? { flexGrow: 1 } : { width: 'fit-content' }),
                              padding: '10px 20px',
                              border: '1px solid #e0e0e0',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              fontWeight: 'bold',
                              alignSelf: 'flex-end',
                              color: 'inherit',
                              backgroundColor: 'transparent',
                              fontSize: '18px',
                              height: '45px',
                             }}
                      onClick={() => {
                        setCancelSubscriptionModalVisible(false);
                      }}>
                No
              </button>
              <button type="submit" style={{
                        alignItems: 'center',
                        ...(isMobile ? { flexGrow: 1 } : { width: 'fit-content' }),
                        padding: '10px 20px',
                        backgroundColor: '#009de6',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        alignSelf: 'flex-end',
                        fontSize: '18px',
                        height: '45px',
                      }}>
                Yes
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default CancelSubscriptionModal;
