import React from 'react';

const Input = ({onChange, placeholder, type, icon, value, getWarning}) => {
  let [warning, setWarning] = React.useState('');

  return (
    <div
      style={{borderRadius: '5px',
              marginTop: '10px',
              overflow: 'auto',
              whiteSpace: 'pre-wrap',
              position: 'relative',
              width: '100%',
             }}>
      <input type={type ? type : 'text'}
             value={value}
             placeholder={placeholder}
             onChange={(event) => {
               if (getWarning) {
                 setWarning(getWarning(event.target.value));
               }
               onChange(event)
             }}
             style={{width: '100%',
                     boxSizing: 'border-box',
                     padding: '15px',
                     borderRadius: '5px',
                     border: '1px solid #d3d3d3',
                     outline: 'none',
                     fontSize: '16px',
                     fontFamily: 'inherit',
                    }}/>
      {icon && (
        <div style={{position: 'absolute',
                     right: '10px',
                     transform: 'translate(0, -50%)',
                     top: '50%',
                    }}>
          {icon}
        </div>
      )}
      {warning !== '' && (
        <span style={{fontSize: '13px',
                      color: 'red',
                      backgroundColor: 'white',
                     }}>
          {warning}
        </span>
      )}
    </div>
  );
}

export default Input;
