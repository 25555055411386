import React from 'react';
import PercentageBox from '../../PercentageBox';
import * as utils from '../../utils';

import { ReactComponent as ClickIcon } from '../../images/click.svg';
import { ReactComponent as HumanIcon } from '../../images/human.svg';
import { ReactComponent as BotIcon } from '../../images/bot.svg';

const TrafficCard = ({site}) => {
  let botTraffic = 0;
  let humanTraffic = 0;

  for (let i = 0; i < site.data.length; i++) {
    for (const value of Object.values(site.data[i])) {
      humanTraffic += value[0];
      botTraffic += value[1];
    }
  }

  let totalTraffic = botTraffic + humanTraffic;
  let humanPercentage = site.oldCounts ? utils.percentageIncrease(site.oldCounts[0], humanTraffic) : null;

  let botClickPercentage = 0;
  if (totalTraffic !== 0) {
    botClickPercentage = botTraffic / totalTraffic * 100;
  }
  let botPercentage = site.oldCounts ? utils.percentageIncrease(site.oldCounts[1], botTraffic) : null;

  return (
    <div className='gradient'
         style={{flexGrow: 1,
                 borderRadius: '10px',
                 display: 'flex',
                 flexDirection: 'column',
                 padding: '20px',
                 gap: '5px',
                }}>
      <span>TOTAL TRAFFIC</span>
      <div style={{display: 'flex',
                   flexDirection: 'row',
                   gap: '10px',
                   alignItems: 'center',
                   justifyContent: 'flex-start',
                  }}>
        <ClickIcon style={{fill: '#009de6',
                           width: '25px',
                          }} />
        <span style={{fontSize: '25px',
                      fontWeight: 'bold',
                     }}>
          {totalTraffic.toLocaleString()}
        </span>
      </div>

      <span>HUMAN TRAFFIC</span>
      <div style={{display: 'flex',
                   flexDirection: 'row',
                   gap: '10px',
                   alignItems: 'center',
                   justifyContent: 'flex-start',
                  }}>
        <HumanIcon style={{fill: '#009de6',
                           width: '25px',
                          }} />
        <span style={{fontSize: '25px',
                     }}>
          {humanTraffic.toLocaleString()}
        </span>
      </div>

      <div style={{display: 'flex',
                   flexDirection: 'row',
                   gap: '10px',
                   alignItems: 'center',
                  }}>
        <PercentageBox percentage={humanPercentage} />
        <span style={{fontSize: '14px',
                      color: '#888',
                     }}>
          vs last month
        </span>
      </div>

      <span>BOT TRAFFIC</span>
      <div style={{display: 'flex',
                   flexDirection: 'row',
                   gap: '10px',
                   alignItems: 'center',
                   justifyContent: 'flex-start',
                  }}>
        <BotIcon style={{fill: '#009de6',
                         width: '25px',
                        }} />
        <span style={{fontSize: '25px',
                      fontWeight: 'bold',
                     }}>
          {botTraffic.toLocaleString()}
        </span>
        <span style={{fontSize: '25px'}}>|</span>
        <span style={{fontSize: '25px',
                      fontWeight: 'bold',
                      color: '#d42c2c',
                     }}>
          {botClickPercentage.toFixed(2)}%
        </span>
      </div>

      <div style={{display: 'flex',
                   flexDirection: 'row',
                   gap: '10px',
                  }}>
        <PercentageBox percentage={botPercentage} />
        <span style={{fontSize: '14px',
                      color: '#888',
                     }}>
          vs last month
        </span>
      </div>
    </div>
  );
}

export default TrafficCard;
