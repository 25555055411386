import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSites } from '../SitesContext';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useTheme } from '../ThemeContext';
import Switch from '../Switch';
import Tab from './Tab';
import { useMobile } from '../MobileContext';

import { ReactComponent as DashboardIcon } from '../images/dashboard.svg';
import { ReactComponent as PlusIcon } from '../images/plus.svg';
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as HelpIcon } from '../images/help.svg';
import { ReactComponent as ProfileIcon } from '../images/user.svg';
import { ReactComponent as SettingsIcon } from '../images/settings.svg';
import { ReactComponent as XIcon } from '../images/x.svg';

const SidePanel = ({setPage, page, previousPage, setAddSiteModalVisible, sites}) => {
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const { isDarkMode, toggleTheme } = useTheme();
  const { reorderSites } = useSites();

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    reorderSites(source, destination);
  };

  return (
    <div style={{display: 'flex',
                 flexDirection: 'column',
                 height: '100%',
                 width: '100%',
                }}>

      <div style={{display: 'flex',
                   flexDirection: 'row',
                   justifyContent: 'space-between',
                   alignItems: 'center',
                   height: '30px',
                   padding: '0 20px',
                   marginBottom: '10px',
                   marginTop: '10px',
                   width: 'calc(100% - 40px)',
                  }}>
        <Logo className='image'
              style={{height: '100%',
                     }}
              onClick={() => navigate('/')} />

        {isMobile && (
          <XIcon className='image'
                 style={{height: '100%',
                        }}
                 onClick={() => setPage(previousPage)} />
        )}
      </div>

      <div style={{display: 'flex',
                   alignItems: 'center',
                   fontSize: '20px',
                   fontWeight: 'bold',
                   margin: '10px',
                  }}>
        <div className={page === 'overview' || isMobile ? 'selected-box' : 'unselected-box'}
             style={{
               display: 'flex',
               flexDirection: 'row',
               alignItems: 'center',
               padding: '10px 15px',
               gap: '10px',
               borderRadius: '5px',
               fontWeight: 'bold',
               cursor: 'pointer',
               width: '100%',
               boxSizing: 'border-box',
             }}
             onClick={() => setPage('overview')}>
          <DashboardIcon className='image'
                         style={{width: '25px',
                                 height: '25px'}} />
          <span>Dashboard</span>
        </div>
      </div>

      <div style={{
             display: 'flex',
             flexDirection: 'row',
             alignItems: 'center',
             justifyContent: 'space-between',
             fontWeight: 'bold',
             fontSize: '1.2em',
             width: 'calc(100% - 20px)',
             padding: '10px',
           }}>
        <p>Websites</p>
        <PlusIcon className='image'
                  style={{width: '25px',
                          height: '17px'}}
                  onClick={() => setAddSiteModalVisible(true)} />
      </div>

      <div style={{flexGrow: 1,
                   overflow: 'auto',
                  }}>
        <div style={{display: 'flex',
                     flexDirection: 'column',
                     gap: '10px',
                     padding: '10px 15px',
                     boxSizing: 'border-box',
                     width: '100%',
                     flexGrow: 1,
                     overflow: 'auto',
                     minHeight: 0
                    }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    overflowY: 'auto',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                  }}>
                  {sites.map((site, index) => (
                    <Draggable key={site.website}
                               draggableId={site.website}
                               index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}>
                          <Tab site={site}
                               dragHandleProps={provided.dragHandleProps}
                               setPage={setPage}
                               page={page}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>

      <div style={{selfAlign: 'flex-end',
                   flexShrink: 0,
                   display: 'flex',
                   flexDirection: 'column',
                   padding: '10px',
                  }}>
        {isMobile && (
          <>
            <div className='unselected-box'
                 style={{
                   display: 'flex',
                   alignItems: 'center',
                   padding: '10px 10px',
                   cursor: 'pointer',
                   boxSizing: 'border-box',
                   gap: '15px',
                   borderRadius: '5px',
                 }}
                 onClick={() => setPage('profile')}>
              <ProfileIcon className='image'
                           style={{width: '25px',
                                   height: '25px',
                                  }} />
              <span style={{fontWeight: 'bold',
                            fontSize: '20px',
                           }}>
                Profile
              </span>
            </div>

            <div className='unselected-box'
                 style={{
                   display: 'flex',
                   alignItems: 'center',
                   padding: '10px 10px',
                   cursor: 'pointer',
                   boxSizing: 'border-box',
                   gap: '15px',
                   borderRadius: '5px',
                 }}
                 onClick={() => setPage('settings')}>
              <SettingsIcon className='image'
                            style={{width: '25px',
                                    height: '25px',
                                   }} />
              <span style={{fontWeight: 'bold',
                            fontSize: '20px',
                           }}>
                Settings
              </span>
            </div>
          </>
        )}

        <div style={{display: 'flex',
                     flexDirection: isMobile ? 'column' : 'column-reverse',
                    }}>
          <div className={page === 'help' ? 'selected-box' : 'unselected-box'}
               style={{
                 display: 'flex',
                 alignItems: 'center',
                 padding: '10px 10px',
                 cursor: 'pointer',
                 boxSizing: 'border-box',
                 gap: '15px',
                 borderRadius: '5px',
               }}
               onClick={() => setPage('help')}>
            <HelpIcon className='image'
                      style={{width: '25px',
                              height: '25px',
                             }} />
            <span style={{fontWeight: 'bold',
                          fontSize: '20px',
                         }}>
              Help
            </span>
          </div>

          <div style={{
                 display: 'flex',
                 alignItems: 'center',
                 padding: '5px 10px',
                 cursor: 'pointer',
                 boxSizing: 'border-box',
                 gap: '10px',
               }}>
            <Switch checked={isDarkMode} onChange={toggleTheme}/>
            <span style={{color: isDarkMode ? 'white' : 'black',
                          fontWeight: 'bold',
                          fontSize: '18px',
                         }}>
              {isDarkMode ?  'Dark' : 'Light'} Mode
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SidePanel;
