import React from 'react';
import CheckBox from '../CheckBox';
import { useMobile } from '../MobileContext';
import Modal from '../Modal';

import { ReactComponent as CloseIcon } from '../images/x.svg';

const DownloadInvoicesModal = ({setInvoicesModalVisible}) => {
  const { isMobile } = useMobile();

  const [invoices, setInvoices] = React.useState([
    {name: 'invoice 1',
     date: '12/12/2024',
     size: '24 MB',
     checked: false,
    },
    {name: 'invoice 2',
     date: '12/12/2024',
     size: '24 MB',
     checked: false,
    },
    {name: 'invoice 3',
     date: '12/12/2024',
     size: '24 MB',
     checked: false,
    },
    {name: 'invoice 4',
     date: '12/12/2024',
     size: '24 MB',
     checked: false,
    },
    {name: 'invoice 5',
     date: '12/12/2024',
     size: '24 MB',
     checked: false,
    }]);

  return (
    <Modal isOpen={true}>
      <div style={{
             background: 'linear-gradient(gray, transparent)',
             zIndex: '101',
             position: 'absolute',
             transform: 'translate(-50%, -50%)',
             top: '50%',
             left: '50%',
             width: isMobile ? '95%' : '800px',
             borderRadius: '10px',
           }}>
        <div className='modal'
             style={{
               margin: '1px',
               borderRadius: '10px',
               zIndex: '101',
               display: 'flex',
               justifyContent: 'center',
               flexDirection: 'column',
               padding: '20px',
               boxSizing: 'border-box',
             }}>
          <div style={{
                 display: 'flex',
                 flexDirection: 'row',
                 alignItems: 'center',
                 justifyContent: 'space-between',
                 width: '100%',
               }}>
            <h3>Select the individual invoices to download</h3>
            <CloseIcon className='image'
                       style={{width: '20px',
                               height: '20px',
                              }}
                       onClick={() => {
                         setInvoicesModalVisible(false);
                       }}/>
          </div>

          <div style={{
                 display: 'flex',
                 padding: '20px',
                 width: '100%',
                 flexDirection: 'row',
                 justifyContent: 'space-between',
                 boxSizing: 'border-box',
                 borderBottom: '1px solid gray',
                 gap: isMobile ? '10px' : '30px',
               }}>
            <input type='checkbox'
                   style={{width: 'fit-content',
                           visibility: 'hidden',
                          }} />
            <span style={{width: '50%',
                         }}>
              Name
            </span>
            <span style={{width: '30%',
                         }}>
              Date
            </span>
            <span style={{width: '20%',
                         }}>
              Size
            </span>
          </div>

          <div style={{
                 display: 'flex',
                 padding: '20px',
                 width: '100%',
                 flexDirection: 'row',
                 justifyContent: 'space-between',
                 boxSizing: 'border-box',
                 borderBottom: '1px solid gray',
                 alignItems: 'center',
                 gap: isMobile ? '10px' : '30px',
               }}>
            <CheckBox
              checked={invoices.every((inv) => inv.checked)}
              onChange={(e) => {
                setInvoices(invoices.map((inv) => {
                  return {...inv, checked: e.target.checked};
                }))}} />

            <span style={{width: '50%',
                         }}>
              ALL
            </span>
            <span style={{width: '30%',
                         }}>
            </span>
            <span style={{width: '20%',
                         }}>
            </span>
          </div>

          {invoices.map((invoice, index) => {
            return (
              <div style={{
                     display: 'flex',
                     padding: '20px',
                     width: '100%',
                     flexDirection: 'row',
                     justifyContent: 'space-between',
                     boxSizing: 'border-box',
                     borderBottom: '1px solid gray',
                     gap: isMobile ? '10px' : '30px',
                   }}
                   key={index}>
                <CheckBox checked={invoice.checked}
                          onChange={() => {
                            setInvoices(invoices.map((inv, i) => {
                              if (i === index) {
                                return {...inv, checked: !inv.checked};
                              }
                              return inv;
                            }));
                          }} />

                <span style={{width: '50%',
                             }}>
                  {invoice.name}
                </span>
                <span style={{width: '30%',
                             }}>
                  {invoice.date}
                </span>
                <span style={{width: '20%',
                             }}>
                  {invoice.size}
                </span>
              </div>
            );
          })}

          <div style={{display: 'flex',
                       flexDirection: 'row',
                       alignItems: 'center',
                       marginTop: '10px',
                       gap: '10px',
                       justifyContent: 'center',
                      }}>
            <div style={{alignItems: 'center',
                         display: 'flex',
                         justifyContent: 'center',
                         ...(isMobile ? { flexGrow: 1 } : { width: 'fit-content' }),
                         padding: '10px 20px',
                         border: '1px solid #e0e0e0',
                         borderRadius: '5px',
                         cursor: 'pointer',
                         fontWeight: 'bold',
                         alignSelf: 'flex-end',
                        }}
                 onClick={() => {
                   setInvoicesModalVisible(false);
                 }}>
              Cancel
            </div>
            <div style={{alignItems: 'center',
                         display: 'flex',
                         justifyContent: 'center',
                         ...(isMobile ? { flexGrow: 1 } : { width: 'fit-content' }),
                         padding: '10px 20px',
                         backgroundColor: '#009de6',
                         color: 'white',
                         borderRadius: '5px',
                         cursor: 'pointer',
                         fontWeight: 'bold',
                         alignSelf: 'flex-end',
                        }}
                 onClick={() => {
                   console.log('download')
                   setInvoicesModalVisible(false);
                 }}>
              Download
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DownloadInvoicesModal;
