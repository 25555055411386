import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './ThemeContext';
import { SitesProvider } from './SitesContext';
import { DropDownProvider } from './DropDownContext';
import LogIn from './login/LogIn';
import SignUp from './login/SignUp';
import ResetPassword from './login/ResetPassword';
import Dashboard from './Dashboard';
import Layout from './Layout';
import { MobileProvider } from './MobileContext';

const App = () => {
  return (
    <MobileProvider>
      <DropDownProvider>
        <SitesProvider>
          <ThemeProvider>
            <Router>
              <Routes>
                <Route element={<Layout />}>
                  <Route path="/" element={<LogIn/>} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                </Route>
                <Route path="/dashboard" element={<Dashboard />} />
              </Routes>
            </Router>
          </ThemeProvider>
        </SitesProvider>
      </DropDownProvider>
    </MobileProvider>
  );
}

export default App;
