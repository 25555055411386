import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PasswordInput from './PasswordInput';
import ImageCarousel from './ImageCarousel';
import { useMobile } from '../MobileContext';

const ResetPassword = () => {
  const { isMobile } = useMobile();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const confirm = () => {
    if (password === confirmPassword) {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');

      fetch(process.env.REACT_APP_SERVER_URL + '/api/set-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify({ password })
      })
        .then(response => response.json())
        .then(data => {
          if (data.message === 'invalid token!') {
            console.log('unauthorized access')
            localStorage.removeItem('token');
          }
          navigate('/');
        })
        .catch(error => console.error('Error:', error));
    }
  }

  return (
    <div style={{display: 'flex',
                 flexDirection: 'column',
                 backgroundColor: 'white',
                 height: '100%',
                }}>
      <div style={{flex: 1,
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'space-around',
                   color: 'black',
                   padding: isMobile ? '20px' : '80px',
                   gap: '50px',
                  }}>

        <form style={{width: isMobile ? '100%' : '30%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '50px',
                     }}
              onSubmit={(e) => {
                e.preventDefault();
                confirm();
              }}>
          <h1>Reset Password</h1>

          <div>
            <span>New Password</span>
            <PasswordInput placeholder='Enter new password'
                           showWarning={true}
                           onChange={(e) => {
                             setPassword(e.target.value);
                           }}/>
          </div>

          <div>
            <span>Confirm Password</span>
            <PasswordInput placeholder='Confirm new password'
                           showWarning={true}
                           target={password}
                           onChange={(e) => {
                             setConfirmPassword(e.target.value);
                           }}/>
          </div>

          <div style={{display: 'flex',
                       width: '100%',
                       justifyContent: 'center',
                      }}>
            <button style={{width: '300px',
                            padding: '15px',
                            backgroundColor: '#008fe9',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            fontFamily: 'inherit',
                           }}>
              Confirm
            </button>
          </div>
        </form>

        {!isMobile && <ImageCarousel/>}
      </div>
    </div>
  );
}

export default ResetPassword;
