import React, { useState, useEffect } from 'react';
import Separator from '../Separator';
import { useMobile } from '../MobileContext';
import { useSites } from '../SitesContext';
import * as utils from '../utils';

import { ReactComponent as CheckIcon } from '../images/check.svg';
import { ReactComponent as InfoIcon } from '../images/info.svg';
import { ReactComponent as Spinner } from '../images/spinner.svg';

const Item = ({text}) => {
  return (
    <div style={{display: 'flex',
                 alignItems: 'center',
                 gap: '10px',
                }}>
      <CheckIcon style={{width: '25px',
                         height: '25px',
                        }} />
      {text}
    </div>
  );
}

const PlanCard = ({setSubscriptionsModalVisible, subscription}) => {
  const { isMobile } = useMobile();
  const [popupVisible, setPopupVisible] = useState(false);
  const [amountDue, setAmountDue] = useState(0);

  const getFormatedDate = (date) => {
    const d = new Date(date);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  }

  const { getTotalSince } = useSites();

  useEffect (() => {
    if (!subscription) return

    let totalClicks = getTotalSince(new Date(subscription.created_at))
    setAmountDue(utils.getPrice(totalClicks))
  }, [subscription, getTotalSince])

  return (
    <div className='border-gradient'
         style={{flex: 1,
                 width: '100%',
                 height: '100%',
                 borderRadius: '10px',
                }}>
      <div className="gradient"
           style={{borderRadius: '10px',
                   height: '100%',
                   display: 'flex',
                   flexDirection: isMobile ? 'column' : 'row',
                   gap: '10px',
                   justifyContent: 'space-between',
                   margin: '1px',
                  }}>
        <div style={{width: 'calc(100% - 40px)',
                     display: 'flex',
                     flexDirection: 'column',
                     justifyContent: 'space-between',
                     gap: '10px',
                     boxSizing: 'border-box',
                     margin: '20px',
                    }}>

          {subscription === null && (
            <div style={{
                   textAlign: 'center',
                   color: 'white',
                   height: '100%',
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'center',
                 }}>
              <Spinner style={{
                         margin: '0 auto',
                         width: '50px',
                         height: '50px',
                         animation: 'spin 1s linear infinite',
                       }} />
            </div>
          )}

          {subscription && !subscription.cap && (
            <span style={{height: '100%',
                         }}>
              No subscription active
            </span>
          )}

          {subscription && subscription.cap && (
            <>
              <div>
                <span style={{fontWeight: 'bold',
                              fontSize: '25px',
                             }}>
                  ${amountDue}
                </span>
              </div>

              <span style={{fontSize: '20px',
                           }}>

                Ends: {getFormatedDate(subscription.expiry)}
              </span>

              <div style={{position: 'relative'}}>
                <div className='cap-box'
                     style={{width: '100%',
                             borderRadius: '5px',
                             padding: '10px',
                             display: 'flex',
                             flexDirection: 'column',
                             gap: '5px',
                             position: 'relative',
                             boxSizing: 'border-box',
                            }}>
                  <span>MONTHLY CAP</span>
                  <span>${subscription.cap}</span>
                  <InfoIcon style={{width: '25px',
                                    height: '25px',
                                    fill: 'gray',
                                    position: 'absolute',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    right: '10px',
                                    cursor: 'pointer',
                                   }}
                            onMouseEnter={() => setPopupVisible(true)}
                            onMouseLeave={() => setPopupVisible(false)}
                  />
                </div>
                {popupVisible && (
                  <span style={{position: 'absolute',
                                top: '100%',
                                left: '0',
                                fontSize: '12px',
                                color: '#666',
                                width: '100%',
                                textAlign: 'left',
                                padding: '5px',
                                backgroundColor: '#f9f9f9',
                                borderRadius: '5px',
                                zIndex: '100',
                               }}>
                    Info about monthly cap
                  </span>
                )}
              </div>
            </>
          )}

          {subscription && (
            <>
              <span style={{fontSize: '14px',
                            color: '#999',
                           }}>
                * Please contact team@polygraph.net for subscription details.
              </span>

              <div style={{backgroundColor: '#009de6',
                           color: 'white',
                           width: '100%',
                           borderRadius: '5px',
                           padding: '10px',
                           display: 'flex',
                           flexDirection: 'column',
                           gap: '5px',
                           position: 'relative',
                           alignItems: 'center',
                           fontWeight: 'bold',
                           cursor: 'pointer',
                           boxSizing: 'border-box',
                          }}
                   onClick={() => {
                     setSubscriptionsModalVisible(true);
                   }}>
                Update
              </div>
            </>
          )}
        </div>

        <div>
          <Separator vertical={!isMobile}/>
        </div>

        <div style={{width: 'calc(100% - 40px)',
                     padding: '20px',
                     display: 'flex',
                     flexDirection: 'column',
                     justifyContent: 'center'}}>
          <div style={{
                 display: 'flex',
                 flexDirection: 'column',
                 gap: '15px'}}>
            <Item text="25,000 ad clicks per month" />
            <Item text="Monitor unlimited websites" />
            <Item text="Apply for click fraud refunds" />
            <Item text="Detect click fraud" />
            <Item text="Prevent click fraud" />
            <Item text="Dedicated account manager" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanCard;
