import React, { useState } from 'react';
import { useSites } from '../SitesContext';
import Input from '../Input';
import { useMobile } from '../MobileContext';
import Modal from '../Modal';

import { ReactComponent as CloseIcon } from '../images/x.svg';

const DeleteSiteModal = ({setDeleteSiteModalVisible, site}) => {
  const { isMobile } = useMobile();
  const { deleteSite } = useSites();
  const [answer, setAnswer] = useState('');

  return (
    <Modal isOpen={true}>
      <div style={{
             background: 'linear-gradient(gray, transparent)',
             zIndex: '101',
             position: 'absolute',
             transform: 'translate(-50%, -50%)',
             top: '50%',
             left: '50%',
             width: isMobile ? '95%' : '800px',
             borderRadius: '10px',
           }}>
        <div className='modal'
             style={{
               margin: '1px',
               borderRadius: '10px',
               zIndex: '101',
               display: 'flex',
               justifyContent: 'center',
               flexDirection: 'column',
               padding: '20px',
             }}>
          <div style={{
                 display: 'flex',
                 flexDirection: 'row',
                 alignItems: 'center',
                 justifyContent: 'space-between',
                 width: '100%',
               }}>
            <h2>Delete Website</h2>
            <CloseIcon className='image'
                       style={{width: '20px',
                               height: '20px',
                              }}
                       onClick={() => {
                         setDeleteSiteModalVisible(false);
                       }}/>
          </div>

          <form onSubmit={(e) => {
                  e.preventDefault();
                  setDeleteSiteModalVisible(false);
                  if (answer === 'Yes') {
                    deleteSite(site);
                  }
                }}
                style={{display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'}}>
            <span> Are you sure you want to delete <span style={{fontWeight: 'bold'}}>{site.website}</span>?</span>
            <span> To confirm you wish to delete this website and all its corresponding data, type "Yes" in the box below and click Delete.</span>
            <Input onChange={(e) => setAnswer(e.target.value)} />

            <div style={{display: 'flex',
                         flexDirection: 'row',
                         alignItems: 'center',
                         marginTop: '10px',
                         gap: '10px',
                         justifyContent: 'center',
                        }}>
              <button type="button"
                      style={{alignItems: 'center',
                              ...(isMobile ? { flexGrow: 1 } : { width: 'fit-content' }),
                              padding: '10px 20px',
                              border: '1px solid #e0e0e0',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              fontWeight: 'bold',
                              alignSelf: 'flex-end',
                              color: 'inherit',
                              backgroundColor: 'transparent',
                              fontSize: '18px',
                              height: '45px',
                             }}
                      onClick={() => {
                        setDeleteSiteModalVisible(false);
                      }}>
                Cancel
              </button>
              <button type="submit" style={{
                        alignItems: 'center',
                        ...(isMobile ? { flexGrow: 1 } : { width: 'fit-content' }),
                        padding: '10px 20px',
                        backgroundColor: '#009de6',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        alignSelf: 'flex-end',
                        fontSize: '18px',
                        height: '45px',
                      }}>
                Delete Website
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteSiteModal;
