import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MessageModal from './profile/MessageModal';
import AccountButtons from './AccountButtons';
import Separator from './Separator';
import AddExtraUserModal from './profile/AddExtraUserModal';
import UserCard from './profile/UserCard';
import ExtraUserCard from './profile/ExtraUserCard';
import { ReactComponent as Spinner } from './images/spinner.svg';
import { useMobile } from './MobileContext';

import { ReactComponent as LogoutIcon } from './images/logout.svg';
import { ReactComponent as PlusIcon } from './images/plus.svg';

const Profile = ({setPage}) => {
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  let [users, setUsers] = useState([]);
  let [modalVisible, setModalVisible] = useState(false);
  let [message, setMessage] = React.useState(null);
  let [primary, setPrimary] = React.useState(null);

  const showMessage = (title, message, color) => {
    setMessage({
      title,
      message,
      color
    });

    setTimeout(() => {
      setMessage(null);
    }, 1000);
  }

  useEffect(() => {
    const token = localStorage.getItem('token');

    fetch(process.env.REACT_APP_SERVER_URL + '/api/get-users', {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setUsers(data.users);
          setPrimary(data.primary);
        }
      })
      .catch(error => console.log(error));
  }, []);

  const addExtraUser = (email, password) => {
    const token = localStorage.getItem('token');

    fetch(process.env.REACT_APP_SERVER_URL + '/api/add-extra-user', {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setUsers([...users, {email}]);
          showMessage('User successfully added', 'New user successfully added', 'green');
        }
        else {
          showMessage('User could not be added', 'Something went wrong, user was not added. Try again later.', 'red');
        }
      })
      .catch(error => {
        console.log(error);
        showMessage('User could not be added', 'Something went wrong, user was not added. Try again later.', 'red');
      });
  };

  const deleteExtraUser = (index) => {
    const token = localStorage.getItem('token');
    let user = users[index + 1];
    let id = user.id;

    fetch(process.env.REACT_APP_SERVER_URL + '/api/delete-extra-user', {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          let newUsers = users.filter((user, i) => i !== index + 1);
          setUsers(newUsers);
          showMessage('User successfully deleted', 'User successfully deleted', 'green');
        }
        else {
          showMessage('User could not be deleted', 'Something went wrong, user was not deleted. Try again later.', 'red');
        }
      })
      .catch(error => {
        console.log(error);
        showMessage('User could not be deleted', 'Something went wrong, user was not deleted. Try again later.', 'red');
      });
  };

  const saveChanges = () => {
    const token = localStorage.getItem('token');

    if (primary) {
      fetch(process.env.REACT_APP_SERVER_URL + '/api/update-users', {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({users})
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            showMessage('Changes successfully saved',
                        'Your personal information was successfully updated',
                        'green');
          }
          else {
            showMessage('Changes could not be saved',
                        'Something went wrong, changes were not saved. Try again later.',
                        'red');
          }
        })
        .catch(error => {
          console.log(error);
          showMessage('Changes could not be saved',
                      'Something went wrong, changes were not saved. Try again later.',
                      'red');
        });
    }
    else {
      fetch(process.env.REACT_APP_SERVER_URL + '/api/update-extra-user', {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(users[0]),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            showMessage('Changes successfully saved',
                        'Your personal information was successfully updated',
                        'green');
          }
          else {
            showMessage('Changes could not be saved',
                        'Something went wrong, changes were not saved. Try again later.',
                        'red');
          }
        })
        .catch(error => {
          console.log(error);
          showMessage('Changes could not be saved',
                      'Something went wrong, changes were not saved. Try again later.',
                      'red');
        });
    }
  };

  if (users.length === 0) {
    return (
      <div style={{
             width: '100%',
             height: '100vh',
             alignItems: 'center',
             display: 'flex',
             justifyContent: 'center',
           }}>
        <div style={{
               textAlign: 'center',
               color: 'white',
             }}>
          <Spinner style={{
                     margin: '0 auto',
                     width: '80px',
                     height: '80px',
                     animation: 'spin 1s linear infinite',
                   }} />
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div style={{width: 'calc(100% - 40px)',
                 overflowY: 'auto',
                 padding: '20px',
                }}>

      {modalVisible && <AddExtraUserModal setVisible={setModalVisible}
                                          addUser={addExtraUser} />}
      {message && (
        <MessageModal title={message.title}
                      message={message.message}
                      color={message.color} />
      )}

      {!isMobile && (
        <div style={{width: '100%'}}>
          <div style={{ width: '100%',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                      }}>
            <AccountButtons setPage={setPage} selected='profile' />
          </div>
        </div>
      )}

      <div style={{width: '100%',
                   display: 'flex',
                   overflow: 'auto',
                  }}>
        <div style={{flexGrow: 1}}>
          {primary && (
            <div style={{display: 'flex',
                         flexDirection: 'column',
                         gap: '10px',
                         alignItems: 'center',
                         justifyContent: 'space-between',
                         width: '100%',
                        }}>
              <div style={{display: 'flex',
                           flexDirection: 'row',
                           gap: '10px',
                           alignItems: 'center',
                           justifyContent: 'space-between',
                           width: '100%',
                          }}>
                <h3>Account Details</h3>
                <PlusIcon className='image'
                          style={{width: '20px',
                                  height: '20px',
                                 }}
                          onClick={() => setModalVisible(true)} />
              </div>

              <UserCard user={users[0]}
                        setUser={(user) => setUsers([user, ...users.slice(1)])} />

              {users.slice(1).map((user, index) => {
                return (
                  <div key={index} style={{width: '100%'}}>
                    <Separator />
                    <ExtraUserCard user={user}
                                   index={index}
                                   deleteUser={deleteExtraUser}
                                   setUser={(user) => {
                                     let newUsers = [...users];
                                     newUsers[index + 1] = user;
                                     setUsers(newUsers);
                                   }}
                    />
                  </div>
                );
              })}
            </div>
          )}

          {!primary && (
            <ExtraUserCard user={users[0]}
                           index={0}
                           deleteUser={null}
                           setUser={(user) => setUsers([user]) } />
          )}

          {isMobile && (
            <div style={{display: 'flex',
                         flexDirection: 'column',
                         gap: '10px',
                        }}>
              <div style={{
                     display: 'flex',
                     alignItems: 'center',
                     width: '100%',
                     padding: '15px 20px',
                     border: '1px solid #e0e0e0',
                     borderRadius: '5px',
                     cursor: 'pointer',
                     boxSizing: 'border-box',
                     gap: '10px',
                     alignSelf: 'flex-end',
                     justifyContent: 'center',
                     marginTop: '10px',
                   }}
                   onClick={saveChanges}>
                <span>Save Changes</span>
              </div>

              <div style={{
                     display: 'flex',
                     alignItems: 'center',
                     width: '100%',
                     padding: '15px 20px',
                     border: '1px solid #e0e0e0',
                     borderRadius: '5px',
                     cursor: 'pointer',
                     boxSizing: 'border-box',
                     gap: '10px',
                     alignSelf: 'flex-end',
                     justifyContent: 'center',
                   }}
                   onClick={() => {
                     localStorage.removeItem('token');
                     navigate('/');
                   }}>
                <span>Log Out</span>
                <LogoutIcon className='image'
                            style={{width: '20px',
                                    height: '20px',
                                   }}/>
              </div>
            </div>
          )}
        </div>

        {!isMobile && (
          <>
            <div style={{width: '400px',
                         position: 'relative',
                        }}>
            </div>

            <div style={{width: '400px',
                         position: 'absolute',
                         bottom: '10px',
                         right: '10px',
                         display: 'flex',
                         flexDirection: 'row-reverse',
                         gap: '10px',
                        }}>

              <div style={{
                     display: 'flex',
                     alignItems: 'center',
                     height: 'fit-content',
                     padding: '5px 20px',
                     border: '1px solid #e0e0e0',
                     borderRadius: '5px',
                     cursor: 'pointer',
                     boxSizing: 'border-box',
                     gap: '10px',
                     alignSelf: 'flex-end',
                   }}
                   onClick={() => {
                     localStorage.removeItem('token');
                     navigate('/');
                   }}>
                <span>Log Out</span>
                <LogoutIcon className='image'
                            style={{width: '20px',
                                    height: '20px',
                                   }}/>
              </div>

              <div style={{
                     display: 'flex',
                     alignItems: 'center',
                     height: 'fit-content',
                     padding: '5px 20px',
                     border: '1px solid #e0e0e0',
                     borderRadius: '5px',
                     cursor: 'pointer',
                     boxSizing: 'border-box',
                     gap: '10px',
                     alignSelf: 'flex-end',
                   }}
                   onClick={saveChanges}>
                <span>Save Changes</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>

  );
};

export default Profile;
