import React from 'react';
import { useMobile } from '../MobileContext';
import Modal from '../Modal';

const VerifyModal = ({setVisible}) => {
  const { isMobile } = useMobile();
  return (
    <Modal isOpen={true}>
      <div style={{zIndex: '101',
                   backgroundColor: 'white',
                   color: 'black',
                   position: 'absolute',
                   transform: 'translate(-50%, -50%)',
                   top: '50%',
                   left: '50%',
                   margin: '1px',
                   width: isMobile ? '95%' : '600px',
                   boxSizing: 'border-box',
                   borderRadius: '5px',
                   display: 'flex',
                   justifyContent: 'center',
                   flexDirection: 'column',
                   padding: '40px',
                   gap: '20px',
                  }}>
        <div style={{
               display: 'flex',
               flexDirection: 'row',
               alignItems: 'center',
               justifyContent: 'space-between',
               width: '100%',
             }}>
          <span style={{fontSize: '22px',
                        fontWeight: 'bold',
                       }}>
            Verify your account
          </span>
        </div>
        <span style={{color: 'gray'}}>
          Please check your email with instructions
        </span>

      </div>
    </Modal>
  );
}

export default VerifyModal;
