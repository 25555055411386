import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSites } from '../SitesContext';
import ForgotPasswordModal from './ForgotPasswordModal';
import Input from './Input';
import PasswordInput from './PasswordInput';
import ImageCarousel from './ImageCarousel';
import Loading from './Loading';
import { useMobile } from '../MobileContext';

const LogIn = () => {
  const { isMobile } = useMobile();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { fetchSites } = useSites();
  const [loading, setLoading] = useState(false);
  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token !== null) {
      fetch(process.env.REACT_APP_SERVER_URL + '/api/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify({})
      })
        .then(response => response.json())
        .then(data => {
          if (data.message === 'invalid token!') {
            console.log('unauthorized access')
            localStorage.removeItem('token');
          }
          localStorage.setItem('token', token);
          navigate('/dashboard');
        })
        .catch(error => console.error('Error:', error));
    }
    else {
      let token = localStorage.getItem('token');

      if (token) {
        fetch(process.env.REACT_APP_SERVER_URL + '/api/validate-token', {
          method: 'POST',
          headers: {
            'Authorization': token
          },
        })
          .then(response => response.json())
          .then(data => {
            if (data.success) {
              fetchSites().then(() => {
                setLoading(true);
                console.log('fetched!');
                navigate('/dashboard');
              });
            }
            else if (data.message === 'invalid token!') {
              console.log('unauthorized access')
              localStorage.removeItem('token');
              navigate('/');
            }
          })
          .catch(error => {
            console.log('Automatic login failed');
            localStorage.removeItem('token');
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = () => {
    setLoading(true);

    fetch(process.env.REACT_APP_SERVER_URL + '/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: email.trim(),
                             password: password.trim() })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          localStorage.setItem('token', data.token);
          fetchSites().then(() => {
            console.log('fetched!');
            navigate('/dashboard');
          });
        } else {
          alert('Login failed');
          setEmail('');
          setPassword('');
          setLoading(false);
        }
      })
      .catch(error => console.error('Error:', error));
  };

  return (
    <div style={{display: 'flex',
                 flexDirection: 'column',
                 backgroundColor: 'white',
                 height: '100%',
                }}>
      {forgotPasswordModalVisible && (
        <ForgotPasswordModal setVisible={setForgotPasswordModalVisible} />
      )}

      {loading && <Loading text="Loading data..." />}

      <div style={{flex: 1,
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'space-around',
                   color: 'black',
                   padding: isMobile ? '20px' : '80px',
                   gap: '50px',
                  }}>

        <form style={{width: isMobile ? '100%' : '30%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '50px',
                     }}
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}>
          <h1>Log In</h1>

          <div>
            <span>Email</span>
            <Input onChange={(e) => setEmail(e.target.value)}
                   value={email}
                   type='email'
                   placeholder='Enter your email'/>
          </div>

          <div>
            <span>Password</span>
            <PasswordInput onChange={(e) => setPassword(e.target.value)}
                           value={password}
                           placeholder='Enter your password'/>
          </div>

          <div style={{display: 'flex',
                       justifyContent: 'flex-end',
                      }}>
            <style>
              {`.custom-link2:link {
                   color: black;
                 }
                 .custom-link2:visited {
                   color: black;
                 }
                 .custom-link2:hover {
                   color: gray;
                 }
                 .custom-link2:active {
                   color: gray;
                 }
                 .custom-link2 {
                   text-decoration: underline;
                   text-underline-offset: 5px;
                 }
               `}
            </style>
            <span className='custom-link2'
                  style={{cursor: 'pointer'}}
                  onClick={() => setForgotPasswordModalVisible(true)}>
              Forgot password?
            </span>
          </div>

          <div style={{display: 'flex',
                       width: '100%',
                       justifyContent: 'center',
                      }}>
            <button style={{width: '300px',
                            padding: '15px',
                            backgroundColor: '#008fe9',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            fontFamily: 'inherit',
                           }}>
              Log In
            </button>
          </div>

          <div style={{display: 'flex',
                       justifyContent: 'center',
                       gap: '5px',
                       alignItems: 'center',
                       color: 'black'
                      }}>
            <style>
              {`.custom-link:link {
                   color: #008fe9;
                 }
                 .custom-link:visited {
                   color: #008fe9;
                 }
                 .custom-link:hover {
                   color: #0056b3;
                 }
                 .custom-link:active {
                   color: #0056b3;
                 }
                 .custom-link {
                   text-decoration: underline;
                   text-underline-offset: 5px;
                 }
               `}
            </style>
            Don't have an account? <Link className='custom-link'
                                         to='/signup'>Create Account</Link>
          </div>

        </form>

        {!isMobile && <ImageCarousel/>}
      </div>
    </div>
  );
}

export default LogIn;
