import React, { useEffect } from 'react';
import TrafficGraph from './analytics/TrafficGraph';
import Graph from './analytics/Graph';
import { useDropDown } from '../DropDownContext';
import { useMobile } from '../MobileContext';

const Analytics = ({site}) => {
  const { isMobile } = useMobile();
  const { getSelectedOption } = useDropDown();
  const [data, setData] = React.useState([]);

  useEffect(() => {
    let option = getSelectedOption('period');
    if (option) {
      let trafficData = {
        title: 'TRAFFIC',
        values: {
          human: site.data.map(entry => {
            return Object.values(entry).map(x => x[0]).reduce((a, b) => a + b, 0);
          }),

          bot: site.data.map(entry => {
            return Object.values(entry).map(x => x[1]).reduce((a, b) => a + b, 0);
          }),
          old: site.oldCounts,
        },
        lastDay: site.lastDay,
      }

      function getCampaignTotal (entry, index) {
        let total = 0;
        for (let key of Object.keys(entry)) {
          let campaignIndex = parseInt(key.split('-')[0]);
          if (campaignIndex === index) {
            total += entry[key][1];
          }
        }
        return total;
      }

      function getPlatformTotal (entry, index) {
        let total = 0;
        for (let key of Object.keys(entry)) {
          let platformIndex = parseInt(key.split('-')[1]);
          if (platformIndex === index) {
            total += entry[key][1];
          }
        }
        return total;
      }

      let platformData = {
        title: 'PLATFORM',
        values: site.platforms.map((platform, index) => {
          return {name: platform,
                  data: site.data.map(entry => {
                    return getPlatformTotal(entry, index);
                  })}
        }),
        lastDay: site.lastDay,
      }

      let campaignData = {
        title: 'CAMPAIGN',
        values: site.campaigns.map((campaign, index) => {
          return {name: campaign,
                  data: site.data.map(entry => {
                    return getCampaignTotal(entry, index);
                  })}
        }),
        lastDay: site.lastDay,
      }

      setData([trafficData, platformData, campaignData]);
    }
  }, [site, getSelectedOption]);

  return (
    <>
      {data.length > 0 && (
        <>
          <h3>Analytics</h3>
          <div style={{display: 'flex',
                       flexDirection: 'column',
                       gap: '20px',
                       boxSizing: 'border-box',
                       marginBottom: '50px',
                      }}>
            <div style={{display: 'flex',
                         flexDirection: 'row',
                         boxSizing: 'border-box',
                         gap: '20px',
                         borderRadius: '10px',
                         height: 'fit-content',
                        }}>
              <TrafficGraph specs={data[0]} />
            </div>
            <div style={{height: isMobile ? '800px' : '400px',
                         display: 'flex',
                         flexDirection: isMobile ? 'column' : 'row',
                         gap: '20px',
                        }}>
              <div style={{width: '100%',
                           height: '100%',
                          }}>
                <Graph specs={data[1]} />
              </div>
              <div style={{width: '100%',
                           height: '100%',
                          }}>
                <Graph specs={data[2]} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Analytics;
