import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { useTheme } from '../../ThemeContext'
import { useMobile } from '../../MobileContext';

function computeDateLabels(lastDay, count) {
  const labels = [];
  const oneDay = 24 * 60 * 60 * 1000;
  let [day, month, year] = lastDay;
  let end = new Date(year, month, day);

  let start = new Date(end);
  start.setDate(start.getDate() - count + 1);

  let current = new Date(start);

  while (current <= end) {
    const next = new Date(current.getTime() + 4 * oneDay);
    const rangeEnd = next < end ? next : end;

    const startLabel = current.toLocaleDateString('en-US', { month: 'short',
                                                             day: '2-digit' });
    const endLabel = rangeEnd.toLocaleDateString('en-US', { month: 'short',
                                                            day: '2-digit' });

    labels.push(`${startLabel} - ${endLabel}`);
    current = new Date(rangeEnd.getTime() + oneDay);
  }

  return labels;
}

function findDateRange(dateString, ranges) {
  const parsedDate = new Date(dateString);
  let day = parsedDate.getDate();
  let month = parsedDate.getMonth();
  let year = parsedDate.getFullYear();
  let targetDate = new Date(year, month, day);

  for (const range of ranges) {
    const [startStr, endStr] = range.split(' - ');
    const startDate = new Date(`${startStr} ${targetDate.getFullYear()}`);
    const endDate = new Date(`${endStr} ${targetDate.getFullYear()}`);

    if (startDate > endDate) {
      endDate.setFullYear(endDate.getFullYear() + 1);
    }

    if (targetDate >= startDate && targetDate <= endDate) {
      return range;
    }
  }
  return null;
}

const getOption = (specs, isDarkMode, isMobile) => {
  const {values, lastDay} = specs;

  const botClicks = values.bot;
  const humanClicks = values.human;
  const totalBotClicks = botClicks.reduce((acc, curr) => acc + curr, 0);
  const totalHumanClicks = humanClicks.reduce((acc, curr) => acc + curr, 0);
  const botPercentage = (totalBotClicks / (totalBotClicks + totalHumanClicks)) * 100;
  const humanPercentage = (totalHumanClicks / (totalBotClicks + totalHumanClicks)) * 100;

  let humanLabel = `Human Traffic: ${totalHumanClicks} | ${humanPercentage.toFixed(2)}%`;
  let botLabel = `Bot Traffic: ${totalBotClicks} | ${botPercentage.toFixed(2)}%`

  let bins = computeDateLabels(lastDay, values.bot.length);

  let human = {};
  let bot = {};

  for (let bin of bins) {
    human[bin] = 0;
    bot[bin] = 0;
  }

  let [day, month, year] = lastDay;
  let date = new Date(year, month, day);
  date.setDate(date.getDate() - botClicks.length + 1);

  for (let i = 0; i < botClicks.length; i++) {
    human[findDateRange(date, bins)] += humanClicks[i];
    bot[findDateRange(date, bins)] += botClicks[i];
    date.setDate(date.getDate() + 1);
  }

  let humanData = [];
  let botData = [];

  for (let bin of bins) {
    humanData.push(human[bin]);
    botData.push(bot[bin]);
  }

  return {
    title: {
      text: '',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      backgroundColor: isDarkMode ? '#21242e' : 'lightGray',
      borderWidth: 0,
      textStyle: {
        color: isDarkMode ? 'white' : 'black',
        fontSize: 15,
      },
      formatter: function (params) {
        let tooltipContent = '';

        tooltipContent += `${params[0].name} <br/>`;

        params.forEach((item) => {
          const color = item.color;
          const name = item.seriesName.startsWith('Human') ? 'Human' : 'Bot';

          const value = item.value;
          tooltipContent += `
          <span style="display:inline-block;width:10px;
           height:10px;background-color:${color};margin-right:5px;"></span>
          ${name}: ${value}<br/>
        `;
        });

        return tooltipContent;
      },
    },
    legend: {
      data: [
        {icon: 'square', name: humanLabel},
        {icon: 'square', name: botLabel}
      ],
      textStyle: {
        color: isDarkMode ? 'white' : 'black',
        fontWeight: 'bold',
        fontSize: 15,
      },
      itemWidth: 10,
      itemHeight: 10,
      itemGap: 30,
      left: 'left',
      bottom: '0%',
    },
    grid: {
      left: '0%',
      right: '0%',
      top: '10%',
      bottom: isMobile ? '20%' : '10%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: bins,
      axisLine: {
        lineStyle: {
          color: isDarkMode ? '#333' : '#ccc',
        },
      },
      axisLabel: {
        color: isDarkMode ? 'white' : 'black',
        fontSize: 10,
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        lineStyle: {
          color: '#ccc',
        },
      },
      splitLine: {
        lineStyle: {
          color: isDarkMode ? '#333' : '#ddd',
          type: 'dashed',
        },
      },
      axisLabel: {
        color: isDarkMode ? 'white' : 'black',
        formatter: (value) => {
          return value >= 1000 ? `${value / 1000}k` : value;
        },
      },
    },
    color: ['#009de6', 'rgba(255, 77, 77, 1)'],
    series: [
      {
        name: humanLabel,
        type: 'bar',
        data: humanData,
        barWidth: '30%',
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
        },
      },
      {
        name: botLabel,
        type: 'bar',
        data: botData,
        barWidth: '30%',
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
        },
      },
    ]
  }
}

const BarGraph = ({specs}) => {
  let { isDarkMode } = useTheme();
  const { isMobile } = useMobile();
  const [chartOption, setChartOption] = useState(getOption(specs, isDarkMode, isMobile));

  useEffect(() => {
    setChartOption(getOption(specs, isDarkMode, isMobile));
  }, [isDarkMode, specs, isMobile]);

  return (
    <ReactECharts option={chartOption}
                  notMerge={true}
                  style={{ height: '100%',
                           width: '100%' }} />
  );
}

export default BarGraph;
