import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useTheme } from '../../ThemeContext'

import { ReactComponent as BotIcon } from '../../images/bot.svg';
import { ReactComponent as HumanIcon } from '../../images/human.svg';

const PieChart = ({ data, text, disabled }) => {
  let { isDarkMode } = useTheme();
  const svgRef = useRef(null);
  const [labelPositions, setLabelPositions] = useState([]);

  useEffect(() => {
    const parentDiv = svgRef.current?.parentElement;
    const height = parentDiv?.clientHeight || 350;
    let width = height;

    let scale = height / 400;

    d3.select(svgRef.current).selectAll('*').remove();

    const svg = d3.select(svgRef.current)
          .attr('width', width)
          .attr('height', height)
          .append('g')
          .attr('transform', `translate(${width / 2}, ${height / 2})`);

    let startAngle = 120;

    const pie = d3.pie().value(d => d.value)
          .startAngle(startAngle * (Math.PI/180))
          .endAngle((startAngle + 360) * (Math.PI/180))

    const arc = d3.arc()
          .cornerRadius(5)
          .innerRadius(d => d.data.innerRadius * scale)
          .outerRadius(d => d.data.outerRadius * scale);

    const positions = pie(data).map(d => ({
      x: arc.centroid(d)[0],
      y: arc.centroid(d)[1],
      data: d.data,
    }));
    setLabelPositions(positions);

    svg.selectAll('path')
      .data(pie(data))
      .enter()
      .append('path')
      .attr('fill', d => disabled ? 'gray' : d.data.color)
      .attr('stroke', isDarkMode ? 'black' : 'white')
      .attr('stroke-width', 3)
      .attr('d', arc)
      .on('mouseover', function (event, d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr('d', d3.arc()
                .cornerRadius(5)
                .innerRadius(d.data.innerRadius * scale)
                .outerRadius(d.data.outerRadius * scale + 10)
               );
      })
      .on('mouseout', function (event, d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr('d', arc);
      })
      .transition()
      .duration(1000)
      .attrTween('d', function (d) {
        const interpolateRadius = d3.interpolate(d.data.innerRadius * scale,
                                                 d.data.outerRadius * scale);
        const arcTween = d3.arc()
              .cornerRadius(5)
              .innerRadius(d.data.innerRadius * scale);

        return function (t) {
          return arcTween.outerRadius(interpolateRadius(t))(d);
        };
      });

    svg.append("text")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .attr("x", 0)
      .attr("y", 0)
      .style("font-size", "20px")
      .style("font-weight", "bold")
      .style("fill", isDarkMode ? "white" : "black")
      .selectAll("tspan")
      .data(text.split("\n"))
      .join("tspan")
      .attr("x", 0)
      .attr("dy", (d, i) => i * 30 - 10)
      .text(d => d);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isDarkMode, text]);

  return (
    <div style={{ position: 'relative',
                  width: '100%',
                  height: '100%',
                }}>
      <svg ref={svgRef} style={{ position: 'absolute', top: 0, left: 0 }}></svg>

      {labelPositions.map((pos, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            top: `calc(50% + ${pos.y}px)`,
            left: `calc(50% + ${pos.x}px)`,
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: isDarkMode ? '#21242e' : '#eee',
            color: isDarkMode ? 'white' : 'black',
            borderRadius: '5px',
            padding: '5px',
            fontSize: '20px',
            fontWeight: 'bold',
            pointerEvents: 'none',
          }}>
          <div style={{ display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',
                        padding: '0 5px',
                      }}>
            {pos.data.label.startsWith('Bot') ?
             <BotIcon style={{ width: '30px',
                               height: '30px',
                               fill: disabled ? 'gray' : '#009de6',
                             }} />
             :
             <HumanIcon style={{ width: '30px',
                                 height: '30px',
                                 fill: disabled ? 'gray' : '#009de6',
                               }} />
            }
            {!disabled && <span>{pos.data.value}%</span>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default PieChart;
