import {tiers} from './tiers.js';

export function getFormattedDateArray (lastDay, count) {
  let [day, month, year] = lastDay;
  let currentDate = new Date(year, month, day)
  const dateArray = [];

  for (let i = 0; i < count; i++) {
    const formattedDate = currentDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    });
    dateArray.push(formattedDate);
    currentDate.setDate(currentDate.getDate() - 1);
  }
  dateArray.reverse();

  return dateArray;
};

export function unixToDate (time) {
  const date = new Date(time * 1000);

  return date.toLocaleString('en-CA',
                             {year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                              hour12: false})
    .replace(/,/, '')
    .replace(/\//g, '/')
    .replace(/-/g, '/');
}

export function percentageIncrease (original, newValue) {
  if (original === 0) {
    if (newValue === 0) {
      return 0;
    }
    else if (newValue > 0) {
      return Infinity;
    }
    else {
      return -Infinity;
    }
  }
  return ((newValue - original) / original) * 100;
}

export function getDatesFromPeriod (period, previous) {
  const now = new Date();
  let end = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  let start;

  if (period === 'Last 30 days') {
    start = new Date(end);
    start.setDate(start.getDate() - 30 + 1);

    if (previous) {
      end = new Date(start);
      end.setDate(end.getDate() - 1);
      start.setDate(start.getDate() - 30 + 1);
    }
  } else if (period === 'Last 6 months') {
    start = new Date(end.getFullYear(), end.getMonth() - 6, end.getDate());
  } else {
    const targetMonth = new Date(`${period} 1, ${now.getFullYear()}`);
    if (targetMonth > now) {
      targetMonth.setFullYear(targetMonth.getFullYear() - 1);
    }

    start = new Date(targetMonth.getFullYear(),
                     targetMonth.getMonth() + (previous ? -1 : 0),
                     1);


    end = new Date(targetMonth.getFullYear(),
                   targetMonth.getMonth() + (previous ? 0 : 1),
                   0);
  }

  return {
    start,
    end,
  };
}

export function generatePeriodOptions() {
  const now = new Date();
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const result = ['Last 30 days'];

  for (let i = 0; i < 6; i++) {
    const monthIndex = (now.getMonth() - i + 12) % 12;
    result.push(months[monthIndex]);
  }

  result.push('Last 6 months');

  return result;
}

export function transformData (inputArray, startDate, endDate) {
  const sortedArray = inputArray.sort(
    (a, b) => new Date(a["Date/Time (Local)"]) - new Date(b["Date/Time (Local)"])
  );

  const dateCounts = {};
  sortedArray.forEach((entry) => {
    const dateKey = new Date(entry["Date/Time (Local)"]).toISOString().split("T")[0];
    dateCounts[dateKey] = (dateCounts[dateKey] || 0) + 1;
  });

  const data = [];
  let currentDate = new Date(startDate);
  while (currentDate < endDate) {
    const dateKey = currentDate.toISOString().split("T")[0];
    data.push(dateCounts[dateKey] || 0);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return data;
}

export function groupByField(array, field) {
  const grouped = array.reduce((acc, item) => {
    const key = item[field];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  return Object.entries(grouped).map(([key, value]) => ({
    name: key,
    data: value,
  }));
}

export function print (obj) {
  console.clear();
  console.log(JSON.stringify(obj, null, 2));
}

export function mapBetweenRanges(value, minA, maxA, minB, maxB) {
  const aSize = maxA - minA;
  const bSize = maxB - minB;
  const proportion = (value - minA) / aSize;
  return proportion * bSize + minB;
}

export function getPrice (traffic) {
  if (traffic < 25000) return 450

  if (traffic > 15000000) {
    let extra = traffic - 15000000

    return 19500 + extra * 0.001
  }

  let lowerTier = tiers.findLast(tier => traffic > tier.maxTraffic)
  let upperTier = tiers.find(tier => traffic <= tier.maxTraffic)

  return mapBetweenRanges(traffic,
                          lowerTier.maxTraffic, upperTier.maxTraffic,
                          lowerTier.maxTraffic * lowerTier.price,
                          upperTier.maxTraffic * upperTier.price).toFixed(2)
}
