import React, { createContext, useState, useContext } from 'react';
import { useDropDown } from './DropDownContext';
import * as utils from './utils';

const SitesContext = createContext();

export const SitesProvider = ({ children }) => {
  const [sites, setSites] = useState([]);
  const { getSelectedOption } = useDropDown();

  const fetchSites = () => {
    const token = localStorage.getItem('token');

    return fetch(process.env.REACT_APP_SERVER_URL + '/api/get-sites', {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          let order = localStorage.getItem('sitesOrder') || "";
          order = order.split(',').map((id) => parseInt(id));

          for (let site of data.sites) {
            if (!order.includes(site.id)) {
              order.push(site.id);
            }
          }
          order = order.filter((id) => data.sites.find((site) => site.id === id));
          applyOrder(data.sites, order);
          localStorage.setItem('sitesOrder', order);

        } else if (data.message === 'invalid token!') {
          console.log('unauthorized access');
          localStorage.removeItem('token');
          window.location.href = '/';
        }
      })
      .catch(error => console.log(error));
  }

  const applyOrder = (sites, order) => {
    let ordered = [];
    for (let id of order) {
      let site = sites.find((site) => site.id === id);
      if (site) {
        ordered.push(site);
      }
    }

    setSites(ordered);
  }

  const reorderSites = (source, destination) => {
    let order = localStorage.getItem('sitesOrder').split(',').map((id) => parseInt(id))
    const [moved] = order.splice(source.index, 1);
    order.splice(destination.index, 0, moved);
    localStorage.setItem('sitesOrder', order);
    applyOrder(sites, order);
  }

  const addSite = (domain) => {
    const token = localStorage.getItem('token');

    fetch(process.env.REACT_APP_SERVER_URL + '/api/add-site', {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({domain})
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          let id = data.website_id;
          let order = localStorage.getItem('sitesOrder').split(',').map((id) => parseInt(id));
          order.unshift(id);
          localStorage.setItem('sitesOrder', order);

          let now = new Date();
          let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
          today.setUTCHours(0, 0, 0, 0);
          let lastDay = [today.getUTCDate(), today.getUTCMonth(), today.getUTCFullYear()];

          setSites([{website: domain,
                     id,
                     campaigns: [],
                     platforms: [],
                     detectionLevel: 'true_traffic',
                     fraudLevel: 'low',
                     data: [{}],
                     lifetimeCounts: {human: [0],
                                      bots: [0],
                                      lastDay
                                     },
                     lastDay
                    },
                    ...sites,
                   ]);
        }
        else if (data.message === 'invalid token!') {
          console.log('unauthorized access')
          localStorage.removeItem('token');
          window.location.href = '/';
        }
      })
      .catch(error => console.log(error));
  }

  const deleteSite = (which) => {
    const token = localStorage.getItem('token');

    fetch(process.env.REACT_APP_SERVER_URL + '/api/delete-site', {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({domain: which.website}),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          let id = which.id;
          let order = localStorage.getItem('sitesOrder')
              .split(',').map((id) => parseInt(id));
          order = order.filter((siteId) => siteId !== id);
          localStorage.setItem('sitesOrder', order);
          setSites(sites.filter((site) => site.id !== id));
        }
        else if (data.message === 'invalid token!') {
          console.log('unauthorized access')
          localStorage.removeItem('token');
          window.location.href = '/';
        }
      })
      .catch(error => console.log(error));
  }

  const renameSite = (which, newDomain) => {
    const token = localStorage.getItem('token');

    fetch(process.env.REACT_APP_SERVER_URL + '/api/rename-site', {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({domain: which.website, newDomain}),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          let id = which.id;
          setSites(sites.map((site) => {
            if (site.id === id) {
              return {...site, website: newDomain};
            }
            return site;
          }));
        }
        else if (data.message === 'invalid token!') {
          console.log('unauthorized access')
          localStorage.removeItem('token');
          window.location.href = '/';
        }
      })
      .catch(error => console.log(error));
  }

  const getCampaigns = (domain) => {
    let site = sites.find((site) => site.website === domain);
    return ['All', ...site.campaigns]
  }

  const getPlatforms = (domain) => {
    let site = sites.find((site) => site.website === domain);
    return ['All', ...site.platforms]
  }

  const getTotalSince = (start) => {
    if (sites.length === 0) return 0;

    let lastDay = sites[0].lastDay;
    let [day, month, year] = lastDay;
    let startUnix = start.getTime() / 1000;
    let total = 0;

    for (let site of sites) {
      let data = site.data;
      let date = new Date(year, month, day);

      for (let i = data.length - 1; i >= 0; i--) {
        let dateUnix = new Date(date).getTime() / 1000;
        if (dateUnix >= startUnix) {

          for (const value of Object.values(data[i])) {
            total += value[0] + value[1];
          }
        }
        date.setDate(date.getDate() - 1);
      }
    }

    return total;
  }

  function filterPeriod (data, lastDay, previous) {
    let option = getSelectedOption('period') || 'Last 30 days';
    let { start, end } = utils.getDatesFromPeriod(option, previous);

    let startUnix = new Date(start).getTime() / 1000;
    let endUnix = new Date(end).getTime() / 1000;

    let [day, month, year] = lastDay;
    let date = new Date(year, month, day);
    let last = null;
    let result = [];

    for (let i = data.length - 1; i >= 0; i--) {
      let dateUnix = new Date(date).getTime() / 1000;

      if (dateUnix >= startUnix && dateUnix <= endUnix) {
        result.push(data[i]);
        if (last === null) {
          last = [date.getUTCDate(), date.getUTCMonth(), date.getUTCFullYear()]
        }
      }
      date.setDate(date.getDate() - 1);
    }

    result.reverse();

    return [result, last];
  }

  function filterCampaign (data, index) {
    return data.map(entry => {
      let result = {};

      for (let key of Object.keys(entry)) {
        let campaignIndex = parseInt(key.split('-')[0]);
        if (campaignIndex === index) {
          let newKey = '0-' + key.split('-')[1];
          result[newKey] = entry[key];
        }
      }
      return result;
    })
  }

  function filterPlatform (data, index) {
    return data.map(entry => {
      let result = {};

      for (let key of Object.keys(entry)) {
        let platformIndex = parseInt(key.split('-')[1]);
        if (platformIndex === index) {
          let newKey = key.split('-')[0] + '-0';
          result[newKey] = entry[key];
        }
      }
      return result;
    })
  }

  const computeOldCounts = (site, campaignIndex, platformIndex) => {
    let option = getSelectedOption('period') || 'Last 30 days';

    if (utils.generatePeriodOptions().slice(-2).includes(option)) {
      return null;
    }

    let data = filterPeriod(site.data, site.lastDay, true)[0];

    if (campaignIndex !== -1) {
      data = filterCampaign(data, campaignIndex);
    }

    if (platformIndex !== -1) {
      data = filterPlatform(data, platformIndex);
    }

    let botTraffic = 0;
    let humanTraffic = 0;

    for (let i = 0; i < data.length; i++) {
      for (const value of Object.values(data[i])) {
        humanTraffic += value[0];
        botTraffic += value[1];
      }
    }

    if (botTraffic === 0 && humanTraffic === 0) {
      return null;
    }

    return [humanTraffic, botTraffic];
  }

  const getSites = (force) => {
    let campaign = force || getSelectedOption('campaign') || 'All';
    let platform = force || getSelectedOption('platform') || 'All';
    let result = [];

    for (let site of sites) {
      let campaignIndex = site.campaigns.indexOf(campaign);
      let platformIndex = site.platforms.indexOf(platform);
      let [newData, lastDay] = filterPeriod(site.data, site.lastDay);

      if (campaignIndex !== -1) {
        newData = filterCampaign(newData, campaignIndex);
      }

      if (platformIndex !== -1) {
        newData = filterPlatform(newData, platformIndex);
      }

      const filteredSite = {
        ...site,
        data: newData,
        lastDay,
        oldCounts: computeOldCounts(site, campaignIndex, platformIndex),
        campaigns: campaign === 'All' ? site.campaigns : [campaign],
        platforms: platform === 'All' ? site.platforms : [platform],
      };
      result.push(filteredSite);
    }

    return result;
  }

  return (
    <SitesContext.Provider value={{ fetchSites,
                                    getSites, getCampaigns, getPlatforms,
                                    reorderSites, addSite, deleteSite, renameSite,
                                    getTotalSince
                                  }}>
      {children}
    </SitesContext.Provider>
  );
};

export const useSites = () => useContext(SitesContext);
