import React, { useState } from 'react';
import Input from '../Input';
import { useMobile } from '../MobileContext';
import Modal from '../Modal';

import { ReactComponent as CloseIcon } from '../images/x.svg';
import { ReactComponent as InfoIcon } from '../images/info.svg';
import { ReactComponent as CopyIcon } from '../images/copy.svg';

const script = `<!-- Polygraph fraud detection -->
<script src="https://cdn.polygraph.net/pg.js"></script>
<!-- Polygraph fraud detection -->`;

const EditSiteModal = ({oldDomain, saveDomain, setEditedIndex, existingNames}) => {
  const { isMobile } = useMobile();
  const [domain, setDomain] = useState(oldDomain);
  const [message, setMessage] = useState(null);
  const [disabled, setDisabled] = useState(false);

  async function copyText (text) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      fallbackCopy(text);
    }
  }

  function fallbackCopy(text) {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  }

  return (
    <Modal isOpen={true}>
      <div style={{
             background: 'linear-gradient(gray, transparent)',
             zIndex: '101',
             position: 'absolute',
             transform: 'translate(-50%, -50%)',
             top: '50%',
             left: '50%',
             width: isMobile ? '95%' : '600px',
             borderRadius: '10px',
           }}>
        <div className='modal'
             style={{
               margin: '1px',
               borderRadius: '10px',
               zIndex: '101',
               display: 'flex',
               justifyContent: 'center',
               flexDirection: 'column',
               padding: '20px',
             }}>
          <div style={{
                 display: 'flex',
                 flexDirection: 'row',
                 alignItems: 'center',
                 justifyContent: 'space-between',
                 width: '100%',
               }}>
            <span style={{fontSize: '22px',
                          fontWeight: 'bold',
                         }}>
              Edit the website
            </span>
            <CloseIcon className='image'
                       style={{width: '15px',
                               height: '15px',
                              }}
                       onClick={() => setEditedIndex(-1)} />
          </div>

          <form onSubmit={(e) => {
                  e.preventDefault();

                  if (!disabled) {
                    saveDomain(domain);
                    setEditedIndex(-1);
                  }
                }}>
            <h4>Site infomation</h4>

            <div style={{position: 'relative'}}>
              <Input onChange={(e) => {
                       if (existingNames.includes(e.target.value) && e.target.value !== oldDomain) {
                         setMessage('This domain name is already in use.');
                         setDisabled(true);
                       }
                       else if (e.target.value === '') {
                         setMessage('Domain name cannot be empty.');
                         setDisabled(true);
                       }
                       else {
                         setMessage(null);
                         setDisabled(false);
                       }
                       setDomain(e.target.value);
                     }}
                     label='DOMAIN NAME'
                     value={domain}
                     icon={<InfoIcon className='image'
                                     style={{width: '20px',
                                             height: '20px',
                                            }}
                                     onMouseEnter={() => setMessage('Enter the domain name of the website you want to track.')}
                                     onMouseLeave={() => setMessage(null)}
                           />} />
              {message && (
                <span style={{position: 'absolute',
                              top: '100%',
                              left: '0',
                              fontSize: '12px',
                              color: '#666',
                              width: '100%',
                              textAlign: 'left',
                              padding: '5px',
                              backgroundColor: '#f9f9f9',
                              borderRadius: '5px',
                              zIndex: '100',
                              boxSizing: 'border-box',
                             }}>
                  {message}
                </span>
              )}
            </div>

            <h4>Tracking code</h4>
            <span>Your ad campaigns must be configured to use UTMs, especially utm_campaign, utm_source, and utm_term.</span>
            <br />
            <span>To start detecting click fraud, you need to add the following HTML code to the &lt;head&gt; section of the website: </span>

            <div className='script'
                 style={{padding: '10px',
                         marginTop: '10px',
                         overflow: 'auto',
                         whiteSpace: 'pre-wrap',
                         borderRadius: '5px',
                         position: 'relative',
                        }}>
              {script}
              <CopyIcon className='image'
                        style={{width: '20px',
                                height: '20px',
                                position: 'absolute',
                                right: '10px',
                                top: '10px',
                               }}
                        onClick={() => copyText(script)}
              />
            </div>

            <div style={{display: 'flex',
                         flexDirection: 'row',
                         alignItems: 'center',
                         marginTop: '10px',
                         gap: '10px',
                         justifyContent: 'center',
                        }}>
              <button type="button"
                      style={{alignItems: 'center',
                              ...(isMobile ? { flexGrow: 1 } : { width: 'fit-content' }),
                              padding: '10px 20px',
                              border: '1px solid #e0e0e0',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              fontWeight: 'bold',
                              alignSelf: 'flex-end',
                              color: 'inherit',
                              backgroundColor: 'transparent',
                              fontSize: '18px',
                              height: '45px',
                             }}
                      onClick={() => setEditedIndex(-1)}>
                Cancel
              </button>
              <button type="submit" style={{
                        alignItems: 'center',
                        ...(isMobile ? { flexGrow: 1 } : { width: 'fit-content' }),
                        padding: '10px 20px',
                        backgroundColor: disabled ? 'gray' : '#009de6',
                        color: disabled ? 'darkgray' : 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        alignSelf: 'flex-end',
                        fontSize: '18px',
                        height: '45px',
                      }}>
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default EditSiteModal;
