import React from 'react';
import Keyword from './Keyword';
import { useMobile } from '../../MobileContext';
import Modal from '../../Modal';

import { ReactComponent as CloseIcon } from '../../images/x.svg';

const HighRiskKeywordsModal = ({data, setVisible}) => {
  const { isMobile } = useMobile();

  return (
    <Modal isOpen={true}>
      <div style={{
             background: 'linear-gradient(gray, transparent)',
             zIndex: '101',
             position: 'absolute',
             transform: 'translate(-50%, -50%)',
             top: '50%',
             left: '50%',
             width: isMobile ? '95%' : '600px',
             borderRadius: '10px',
             maxHeight: '100vh',
             overflowY: 'auto',
           }}>
        <div className='modal'
             style={{
               margin: '1px',
               borderRadius: '10px',
               zIndex: '101',
               display: 'flex',
               justifyContent: 'center',
               flexDirection: 'column',
               padding: '20px',
             }}>
          <div style={{
                 display: 'flex',
                 flexDirection: 'row',
                 alignItems: 'center',
                 justifyContent: 'space-between',
                 width: '100%',
               }}>
            <span style={{fontSize: '22px',
                          fontWeight: 'bold',
                         }}>
              High Risk Keywords
            </span>
            <CloseIcon className='image'
                       style={{width: '15px',
                               height: '15px',
                              }}
                       onClick={() => setVisible(false)} />
          </div>

          <div>
            <p>The information below show your high risk and medium risk ad keywords from the past 30 days.</p>
            <p><span style={{color: '#d42c2c'}}>High Risk</span> are your ad keywords which are being actively targeted by click fraud. This means if you continue using these keywords, it is likely you will continue experiencing click fraud.</p>
            <p><span style={{color: '#f6a60c'}}>Medium Risk</span> are your ad keywords which have been targeted by click fraud gangs in the past six months, however they have not been targeted in the past 30 days. You should continue using these keywords with caution.</p>
          </div>

          <div style={{display: 'flex',
                       flexWrap: 'wrap',
                       gap: '10px',
                      }}>

            {data.map((keyword, index) => {
              return <Keyword key={index}
                              text={keyword.text}
                              number={keyword.number}
                              riskLevel={keyword.riskLevel} />
            })}
          </div>

          <div style={{display: 'flex',
                       flexDirection: 'row',
                       alignItems: 'center',
                       marginTop: '10px',
                       gap: '10px',
                       justifyContent: 'center',
                      }}>
            <div style={{alignItems: 'center',
                         width: 'fit-content',
                         padding: '10px 20px',
                         border: '1px solid #e0e0e0',
                         borderRadius: '5px',
                         cursor: 'pointer',
                         fontWeight: 'bold',
                         alignSelf: 'flex-end',
                        }}
                 onClick={() => setVisible(false)}>
              Got it
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default HighRiskKeywordsModal;
