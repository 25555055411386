import React from 'react';
import Input from '../Input';
import PasswordInput from './PasswordInput';
import { useMobile } from '../MobileContext';

import { ReactComponent as TrashIcon } from '../images/trash.svg';

const ExtraUserCard = ({user, setUser, index, deleteUser}) => {
  const { isMobile } = useMobile();
  let { email } = user;

  return (
    <div style={{width: '100%',
                }}>
      <div style={{display: 'flex',
                   flexDirection: 'row',
                   alignItems: 'center',
                   gap: '20px',
                  }}>
        <h4> User {index + 1}</h4>

        {deleteUser && (
          <TrashIcon className='image'
                     style={{width: '15px',
                             height: '15px',
                             cursor: 'pointer',
                            }}
                     onClick={() => deleteUser(index)} />
        )}
      </div>
      <div style={{display: 'flex',
                   flexDirection: 'column',
                   gap: '20px',
                   boxSizing: 'border-box',
                   width: '100%',
                   borderRadius: '10px',
                  }}>
        <div style={{display: 'flex',
                     flexDirection: 'column',
                     gap: '20px',
                     width: '100%',
                    }}>
          <Input label='EMAIL'
                 value={email}
                 onChange={(e) => setUser({...user, email: e.target.value})} />
        </div>

        <div style={{display: 'flex',
                     flexDirection: isMobile ? 'column' : 'row',
                     gap: '20px',
                    }}>
          <PasswordInput label='NEW PASSWORD'
                         onChange={(e) => setUser({...user, password: e.target.value})} />
          <PasswordInput label='CONFIRM NEW PASSWORD'
                         target={user.password}
                         onChange={(e) => setUser({...user, password2: e.target.value})} />
        </div>

        <div style={{display: 'flex',
                     flexDirection: 'row',
                     gap: '20px',
                    }}>
          <span style={{fontSize: '13px',
                        width: '100%',
                        color: 'gray',
                       }}>
            8+ characters, with min one number and one uppercase character.
          </span>
        </div>
      </div>
    </div>
  );
}

export default ExtraUserCard;
