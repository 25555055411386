import React, { useEffect } from 'react';
import { useSites } from './SitesContext';
import SiteCard from './settings/SiteCard';
import DownloadInvoicesModal from './settings/DownloadInvoicesModal';
import CancelSubscriptionModal from './settings/CancelSubscriptionModal';
import ChangeCardModal from './settings/ChangeCardModal';
import DeleteSiteModal from './settings/DeleteSiteModal';
import SubscriptionsModal from './settings/SubscriptionsModal';
import CardCard from './settings/CardCard';
import AccountButtons from './AccountButtons';
import PlanCard from './settings/PlanCard';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import Separator from './Separator';
import EditSiteModal from './settings/EditSiteModal';
import { useMobile } from './MobileContext';

import { ReactComponent as CloudIcon } from './images/cloud.svg';

const Settings = ({setPage}) => {
  const { isMobile } = useMobile();
  const [deleteSiteModalVisible, setDeleteSiteModalVisible] = React.useState(false);
  const [invoicesModalVisible, setInvoicesModalVisible] = React.useState(false);
  const [changeCardModalVisible, setChangeCardModalVisible] = React.useState(false);
  const [cancelSubscriptionModalVisible, setCancelSubscriptionModalVisible] = React.useState(false);
  const [siteIndex, setSiteIndex] = React.useState(0);
  const [subscriptionsModalVisible, setSubscriptionsModalVisible] = React.useState(false);
  const [scrolling, setScrolling] = React.useState(false);
  const [editedIndex, setEditedIndex] = React.useState(-1);
  const [paymentMethod, setPaymentMethod] = React.useState(null);
  const [customerId, setCustomerId] = React.useState('');
  const [paymentVersion, setPaymentVersion] = React.useState(0);
  const [subscription, setSubscription] = React.useState(null);
  const [subscriptionVersion, setSubscriptionVersion] = React.useState(0);

  useEffect(() => {
    const token = localStorage.getItem('token');

    fetch(process.env.REACT_APP_SERVER_URL + '/api/get-users', {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          let customerId = data.users[0].stripeCustomerId;
          setCustomerId(customerId);
        }
      })
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (customerId === '') return;

    fetch(process.env.REACT_APP_SERVER_URL + '/api/get-customer-payment-method', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',
                 'Authorization': token,},
      body: JSON.stringify({ customerId }),
    })
      .then(res => res.json())
      .then(data => {
        setPaymentMethod(data.paymentMethod)
      });
  }, [customerId, paymentVersion]);

  useEffect(() => {
    let token = localStorage.getItem('token');

    fetch(process.env.REACT_APP_SERVER_URL + '/api/get-subscription', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',
                 'Authorization': token
               },
    })
      .then(res => res.json())
      .then(data => {
        setSubscription(data)
      });

  }, [subscriptionVersion]);

  const { getSites, deleteSite, renameSite, reorderSites } = useSites();

  const enablePageScroll = () => {
    setScrolling(false);
  };

  const disablePageScroll = () => {
    setScrolling(true);
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    reorderSites(source, destination);
    enablePageScroll();
  };

  const cancelSubscription = () => {
    const token = localStorage.getItem('token');

    fetch(process.env.REACT_APP_SERVER_URL + '/api/cancel-subscription', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',
                 'Authorization': token
               },
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setSubscription({cap: null});
        }
      });
  };

  return (
    <div style={{
           width: 'calc(100% - 40px)',
           overflow: scrolling ? 'hidden' : 'auto',
           padding: '20px',
         }}>

      {editedIndex !== -1 && (
        <EditSiteModal oldDomain={getSites()[editedIndex].website}
                       setEditedIndex={setEditedIndex}
                       saveDomain={(newDomain) => {
                         renameSite(getSites()[editedIndex], newDomain);
                       }}
                       existingNames={getSites().map(site => site.website)}
        />
      )}

      {!isMobile && (
        <div style={{ width: '100%',
                      display: 'flex',
                      flexDirection: 'row-reverse',
                    }}>
          <AccountButtons setPage={setPage} selected='settings'/>
        </div>
      )}

      {invoicesModalVisible && (
        <DownloadInvoicesModal setInvoicesModalVisible={setInvoicesModalVisible} />
      )}

      {cancelSubscriptionModalVisible && (
        <CancelSubscriptionModal setCancelSubscriptionModalVisible={setCancelSubscriptionModalVisible}
                                 cancelSubscription={cancelSubscription} />
      )}

      {changeCardModalVisible && (
        <ChangeCardModal setChangeCardModalVisible={setChangeCardModalVisible}
                         incrementPaymentVersion={() => setPaymentVersion(paymentVersion + 1)} />
      )}

      {deleteSiteModalVisible && (
        <DeleteSiteModal site={getSites()[siteIndex]}
                         setDeleteSiteModalVisible={setDeleteSiteModalVisible}
                         deleteSite={deleteSite} />
      )}

      {subscriptionsModalVisible && (
        <SubscriptionsModal setSubscriptionsModalVisible={setSubscriptionsModalVisible}
                            subscription={subscription}
                            incrementSubscriptionVersion={() => setSubscriptionVersion(subscriptionVersion + 1)} />
      )}

      <div style={{display: 'flex',
                   flexDirection: 'row',
                   alignItems: 'center',
                   justifyContent: 'space-between',
                   marginBottom: '10px',
                  }}>
        <h3>Manage Websites</h3>
      </div>

      {!isMobile && (
        <>
          <div style={{width: '100%',
                       display: 'flex',
                       flexDirection: 'row',
                       padding: '20px',
                       boxSizing: 'border-box',
                      }}>
            <div style={{width: '40%',
                        }}>
              URL
            </div>
            <div style={{width: '20%',
                         display: 'flex',
                         justifyContent: 'center',
                        }}>
              FRAUD LEVEL
            </div>
            <div style={{width: '20%',
                         display: 'flex',
                         justifyContent: 'center',
                        }}>
              PROTECTION STATE
            </div>
            <div style={{width: '20%',
                        }}>
            </div>
          </div>

          <Separator />
        </>
      )}

      <DragDropContext onBeforeCapture={disablePageScroll}
                       onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                boxSizing: 'border-box',
                width: '100%',
                marginBottom: '20px',
                paddingBottom: '20px',
                ...(isMobile ? {} : { padding: '20px' }),
                height: '300px',
                overflow: 'auto',
              }}>
              {getSites().map((site, index) => (
                <div key={site.website}>
                  <Draggable draggableId={site.website}
                             index={index}>
                    {(provided) => (
                      <div style={{width: '100%',
                                  }}
                           ref={provided.innerRef}
                           {...provided.draggableProps}>

                        <SiteCard site={site}
                                  setDeleteSiteModalVisible={setDeleteSiteModalVisible}
                                  setEditedIndex={setEditedIndex}
                                  index={index}
                                  setSiteIndex={setSiteIndex}
                                  dragHandleProps={provided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                  <Separator />
                </div>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div style={{display: 'flex',
                   flexDirection: 'row',
                   alignItems: 'center',
                   justifyContent: 'space-between',
                   marginBottom: '10px',
                  }}>
        <h3>Subscription</h3>

        {!isMobile && (
          <div style={{alignItems: 'center',
                       width: 'fit-content',
                       padding: '10px 20px',
                       border: '1px solid #e0e0e0',
                       borderRadius: '5px',
                       cursor: 'pointer',
                       fontWeight: 'bold',
                       alignSelf: 'flex-end',
                       display: 'flex',
                      }}
               onClick={() => setInvoicesModalVisible(true)}>
            Download Invoices
            <CloudIcon className='image'
                       style={{width: '20px',
                               height: '20px',
                               marginLeft: '10px',
                              }} />
          </div>
        )}

        {isMobile && (
          <CloudIcon className='image'
                     style={{width: '20px',
                             height: '20px',
                             marginLeft: '10px',
                            }}
                     onClick={() => setInvoicesModalVisible(true)} />
        )}
      </div>

      <div style={{display: 'flex',
                   flexDirection: isMobile ? 'column' : 'row',
                   gap: '20px',
                   boxSizing: 'border-box',
                   borderRadius: '10px',
                   ...(!isMobile ? { height: '300px' } : {}),
                  }}>
        <PlanCard setSubscriptionsModalVisible={setSubscriptionsModalVisible}
                  subscription={subscription} />
        <CardCard setModalVisible={setChangeCardModalVisible}
                  paymentMethod={paymentMethod} />
      </div>

      {subscription && subscription.cap && (
        <div style={{display: 'flex',
                     flexDirection: 'row',
                     alignItems: 'center',
                     marginTop: '10px',
                     gap: '10px',
                    }}>
          <div style={{alignItems: 'center',
                       ...(isMobile ? { flexGrow: 1 } : { width: 'fit-content' }),
                       padding: isMobile ? '15px 20px' : '10px 20px',
                       border: '1px solid #e0e0e0',
                       borderRadius: '5px',
                       cursor: 'pointer',
                       fontWeight: 'bold',
                       alignSelf: 'flex-end',
                       display: 'flex',
                       justifyContent: 'center',
                      }}
               onClick={() => {
                 setCancelSubscriptionModalVisible(true);
               }}>
            Cancel Subscription
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
