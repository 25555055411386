import React, { useState, useEffect, useRef } from 'react';
import DropDown from './DropDown';
import TrafficCard from './overview/TrafficCard';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useSites } from './SitesContext';
import SiteCard from './overview/SiteCard';
import SiteCard2 from './overview/SiteCard2';
import SiteCard3 from './overview/SiteCard3';
import AccountButtons from './AccountButtons';
import Separator from './Separator';
import * as utils from './utils';
import { useMobile } from './MobileContext';

import { ReactComponent as GridIcon } from './images/grid.svg';
import { ReactComponent as ListIcon } from './images/list.svg';

const Overview = ({setPage, position, setPosition}) => {
  const { isMobile } = useMobile();
  let [displayMode, setDisplayMode] = useState(null);
  const { getSites, reorderSites } = useSites();
  const [totalTraffic, setTotalTraffic] = useState({});

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    reorderSites(source, destination);
  };

  const aggregateSites = (sites) => {
    let result = {total: {numClicks: 0,
                          percentage: 0,
                          old: 0,
                          title: 'TOTAL TRAFFIC',
                          icon: 'click'},
                  bot: {numClicks: 0,
                        clickPercentage: 0,
                        percentage: 0,
                        old: 0,
                        title: 'BOT TRAFFIC',
                        icon: 'bot'}
                 };

    let old = false;

    sites.forEach(site => {
      let humanClicks = 0;
      let botClicks = 0;

      for (let i = 0; i < site.data.length; i++) {
        for (const value of Object.values(site.data[i])) {
          humanClicks += value[0];
          botClicks += value[1];
        }
      }

      result.total.numClicks += humanClicks + botClicks;
      result.bot.numClicks += botClicks;

      if (site.oldCounts) {
        result.total.old += site.oldCounts[0] + site.oldCounts[1];
        result.bot.old += site.oldCounts[1];
        old = true;
      }
    });

    if (old) {
      result.total.percentage = utils.percentageIncrease(result.total.old, result.total.numClicks);
      result.bot.percentage = utils.percentageIncrease(result.bot.old, result.bot.numClicks);
    }
    else {
      result.total.percentage = null;
      result.bot.percentage = null;
    }

    if (result.total.numClicks !== 0) {
      result.bot.clickPercentage = result.bot.numClicks / result.total.numClicks * 100;
    }

    return result;
  }

  useEffect(() => {
    setTotalTraffic(aggregateSites(getSites('All')));
  }, [getSites]);

  useEffect(() => {
    const displayMode = localStorage.getItem('displayMode') || 'grid';
    setDisplayMode(displayMode);
  }, []);

  const setDisplay = (display) => {
    localStorage.setItem('displayMode', display);
    setDisplayMode(display);
  }

  const ref = useRef(null);

  return (
    <div style={{overflowY: 'auto',
                 padding: '20px',
                 height: '100%',
                 width: 'calc(100% - 40px)',
                }}
         ref={ref}
         onLoad={() => ref.current.scrollTop = position }
         onScroll={() => setPosition(ref.current.scrollTop)}>
      <div style={{display: 'flex',
                   flexDirection: 'row',
                   paddingBottom: '20px',
                   justifyContent: 'space-between',
                  }}>
        <DropDown id="period" title="Period" options={utils.generatePeriodOptions()} />
        {!isMobile && <AccountButtons setPage={setPage} />}
      </div>
      {isMobile && <h3>Account Overview</h3>}
      {!isMobile && <h2>Account Overview</h2>}
      {totalTraffic.total && (
        <div style={{display: 'flex',
                     flexDirection: isMobile ? 'column' : 'row',
                     width: 'calc(100%)',
                     gap: '20px',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                    }}>
          <TrafficCard
            title='TOTAL TRAFFIC'
            icon='click'
            numClicks={totalTraffic.total.numClicks}
            percentage={totalTraffic.total.percentage}/>

          <TrafficCard
            title='BOT TRAFFIC'
            icon='bot'
            numClicks={totalTraffic.bot.numClicks}
            clickPercentage={totalTraffic.bot.clickPercentage}
            percentage={totalTraffic.bot.percentage}/>
        </div>
      )}

      <div style={{
             display: 'flex',
             flexDirection: 'row',
             alignItems: 'center',
             justifyContent: 'space-between',
             width: '100%',
           }}>
        {isMobile && <h3>Websites</h3>}
        {!isMobile && <h2>Websites</h2>}

        {!isMobile && (
          <div style={{display: 'flex',
                       flexDirection: 'row',
                       gap: '10px',
                      }}>
            <GridIcon className={`image ${displayMode === 'list' ? 'image-gray' : ''}`}
                      style={{width: '20px',
                              height: '20px',
                             }}
                      onClick={() => setDisplay('grid')} />
            <ListIcon className={`image ${displayMode === 'grid' ? 'image-gray' : ''}`}
                      style={{width: '20px',
                              height: '20px',
                             }}
                      onClick={() => setDisplay('list')} />
          </div>
        )}

      </div>

      {!isMobile && displayMode === 'list' && (
        <div style={{width: '100%',
                     display: 'flex',
                     flexDirection: 'row',
                     padding: '20px',
                     boxSizing: 'border-box',
                    }}>
          <div style={{width: '40%',
                      }}>
            URL
          </div>
          <div style={{width: '20%',
                       display: 'flex',
                       justifyContent: 'center',
                      }}>
            FRAUD LEVEL
          </div>
          <div style={{width: '20%',
                       display: 'flex',
                       justifyContent: 'center',
                      }}>
            BOT ACTIVITY
          </div>
          <div style={{width: '20%',
                       display: 'flex',
                       justifyContent: 'center',
                      }}>
            PROTECTION STATE
          </div>
        </div>
      )}

      {!isMobile && displayMode === 'list' && <Separator />}

      {!isMobile && displayMode === 'list' && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  boxSizing: 'border-box',
                  width: '100%',
                  paddingBottom: '20px',
                }}>
                {getSites().map((site, index) => (
                  <div key={site.website}>
                    <Draggable draggableId={site.website}
                               index={index}>
                      {(provided) => (
                        <div style={{width: '100%',
                                    }}
                             ref={provided.innerRef}
                             {...provided.draggableProps}>

                          <SiteCard2 site={site}
                                     index={index}
                                     setPage={setPage}
                                     dragHandleProps={provided.dragHandleProps} />
                        </div>
                      )}
                    </Draggable>
                    <Separator />
                  </div>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {!isMobile && displayMode === 'grid' && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  boxSizing: 'border-box',
                  width: '90%',
                  paddingBottom: '20px',
                }}>
                {getSites().map((site, index) => (
                  <div key={site.website}>
                    <Draggable draggableId={site.website}
                               index={index}>
                      {(provided) => (
                        <div style={{width: '100%',
                                    }}
                             ref={provided.innerRef}
                             {...provided.draggableProps}>

                          <SiteCard site={site}
                                    setPage={setPage}
                                    dragHandleProps={provided.dragHandleProps} />
                        </div>
                      )}
                    </Draggable>
                    <Separator />
                  </div>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {isMobile && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  boxSizing: 'border-box',
                  width: 'calc(100%)',
                  paddingBottom: '20px',
                }}>
                {getSites().map((site, index) => (
                  <div key={site.website}>
                    <Draggable draggableId={site.website}
                               index={index}>
                      {(provided) => (
                        <div style={{width: '100%',
                                    }}
                             ref={provided.innerRef}
                             {...provided.draggableProps}>

                          <SiteCard3 site={site}
                                     setPage={setPage}
                                     dragHandleProps={provided.dragHandleProps} />
                        </div>
                      )}
                    </Draggable>
                    <Separator />
                  </div>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
}

export default Overview;
