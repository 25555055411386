import React from 'react';

import { ReactComponent as UpIcon } from '../images/upArrow.svg';
import { ReactComponent as DownIcon } from '../images/downArrow.svg';
import SeparatorImage from '../images/separator-blue.png';

const Question = ({ question, answer }) => {
  const [answerVisible, setAnswerVisible] = React.useState(false);

  return (
    <div>
      <div className='selected-box'
           style={{cursor: 'pointer',
                   padding: '5px 15px',
                   width: '100%',
                   boxSizing: 'border-box',
                   borderRadius: '5px',
                   display: 'flex',
                   flexDirection: 'column',
                  }} onClick={() => setAnswerVisible(!answerVisible)}>
        <div style={{display: 'flex',
                     flexDirection: 'row',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                    }}>
          <p>{question}</p>
          {answerVisible ?
           <UpIcon style={{width: '20px',
                           height: '20px',
                           whiteSpace: 'nowrap',
                           flexShrink: 0,
                          }} />
           :
           <DownIcon style={{width: '20px',
                             height: '20px',
                             whiteSpace: 'nowrap',
                             flexShrink: 0,
                            }} /> }
        </div>
        {answerVisible && (
          <img src={SeparatorImage}
               style={{width: '100%',
                       height: '2px',
                       padding: '10px',
                      }}
               alt='separator' />
        )}

        {answerVisible && (
          answer.split('\n').map((p, i) => {
            return (
              <p key={i} style={{fontSize: '14px',
                                }}>{p}</p>
            );
          }))}
      </div>
    </div>
  );
};

export default Question;
