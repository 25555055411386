import React from 'react';
import ScrollingText from '../../ScrollingText';

const Keyword = ({text, number, riskLevel}) => {
  const color = riskLevel === 'medium' ? '#f6a60c' : '#d42c2c';

  return (
    <div className='keyword'
         style={{display: 'flex',
                 flexDirection: 'row',
                 gap: '10px',
                 alignItems: 'center',
                 width: 'fit-content',
                 borderRadius: '5px',
                 cursor: 'pointer',
                }}>
      <span style={{padding: '10px',
                    maxWidth: '300px',
                    overflow: 'hidden',
                   }}>
        <ScrollingText text={text} width={300} />
      </span>
      <div style={{backgroundColor: color,
                   padding: '10px',
                   borderRadius: '5px',
                   fontWeight: 'bold',
                   width: '25px',
                   height: '25px',
                   display: 'flex',
                   justifyContent: 'center',
                   alignItems: 'center',
                   color: 'white',
                  }}>
        {number}
      </div>
    </div>
  );
}

export default Keyword;
