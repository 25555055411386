import React from 'react';

import { ReactComponent as SettingsIcon } from '../images/settings.svg';
import { ReactComponent as Spinner } from '../images/spinner.svg';
import { useMobile } from '../MobileContext';

const cardIcons = {
  visa: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png',
  mastercard: 'https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg',
  amex: 'https://upload.wikimedia.org/wikipedia/commons/3/30/American_Express_logo_%282018%29.svg',
  discover: 'https://upload.wikimedia.org/wikipedia/commons/5/56/Discover_Card_logo.svg',
};

const CardBrandIcon = ({ brand }) => {
  return (
    <img
      src={cardIcons[brand.toLowerCase()] || cardIcons['visa']}
      alt={brand}
      style={{ width: 50 }}
    />
  );
};

const CardCard = ({setModalVisible, paymentMethod, inModal}) => {
  const { isMobile } = useMobile();
  return (
    <div className='border-gradient'
         style={{width: isMobile ? '100%' : '450px',
                 height: inModal ? '270px' : '100%',
                 borderRadius: '10px',
                }}>
      <div className='gradient'
           style={{margin: '1px',
                   borderRadius: '10px',
                   padding: '20px',
                   display: 'flex',
                   flexDirection: 'column',
                   height: '100%',
                   boxSizing: 'border-box',
                   gap: '10px',
                   justifyContent: 'space-between',
                  }}>
        {!paymentMethod && (
          <div style={{
                 textAlign: 'center',
                 color: 'white',
                 height: '100%',
                 display: 'flex',
                 alignItems: 'center',
                 justifyContent: 'center',
               }}>
            <Spinner style={{
                       margin: '0 auto',
                       width: '50px',
                       height: '50px',
                       animation: 'spin 1s linear infinite',
                     }} />
          </div>
        )}

        {paymentMethod && (
          <>
            <div style={{display: 'flex',
                         flexDirection: 'row',
                         justifyContent: 'space-between',
                        }}>
              <span>CARD</span>
              {setModalVisible && (
                <SettingsIcon className='image'
                              style={{width: '25px',
                                      height: '25px',
                                      cursor: 'pointer',
                                     }}
                              onClick={() => {
                                setModalVisible(true);
                              }}
                />)}
            </div>

            <span style={{fontWeight: 'bold',
                          fontSize: '20px',
                         }}>
              <CardBrandIcon brand={paymentMethod.card.brand} />
            </span>
            <span style={{fontWeight: 'bold',
                          fontSize: '24px',
                         }}>
              **** **** **** {paymentMethod.card.last4}
            </span>

            <div style={{display: 'flex',
                         flexDirection: 'row',
                         justifyContent: 'space-between',
                        }}>
              <div style={{width: '50%',
                           display: 'flex',
                           flexDirection: 'column',
                           gap: '10px',
                          }}>
                <span style={{fontWeight: 'bold'}}>
                  Card Holder
                </span>
                <span>{paymentMethod.billing_details.name}</span>
              </div>
              <div style={{width: '50%',
                           display: 'flex',
                           flexDirection: 'column',
                           gap: '10px',
                          }}>
                <span style={{fontWeight: 'bold'}}>
                  Expiry Date
                </span>
                <span>{paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CardCard;
