import React from 'react';
import Input from '../Input';
import { ReactComponent as EyeOpenIcon } from '../images/eye-open.svg';
import { ReactComponent as EyeClosedIcon } from '../images/eye-closed.svg';

const PasswordInput = ({label, target, onChange}) => {
  let [show, setShow] = React.useState(false);
  let [warning, setWarning] = React.useState(null);

  let icon = show ?
    <EyeClosedIcon className='image2'
                   style={{width: '25px',
                           height: '25px',
                          }}
                   onClick={() => setShow(!show)}/> :
  <EyeOpenIcon className='image2'
               style={{width: '25px',
                       height: '25px',
                      }}
               onClick={() => setShow(!show)}/>;

  return (
    <div style={{width: '100%',
                 display: 'flex',
                 flexDirection: 'column',
                 gap: '10px',
                }}>
      <Input label={label}
             type={show ? 'text' : 'password'}
             icon={icon}
             borderColor={warning ? '#d42c2c' : null}
             onChange={(e) => {
               if (e.target.value.length < 8) {
                 setWarning('Password is less than 8 characters');
               }
               else if (!e.target.value.match(/[A-Z]/)) {
                 setWarning('Password does not contain an uppercase letter');
               }
               else if (!e.target.value.match(/[0-9]/)) {
                 setWarning('Password does not contain a number');
               }
               else if (target && e.target.value !== target) {
                 setWarning('Passwords do not match');
               }
               else {
                 setWarning(null);
               }

               if (onChange) {
                 onChange(e);
               }
             }}
      />
      {warning && (
        <span style={{fontSize: '13px',
                      color: '#d42c2c'
                     }}>
          {warning}
        </span>
      )}
    </div>
  );
}

export default PasswordInput;
