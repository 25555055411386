import React from 'react';
import Label from './Label';
import PieChart from './PieChart';
import * as utils from '../../utils';
import { useMobile } from '../../MobileContext';

const PieGraph = ({specs}) => {
  const { isMobile } = useMobile();
  const {values} = specs;

  let currentBotClicks = values.bot.reduce((a, b) => a + b, 0);
  let currentHumanClicks = values.human.reduce((a, b) => a + b, 0);
  let currentTotalClicks = currentHumanClicks + currentBotClicks;

  let previousHumanClicks = 0;
  let previousBotClicks = 0;

  if (values.old) {
    [previousHumanClicks, previousBotClicks] = values.old;
  }

  let previousTotalClicks = previousHumanClicks + previousBotClicks;

  let currentPercentage;

  if (currentHumanClicks === 0 && currentTotalClicks === 0) {
    currentPercentage = null;
  }
  else {
    currentPercentage = 100 * currentHumanClicks / currentTotalClicks;
  }

  let previousPercentage = 100 * previousHumanClicks / previousTotalClicks;

  let humanChange = utils.percentageIncrease(previousHumanClicks, currentHumanClicks)
  let botChange = utils.percentageIncrease(previousBotClicks, currentBotClicks)

  return (
    <div style={{width: '100%',
                 height: isMobile ? 'fit-content' : '100%',
                 display: 'flex',
                 flexDirection: isMobile ? 'column' : 'row',
                 alignItems: 'center',
                 justifyContent: 'space-around',
                }} >

      {values.old && (
        <div style={{width: isMobile ? '100%' : '30%',
                     aspectRatio: '1 / 1',
                    }}>

          <PieChart text={"PREVIOUS\nPERIOD"}
                    data={[
                      { label: 'Human Traffic',
                        value: previousPercentage.toFixed(2),
                        innerRadius: 92,
                        outerRadius: 170,
                        color: '#009de6' },

                      { label: 'Bot Traffic',
                        value: (100 - previousPercentage).toFixed(2),
                        innerRadius: 116,
                        outerRadius: 152,
                        color: '#ff4d4d' },
                    ]} />
        </div>
      )}

      {!values.old && (
        <div style={{width: isMobile ? '100%' : '30%',
                     aspectRatio: '1 / 1',
                    }}>

          <PieChart text={"NO PREVIOUS\nPERIOD"}
                    disabled={true}
                    data={[
                      { label: 'Human Traffic',
                        value: 80,
                        innerRadius: 92,
                        outerRadius: 170,
                        color: '#009de6' },

                      { label: 'Bot Traffic',
                        value: 20,
                        innerRadius: 116,
                        outerRadius: 152,
                        color: '#ff4d4d' },
                    ]} />
        </div>
      )}

      {currentPercentage && (
        <div style={{width: isMobile ? '100%' : '30%',
                     aspectRatio: '1 / 1',
                    }}>
          <PieChart text={"CURRENT\nPERIOD"}
                    data={[
                      { label: 'Human Traffic',
                        value: currentPercentage.toFixed(2),
                        innerRadius: 92,
                        outerRadius: 170,
                        color: '#009de6' },

                      { label: 'Bot Traffic',
                        value: (100 - currentPercentage).toFixed(2),
                        innerRadius: 116,
                        outerRadius: 152,
                        color: '#ff4d4d' },
                    ]} />
        </div>
      )}

      {!currentPercentage && (
        <div style={{width: isMobile ? '100%' : '30%',
                     aspectRatio: '1 / 1',
                    }}>

          <PieChart text={"NO CURRENT\nPERIOD"}
                    disabled={true}
                    data={[
                      { label: 'Human Traffic',
                        value: 80,
                        innerRadius: 92,
                        outerRadius: 170,
                        color: '#009de6' },

                      { label: 'Bot Traffic',
                        value: 20,
                        innerRadius: 116,
                        outerRadius: 152,
                        color: '#ff4d4d' },
                    ]} />
        </div>
      )}


      <div style={{width: isMobile ? '100%' : '350px',
                  }}>
        <Label text="Human Traffic"
               color="#009de6"
               percentage={humanChange}
               disabled={!values.old} />
        <Label text="Bot Traffic"
               color="#ff4d4d"
               showRed={true}
               percentage={botChange}
               disabled={!values.old} />
      </div>
    </div>
  );
}

export default PieGraph;
