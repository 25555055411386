import React, { useEffect } from 'react';
import SidePanel from './dashboard/SidePanel';
import Overview from './Overview';
import SiteOverview from './SiteOverview';
import Help from './Help';
import Profile from './Profile';
import Settings from './Settings';
import AddSiteModal from './AddSiteModal';
import { useSites } from './SitesContext';
import { useNavigate } from 'react-router-dom';
import { useMobile } from './MobileContext';

import { ReactComponent as Logo } from './images/logo.svg';
import { ReactComponent as MenuIcon } from './images/menu2.svg';

const Dashboard = () => {
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const { getSites, fetchSites } = useSites();
  const [page, setPage] = React.useState('overview');
  const [addSiteModalVisible, setAddSiteModalVisible] = React.useState(false);
  const [position, setPosition] = React.useState(0);
  const [previousPage, setPreviousPage] = React.useState('overview');

  useEffect(() => {
    let token = localStorage.getItem('token');

    if (token) {
      fetch(process.env.REACT_APP_SERVER_URL + '/api/validate-token', {
        method: 'POST',
        headers: {
          'Authorization': token
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            fetchSites().then(() => {
              console.log('fetched!');
              navigate('/dashboard');
            });
          }
          else if (data.message === 'invalid token!') {
            console.log('unauthorized access')
            localStorage.removeItem('token');
            navigate('/');
          }
        })
        .catch(error => {
          console.log('Automatic login failed');
          localStorage.removeItem('token');
          navigate('/');
        });
    }
    else {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSite = () => {
    let id = parseInt(page.split('_')[1]);
    let sites = getSites();
    for (let i = 0; i < sites.length; i++) {
      if (sites[i].id === id) {
        return sites[i];
      }
    }
    return null;
  }

  if (!getSites()) return null;

  let content = (
    <>
      {(page === 'overview' || (!isMobile && page === 'side-panel')) && (
        <Overview setPage={setPage} position={position} setPosition={setPosition}/>
      )}

      {page.startsWith('site') && (
        <SiteOverview site={getSite()} setPage={setPage} />
      )}

      {page.startsWith('help') && (
        <Help setPage={setPage} />
      )}

      {page.startsWith('profile') && (
        <Profile setPage={setPage} />
      )}

      {page.startsWith('settings') && (
        <Settings setPage={setPage}/>
      )}
    </>
  );

  return (
    <>
      {addSiteModalVisible && (
        <AddSiteModal setAddSiteModalVisible={setAddSiteModalVisible}
                      existingNames={getSites().map(site => site.website)} />
      )}

      {isMobile && (
        <div style={{display: 'flex',
                     height: page === 'side-panel' ? '100vh' : '100%',
                     overflow: 'scroll',
                     flexDirection: 'column',
                    }}>
          {page === 'side-panel' && (
            <SidePanel sites={getSites()}
                       previousPage={previousPage}
                       page={page}
                       setPage={setPage}
                       setAddSiteModalVisible={setAddSiteModalVisible}/>
          )}

          {page !== 'side-panel' && (
            <>
              <div style={{display: 'flex',
                           flexDirection: 'row',
                           justifyContent: 'space-between',
                           alignItems: 'center',
                           height: '30px',
                           padding: '0 20px',
                           marginBottom: '10px',
                           marginTop: '10px',
                           width: 'calc(100% - 40px)',
                          }}>
                <Logo className='image'
                      style={{height: '100%',
                             }}
                      onClick={() => navigate('/')} />

                <MenuIcon className='image'
                          style={{height: '100%',
                                 }}
                          onClick={() => {
                            setPreviousPage(page)
                            setPage('side-panel')
                          }} />
              </div>
              {content}
            </>
          )}
        </div>
      )}

      {!isMobile && (
        <div style={{display: 'flex',
                     height: '100vh',
                    }}>

          <div style={{width: '350px',
                       borderRight: '2px solid #21242e',
                       flexShrink: 0,
                      }}>
            <SidePanel sites={getSites()}
                       page={page}
                       setPage={setPage}
                       setAddSiteModalVisible={setAddSiteModalVisible}/>
          </div>

          <div style={{height: '100%',
                       flexGrow: 1,
                       display: 'flex',
                       flexDirection: 'column',
                       gap: '20px',
                       overflow: 'scroll',
                      }}>
            {content}
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
