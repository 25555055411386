import React from 'react';
import { useMobile } from '../../MobileContext';
import Modal from '../../Modal';

import { ReactComponent as CloseIcon } from '../../images/x.svg';

const WhenStartedModal = ({setVisible}) => {
  const { isMobile } = useMobile();

  return (
    <Modal isOpen={true}>
      <div style={{
             background: 'linear-gradient(gray, transparent)',
             zIndex: '101',
             position: 'absolute',
             transform: 'translate(-50%, -50%)',
             top: '50%',
             left: '50%',
             width: isMobile ? '95%' : '600px',
             borderRadius: '10px',
             maxHeight: '100vh',
             overflowY: 'auto',
           }}>
        <div className='modal'
             style={{
               margin: '1px',
               borderRadius: '10px',
               zIndex: '101',
               display: 'flex',
               justifyContent: 'center',
               flexDirection: 'column',
               padding: '20px',
             }}>
          <div style={{
                 display: 'flex',
                 flexDirection: 'row',
                 alignItems: 'center',
                 justifyContent: 'space-between',
                 width: '100%',
               }}>
            <span style={{fontSize: '22px',
                          fontWeight: 'bold',
                         }}>
              This is where you started
            </span>
            <CloseIcon className='image'
                       style={{width: '15px',
                               height: '15px',
                              }}
                       onClick={() => setVisible(false)} />
          </div>

          <p>Explanation of graph</p>

          <div style={{display: 'flex',
                       flexDirection: 'row',
                       alignItems: 'center',
                       marginTop: '10px',
                       gap: '10px',
                       justifyContent: 'center',
                      }}>
            <div style={{alignItems: 'center',
                         width: 'fit-content',
                         padding: '10px 20px',
                         border: '1px solid #e0e0e0',
                         borderRadius: '5px',
                         cursor: 'pointer',
                         fontWeight: 'bold',
                         alignSelf: 'flex-end',
                        }}
                 onClick={() => setVisible(false)}>
              Got it
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default WhenStartedModal;
