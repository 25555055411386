import React from 'react';
import Shield from '../Shield';
import TrafficButtons from '../TrafficButtons';
import IconBox from '../IconBox';

import { ReactComponent as DragHandleIcon } from '../images/dragHandle.svg';
import { ReactComponent as BotIcon } from '../images/bot.svg';

const SiteCard2 = ({ site, dragHandleProps, setPage }) => {
  let botClicks = 0;
  let humanClicks = 0;

  for (let i = 0; i < site.data.length; i++) {
    for (const value of Object.values(site.data[i])) {
      humanClicks += value[0];
      botClicks += value[1];
    }
  }

  let totalClicks = botClicks + humanClicks;
  let clickPercentage = 0;

  if (totalClicks !== 0) {
    clickPercentage = (botClicks / totalClicks) * 100;
  }

  return (
    <div style={{width: '100%',
                 display: 'flex',
                 flexDirection: 'row',
                 boxSizing: 'border-box',
                }}>
      <div style={{width: '40%',
                   display: 'flex',
                   flexDirection: 'column',
                   gap: '20px',
                   justifyContent: 'center',
                   cursor: 'pointer',
                  }}
           onClick={() => setPage('site_' + site.id)}>
        <div style={{display: 'flex',
                     flexDirection: 'row',
                     gap: '10px',
                     alignItems: 'center',
                    }}>
          <div {...dragHandleProps}
               style={{ cursor: 'grab' }}>
            <DragHandleIcon style={{ width: '15px',
                                     height: '15px',
                                     fill: 'gray'
                                   }}/>
          </div>
          <IconBox website={site.website} />
          <span style={{fontWeight: 'bold',
                        fontSize: '20px',
                       }}>
            {site.website}
          </span>
        </div>
      </div>

      <div style={{width: '20%',
                   display: 'flex',
                   flexDirection: 'row',
                   justifyContent: 'center',
                  }}>
        <div style={{width: '200px',
                     display: 'flex',
                     flexDirection: 'row',
                     alignItems: 'center',
                     gap: '10px',
                    }}>
          <Shield fraudLevel={site.fraudLevel} />
          {site.fraudLevel} fraud level
        </div>
      </div>

      <div style={{width: '20%',
                   display: 'flex',
                   gap: '20px',
                   alignItems: 'center',
                   justifyContent: 'center',
                  }}>
        <div style={{width: '220px',
                     display: 'flex',
                     flexDirection: 'row',
                     gap: '10px',
                     justifyContent: 'left',
                    }}>
          <BotIcon style={{ fill: '#009de6',
                            width: '30px',
                            height: '30px' }} />
          <span style={{fontSize: '20px', }}>
            {botClicks.toLocaleString()}
          </span>
          {clickPercentage !== undefined && (
            <>
              <span style={{fontSize: '20px', }}>
                |
              </span>
              <span style={{fontSize: '20px', }}>
                {clickPercentage.toFixed(2)}%
              </span>
            </>
          )}
        </div>
      </div>

      <div style={{width: '20%'}}>
        <TrafficButtons gap={'10px'} site={site}/>
      </div>
    </div>
  );
}

export default SiteCard2;
