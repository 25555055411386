import React from 'react';
import { useTheme } from './ThemeContext';

import SeparatorImage from './images/separator.png';
import SeparatorBlueImage from './images/separator-blue.png';

import SeparatorVerticalImage from './images/separator-vertical.png';
import SeparatorBlueVerticalImage from './images/separator-blue-vertical.png';


const Separator = ({vertical}) => {
  const { isDarkMode } = useTheme();

  const style = vertical ? {
    width: '2px',
    height: '100%',
  } : {
    width: '100%',
    height: '2px',
  };

  const src = vertical ?
        (isDarkMode ? SeparatorVerticalImage : SeparatorBlueVerticalImage) :
        (isDarkMode ? SeparatorImage : SeparatorBlueImage);

  return (
    <img src={src} style={style} alt="separator"/>
  );
}

export default Separator;
