import React from 'react';
import Input from '../Input';
import PasswordInput from './PasswordInput';
import { useMobile } from '../MobileContext';

import { ReactComponent as PadlockIcon } from '../images/padlock.svg';

const UserCard = ({user, setUser}) => {
  const { isMobile } = useMobile();
  let { fullName, telephone, companyName, email } = user;

  return (
    <div style={{width: '100%'}}>
      <h4>Primary User</h4>
      <div style={{display: 'flex',
                   flexDirection: 'column',
                   gap: '20px',
                   boxSizing: 'border-box',
                   width: '100%',
                  }}>

        <div style={{display: 'flex',
                     flexDirection: isMobile ? 'column' : 'row',
                     width: '100%',
                     gap: '20px',
                    }}>
          <Input label='FULL NAME'
                 value={fullName}
                 onChange={(e) => setUser({...user, fullName: e.target.value})} />
          <Input label='COMPANY NAME'
                 value={companyName}
                 onChange={(e) => setUser({...user, companyName: e.target.value})} />
        </div>

        <div style={{display: 'flex',
                     flexDirection: isMobile ? 'column' : 'row',
                     gap: '20px',
                    }}>
          <Input label='EMAIL'
                 value={email}
                 onChange={(e) => {}}
                 icon={<PadlockIcon className='image'
                                    style={{width: '25px',
                                            height: '25px',
                                           }}/>} />
          <Input label='TELEPHONE'
                 value={telephone}
                 onChange={(e) => setUser({...user, telephone: e.target.value})} />
        </div>

        <div style={{display: 'flex',
                     flexDirection: 'row',
                     gap: '20px',
                    }}>
          <span style={{fontSize: '13px',
                        width: '100%',
                        color: 'gray',
                       }}>
            To change your email address, please contact team@polygraph.net
          </span>
        </div>

        <div style={{display: 'flex',
                     flexDirection: isMobile ? 'column' : 'row',
                     gap: '20px',

                    }}>
          <PasswordInput label='NEW PASSWORD'
                         onChange={(e) => setUser({...user, password: e.target.value})}
                         showWarning={true} />
          <PasswordInput label='CONFIRM NEW PASSWORD'
                         target={user.password}
                         onChange={(e) => setUser({...user, password2: e.target.value})}
                         showWarning={true} />
        </div>

        <div style={{display: 'flex',
                     flexDirection: 'row',
                     gap: '20px',
                    }}>
          <span style={{fontSize: '13px',
                        width: '100%',
                        color: 'gray',
                       }}>
            8+ characters, with min one number and one uppercase character.
          </span>
        </div>
      </div>
    </div>
  );
}

export default UserCard;
