import React, { useState } from 'react';
import Input from './Input';
import { useMobile } from '../MobileContext';
import Modal from '../Modal';

import { ReactComponent as CloseIcon } from '../images/x.svg';

const ForgotPasswordModal = ({setVisible}) => {
  const { isMobile } = useMobile();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);

  const resetPassword = () => {
    fetch(process.env.REACT_APP_SERVER_URL + '/api/forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: email.trim() })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setMessage('Please check your email with instructions');
        }
        else {
          setMessage('Email not found');
        }
      })
  };

  return (
    <Modal isOpen={true}>
      <div style={{zIndex: '101',
                   backgroundColor: 'white',
                   color: 'black',
                   position: 'absolute',
                   transform: 'translate(-50%, -50%)',
                   top: '50%',
                   left: '50%',
                   margin: '1px',
                   width: isMobile ? '95%' : '600px',
                   boxSizing: 'border-box',
                   borderRadius: '5px',
                   display: 'flex',
                   justifyContent: 'center',
                   flexDirection: 'column',
                   padding: isMobile ? '20px' : '40px',
                   gap: '20px',
                  }}>
        <div style={{
               display: 'flex',
               flexDirection: 'row',
               alignItems: 'center',
               justifyContent: 'space-between',
               width: '100%',
             }}>
          <span style={{fontSize: '22px',
                        fontWeight: 'bold',
                       }}>
            Forgot password
          </span>
          <CloseIcon className='image'
                     style={{width: '15px',
                             height: '15px',
                             fill: 'black',
                            }}
                     onClick={() => setVisible(false)} />
        </div>

        {!message && (
          <>
            <span style={{color: 'gray'}}>
              Enter your email address to get a link to reset your password
            </span>

            <Input value={email}
                   placeholder='Enter email'
                   type='email'
                   onChange={(e) => {
                     setEmail(e.target.value);
                   }}/>

            <div style={{display: 'flex',
                         flexDirection: isMobile ? 'column-reverse' : 'row',
                         alignItems: 'center',
                         marginTop: '10px',
                         gap: '10px',
                         justifyContent: 'center',
                        }}>
              <div style={{alignItems: 'center',
                           width: '100%',
                           boxSizing: 'border-box',
                           padding: '10px 20px',
                           border: '3px solid #009de6',
                           color: '#009de6',
                           borderRadius: '5px',
                           cursor: 'pointer',
                           fontWeight: 'bold',
                           display: 'flex',
                           justifyContent: 'center',
                          }}
                   onClick={() => setVisible(false)}>
                <span>Cancel</span>
              </div>
              <div style={{alignItems: 'center',
                           width: '100%',
                           padding: '10px 20px',
                           boxSizing: 'border-box',
                           backgroundColor: '#009de6',
                           border: '3px solid #009de6',
                           color: 'white',
                           borderRadius: '5px',
                           cursor: 'pointer',
                           fontWeight: 'bold',
                           display: 'flex',
                           justifyContent: 'center',
                          }}
                   onClick={resetPassword}>
                Reset Password
              </div>
            </div>
          </>
        )}

        {message && (
          <>
            <span style={{color: 'gray'}}>
              {message}
            </span>

            <div style={{display: 'flex',
                         flexDirection: 'row',
                         alignItems: 'center',
                         marginTop: '10px',
                         gap: '10px',
                         justifyContent: 'center',
                        }}>
              <div style={{alignItems: 'center',
                           width: isMobile ? '100%' : '200px',
                           boxSizing: 'border-box',
                           padding: '10px 20px',
                           backgroundColor: '#009de6',
                           border: '3px solid #009de6',
                           color: 'white',
                           borderRadius: '5px',
                           cursor: 'pointer',
                           fontWeight: 'bold',
                           alignSelf: 'flex-end',
                           display: 'flex',
                           justifyContent: 'center',
                          }}
                   onClick={() => setVisible(false)}>
                <span>Close</span>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

export default ForgotPasswordModal;
