import React from 'react';
import Chart from './detection-types/Chart';
import HighRiskCard from './detection-types/HighRiskCard';
import SourcesCard from './detection-types/SourcesCard';
import { useMobile } from '../MobileContext';

const DetectionTypes = ({site}) => {
  const { isMobile } = useMobile();

  return (
    <>
      <h3>Detection types</h3>
      {isMobile && (
        <div style={{display: 'flex',
                     flexDirection: 'column',
                     gap: '20px',
                    }}>
          <Chart site={site} />
          <HighRiskCard site={site} />
          <SourcesCard site={site} />
        </div>
      )}

      {!isMobile && (
        <div style={{display: 'flex',
                     flexDirection: 'row',
                     gap: '20px',
                     boxSizing: 'border-box',
                     height: '400px',
                    }}>
          <div style={{flexGrow: 1,
                       borderRadius: '10px',
                      }}>
            <Chart site={site} />
          </div>
          <div style={{width: '400px',
                       borderRadius: '10px',
                       gap: '20px',
                       display: 'flex',
                       flexDirection: 'column',
                      }}>
            <HighRiskCard site={site} />
            <SourcesCard site={site} />
          </div>
        </div>
      )}
    </>
  );
}

export default DetectionTypes;
