import React from 'react';

const IconBox = ({website}) => {
  let domain = new URL(`http://${website}`).hostname;
  const faviconUrl = `https://www.google.com/s2/favicons?sz=64&domain=${domain}`;

  return (
    <div className="f-box"
         style={{
           width: '40px',
           height: '40px',
           borderRadius: '3px',
           alignItems: 'center',
           fontWeight: 'bold',
           fontSize: '20px',
           display: 'flex',
           flexDirection: 'row',
           alignContent: 'center',
           justifyContent: 'center',
           flexShrink: 0,
         }}>
      <img
        src={faviconUrl}
        alt={`${website} favicon`}
        style={{ width: '32px', height: '32px'}}
      />
    </div>
  );
}

export default IconBox;
