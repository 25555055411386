import React from 'react';
import Shield from '../Shield';
import TrafficButtons from '../TrafficButtons';
import IconBox from '../IconBox';
import { useMobile } from '../MobileContext';

import { ReactComponent as TrashIcon } from '../images/trash.svg';
import { ReactComponent as DragHandleIcon } from '../images/dragHandle.svg';
import { ReactComponent as EditIcon } from '../images/edit.svg';

const SiteCard = ({site, setDeleteSiteModalVisible, setEditedIndex, setSiteIndex, index, dragHandleProps }) => {
  const { isMobile } = useMobile();

  return (
    <div style={{width: '100%',
                 display: 'flex',
                 flexDirection: isMobile ? 'column' : 'row',
                 boxSizing: 'border-box',
                 gap: '20px',
                }}>

      <div style={{width: isMobile ? '100%' : '60%',
                   display: 'flex',
                   flexDirection: 'row',
                   gap: '20px',
                   justifyContent: 'space-between',
                  }}>
        <div style={{display: 'flex',
                     flexDirection: 'row',
                     gap: '10px',
                     alignItems: 'center',
                    }}>
          <div {...dragHandleProps}
               style={{ cursor: 'grab' }}>
            <DragHandleIcon style={{ width: '15px',
                                     height: '15px',
                                     fill: 'gray'
                                   }}/>
          </div>
          <IconBox website={site.website} />
          <span style={{fontWeight: 'bold',
                        fontSize: '20px',
                       }}>
            {site.website}
          </span>
        </div>
        {isMobile && <Shield fraudLevel={site.fraudLevel} /> }
      </div>

      {!isMobile && (
        <div style={{width: '20%',
                     display: 'flex',
                     flexDirection: 'row',
                     justifyContent: 'center',
                    }}>
          <div style={{width: '200px',
                       display: 'flex',
                       flexDirection: 'row',
                       alignItems: 'center',
                       gap: '10px',
                      }}>
            <Shield fraudLevel={site.fraudLevel} />
            {site.fraudLevel} fraud level
          </div>
        </div>
      )}

      <div style={{...(isMobile ? {width: '100%'} : {width: '20%'}),
                   display: 'flex',
                   justifyContent: isMobile ? 'space-between' : 'center',
                  }}>
        <TrafficButtons gap='10px' site={site} direction={isMobile ? 'row' : 'column'} />

        {isMobile && (
          <div style={{width: '20%',
                       display: 'flex',
                       gap: '20px',
                       alignItems: 'center',
                       justifyContent: 'center',
                      }}>
            <TrashIcon className='image'
                       style={{ width: '20px',
                                height: '20px'}}
                       onClick={() => {
                         setSiteIndex(index);
                         setDeleteSiteModalVisible(true);
                       }} />

            <EditIcon className='image'
                      style={{ width: '20px',
                               height: '20px'}}
                      onClick={() => setEditedIndex(index)} />
          </div>
        )}
      </div>

      {!isMobile && (
        <div style={{width: '20%',
                     display: 'flex',
                     gap: '20px',
                     alignItems: 'center',
                     justifyContent: 'center',
                    }}>
          <TrashIcon className='image'
                     style={{ width: '20px',
                              height: '20px'}}
                     onClick={() => {
                       setSiteIndex(index);
                       setDeleteSiteModalVisible(true);
                     }} />

          <EditIcon className='image'
                    style={{ width: '20px',
                             height: '20px'}}
                    onClick={() => setEditedIndex(index)} />
        </div>
      )}
    </div>
  );
}

export default SiteCard;
