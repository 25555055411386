import React from 'react';
import Input from '../Input';
import PasswordInput from './PasswordInput';
import { useMobile } from '../MobileContext';
import Modal from '../Modal';

import { ReactComponent as CloseIcon } from '../images/x.svg';

const AddExtraUserModal = ({setVisible, addUser}) => {
  const { isMobile } = useMobile();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  return (
    <Modal isOpen={true}>
      <div style={{
             background: 'linear-gradient(gray, transparent)',
             zIndex: '101',
             position: 'absolute',
             transform: 'translate(-50%, -50%)',
             top: '50%',
             left: '50%',
             width: isMobile ? '95%' : '600px',
             borderRadius: '10px',
           }}>
        <div className='modal'
             style={{
               margin: '1px',
               borderRadius: '10px',
               zIndex: '101',
               display: 'flex',
               justifyContent: 'center',
               flexDirection: 'column',
               padding: '20px',
             }}>
          <div style={{
                 display: 'flex',
                 flexDirection: 'row',
                 alignItems: 'center',
                 justifyContent: 'space-between',
                 width: '100%',
               }}>
            <span style={{fontSize: '22px',
                          fontWeight: 'bold',
                         }}>
              Add an extra user
            </span>
            <CloseIcon className='image'
                       style={{width: '15px',
                               height: '15px',
                              }}
                       onClick={() => setVisible(false)} />
          </div>

          <Input label='EMAIL'
                 onChange={(e) => setEmail(e.target.value)} />
          <PasswordInput label='NEW PASSWORD'
                         onChange={(e) => setPassword(e.target.value)}
                         showWarning={true}/>
          <PasswordInput label='CONFIRM NEW PASSWORD'
                         target={password}
                         onChange={(e) => setConfirmPassword(e.target.value)}
                         showWarning={true}/>

          <div style={{display: 'flex',
                       flexDirection: 'row',
                       alignItems: 'center',
                       marginTop: '10px',
                       gap: '10px',
                       justifyContent: 'center',
                      }}>
            <div style={{alignItems: 'center',
                         ...(isMobile ? { flexGrow: 1 } : { width: 'fit-content' }),
                         padding: '10px 20px',
                         border: '1px solid #e0e0e0',
                         borderRadius: '5px',
                         cursor: 'pointer',
                         fontWeight: 'bold',
                         alignSelf: 'flex-end',
                         display: 'flex',
                         justifyContent: 'center',
                        }}
                 onClick={() => setVisible(false)}>
              Cancel
            </div>
            <div style={{alignItems: 'center',
                         ...(isMobile ? { flexGrow: 1 } : { width: 'fit-content' }),
                         padding: '10px 20px',
                         backgroundColor: '#009de6',
                         color: 'white',
                         borderRadius: '5px',
                         cursor: 'pointer',
                         fontWeight: 'bold',
                         alignSelf: 'flex-end',
                         display: 'flex',
                         justifyContent: 'center',
                        }}
                 onClick={() => {
                   if (password === confirmPassword) {
                     addUser(email, password);
                     setVisible(false);
                   }
                 }}>
              Add User
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddExtraUserModal;
