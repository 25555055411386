import React from 'react';
import TitleCard from './overview/TitleCard';
import TrafficCard from './overview/TrafficCard';
import LiveBotTrackingGraph from './overview/LiveBotTrackingGraph';
import WhenStartedGraph from './overview/WhenStartedGraph';
import TrafficWhenStartedCard from './overview/TrafficWhenStartedCard';
import { useMobile } from '../MobileContext';

const OverView = ({site}) => {
  const { isMobile } = useMobile();

  return (
    <>
      <h3>Overview</h3>

      {isMobile && (
        <div style={{display: 'flex',
                     flexDirection: 'column',
                     gap: '20px',
                     boxSizing: 'border-box',
                     marginBottom: '50px',
                    }}>
          <TitleCard site={site} />
          <TrafficCard site={site} />
          <LiveBotTrackingGraph site={site} />
          <TrafficWhenStartedCard site={site} />
          <div style={{height: '300px'}} >
            <WhenStartedGraph site={site} />
          </div>
        </div>
      )}

      {!isMobile && (
        <div style={{display: 'flex',
                     flexDirection: 'column',
                     gap: '20px',
                     boxSizing: 'border-box',
                     marginBottom: '50px',
                    }}>
          <div style={{width: '100%',
                       height: 'fit-content',
                       display: 'flex',
                       flexDirection: 'row',
                       boxSizing: 'border-box',
                       gap: '20px',
                      }}>
            <div style={{height: '460px',
                         width: '25%',
                         display: 'flex',
                         flexDirection: 'column',
                         gap: '20px',
                        }}>
              <TitleCard site={site} />
              <TrafficCard site={site} />
            </div>

            <div style={{height: '460px',
                         width: '75%',
                         display: 'flex',
                         flexDirection: 'column',
                         gap: '20px',
                        }}>
              <LiveBotTrackingGraph site={site} />
            </div>
          </div>
          <div style={{height: '200px',
                       display: 'flex',
                       flexDirection: 'row',
                       gap: '20px',
                      }}>
            <div style={{width: '75%'}}>
              <WhenStartedGraph site={site} />
            </div>
            <div style={{width: '25%'}}>
              <TrafficWhenStartedCard site={site} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OverView;
