
export const tiers = [
  { maxTraffic : 25000    , price: 0.018  },
  { maxTraffic : 50000    , price: 0.016  },
  { maxTraffic : 100000   , price: 0.015  },
  { maxTraffic : 200000   , price: 0.0125 },
  { maxTraffic : 500000   , price: 0.009  },
  { maxTraffic : 1000000  , price: 0.006  },
  { maxTraffic : 3000000  , price: 0.0032 },
  { maxTraffic : 15000000 , price: 0.0013 },
]
