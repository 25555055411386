import React, { useState, useEffect } from 'react';

const ScrollingText = ({text, width}) => {
  const [position, setPosition] = useState(0);
  const [direction, setDirection] = useState(-1);
  const [isAnimating, setIsAnimating] = useState(false);
  const [offset, setOffset] = useState(0);

  const getTextWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  useEffect(() => {
    setOffset(width - getTextWidth(text, "16px Poppins"));
  }, [text, width]);

  useEffect(() => {
    if (isAnimating) {
      const step = () => {
        setPosition((prev) => {
          if ((prev <= offset && direction === -1) ||
              (prev >= 0 && direction === 1)) {
            setDirection(direction * -1);
          }
          return prev + direction * 0.2;
        });
      };
      const animationFrame = requestAnimationFrame(step);
      return () => cancelAnimationFrame(animationFrame);
    }
  }, [position, isAnimating, direction, offset]);

  return (
    <div style={{maxWidth: `${width}px`,
                 overflow: 'hidden',
                 whiteSpace: 'nowrap',
                 padding: '5px',
                }}>
      <span style={{transform: `translateX(${position}px)`,
                    display: 'inline-block',
                    fontSize: '16px',
                   }}
            onMouseOver={() => setIsAnimating(true)}
            onMouseOut={() => {
              setPosition(0);
              setIsAnimating(false)
            }}>
        {text}
      </span>
    </div>
  );
}

export default ScrollingText;
