import React from 'react';
import SourcesModal from './SourcesModal';
import ScrollingText from '../../ScrollingText';

import { ReactComponent as InfoIcon } from '../../images/info.svg';

const SourcesCard = ({site}) => {
  const [modalVisible, setModalVisible] = React.useState(false);

  let sources = {};

  for (let entries of site.data) {
    for (let entry of Object.values(entries)) {
      let ss = entry[3];

      for (let source of ss) {
        if (source.source in sources) {
          sources[source.source] += source.count;
        } else {
          sources[source.source] = source.count;
        }
      }
    }
  }

  let data = [];

  for (let source in sources) {
    data.push({source: source,
               clicks: sources[source]});
  }

  data.sort((a, b) => {
    return b.clicks - a.clicks;
  });

  return (
    <div className='gradient'
         style={{borderRadius: '10px',
                 height: '100%',
                 padding: '20px',
                 display: 'flex',
                 flexDirection: 'column',
                 gap: '20px',
                 overflow: 'auto',
                 boxSizing: 'border-box',
                }}>
      {modalVisible && <SourcesModal setVisible={setModalVisible} />}
      <div style={{display: 'flex',
                   flexDirection: 'row',
                   justifyContent: 'space-between',
                   alignItems: 'center',
                  }}>
        <span>SOURCES</span>
        <InfoIcon className='image'
                  style={{ width: '20px',
                           height: '20px',
                         }}
                  onClick={() => setModalVisible(true)}/>
      </div>
      {data.map((item, index) => {
        return (
          <div key={index}
               style={{display: 'flex',
                       flexDirection: 'row',
                       justifyContent: 'space-between',
                       gap: '10px',
                      }}>
            <ScrollingText text={item.source} width={300} />
            <span> {item.clicks}</span>
          </div>
        );
      })}
    </div>
  );
}

export default SourcesCard;
