import React from 'react';
import HighRiskKeywordsModal from './HighRiskKeywordsModal';
import Keyword from './Keyword';

import { ReactComponent as InfoIcon } from '../../images/info.svg';

const HighRiskCard = ({site}) => {
  const [modalVisible, setModalVisible] = React.useState(false);

  let keywords = {};

  for (let entries of site.data) {
    for (let entry of Object.values(entries)) {
      let ks = entry[2];

      for (let keyword of ks) {
        if (keyword.text in keywords) {
          keywords[keyword.text] += keyword.number;
        } else {
          keywords[keyword.text] = keyword.number;
        }
      }
    }
  }

  let data = [];

  for (let keyword in keywords) {
    data.push({text: keyword,
               number: keywords[keyword],
               riskLevel: keywords[keyword] > 10 ? 'high' : 'medium'});
  }

  data.sort((a, b) => {
    return b.number - a.number;
  });

  return (
    <div className='gradient'
         style={{borderRadius: '10px',
                 height: '100%',
                 padding: '20px',
                 display: 'flex',
                 flexDirection: 'column',
                 gap: '20px',
                 flexGrow: 1,
                 overflow: 'auto',
                 boxSizing: 'border-box',
                }}>
      {modalVisible && <HighRiskKeywordsModal data={data}
                                              setVisible={setModalVisible} />}
      <div style={{display: 'flex',
                   flexDirection: 'row',
                   justifyContent: 'space-between',
                   alignItems: 'center',
                  }}>
        <span>HIGH RISK KEYWORDS</span>
        <InfoIcon className='image'
                  style={{ width: '20px',
                           height: '20px',
                         }}
                  onClick={() => setModalVisible(true)}/>
      </div>
      <div style={{display: 'flex',
                   flexWrap: 'wrap',
                   gap: '10px',
                  }}>
        {data.map((keyword, index) => {
          return <Keyword key={index}
                          text={keyword.text}
                          number={keyword.number}
                          riskLevel={keyword.riskLevel} />
        })}
      </div>
    </div>
  );
}

export default HighRiskCard;
