import React, { createContext, useState, useContext, useEffect } from 'react';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    const darkMode = localStorage.getItem('darkMode') || 'true';
    setIsDarkMode(darkMode === 'true');
  }, []);

  const toggleTheme = () => {
    localStorage.setItem('darkMode', !isDarkMode);
    setIsDarkMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    const theme = isDarkMode ? 'dark-theme.css' : 'light-theme.css';
    const existingLink = document.getElementById('theme-style');

    if (existingLink) {
      existingLink.href = theme;
    } else {
      const link = document.createElement('link');
      link.id = 'theme-style';
      link.rel = 'stylesheet';
      link.href = theme;
      document.head.appendChild(link);
    }
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
