import React, { createContext, useContext, useState } from 'react';

const DropDownContext = createContext();

export const DropDownProvider = ({ children }) => {
  const [dropdownStates, setDropdownStates] = useState({});

  const setSelectedOption = (id, value) => {
    setDropdownStates((prev) => ({ ...prev, [id]: value }));
  };

  const getSelectedOption = (id) => dropdownStates[id] || null;

  return (
    <DropDownContext.Provider value={{ getSelectedOption, setSelectedOption }}>
      {children}
    </DropDownContext.Provider>
  );
};

export const useDropDown = () => useContext(DropDownContext);
