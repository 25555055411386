import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMobile } from './MobileContext';

import { ReactComponent as Logo } from './images/logo.svg';

const Footer = () => {
  const navigate = useNavigate();
  const { isMobile } = useMobile();

  return (
    <div style={{background: `linear-gradient(-20deg, #0d2735 0%, black 40%, black 60%, #0d2735 100%)`,
                 width: '100%',
                 display: 'flex',
                 flexDirection: isMobile ? 'column' : 'row',
                }}>
      <div style={{padding: isMobile ? '20px 20px' : '40px 60px',
                   display: 'flex',
                   flexDirection: 'column',
                   gap: '10px',
                   flex: 1,
                   justifyContent: 'space-between',
                  }}>
        <Logo className='image'
              style={{width: '150px',
                      height: 'auto',
                     }}
              onClick={() => navigate('/')} />

        {!isMobile && (
          <span style={{color: 'gray',
                        fontSize: '14px',
                       }}>
            © Polygraph 2024
          </span>
        )}
      </div>

      <div style={{padding: isMobile ? '20px 20px' : '40px 60px',
                   display: 'flex',
                   flexDirection: 'column',
                   gap: '10px',
                  }}>
        <span style={{fontWeight: '500',
                      fontSize: '20px',
                     }}>
          COMPANY
        </span>

        <span>About</span>
        <span>Contacts</span>
        <span>Career</span>
        <span>Privacy Policy</span>
        <span>Terms & Conditions</span>
      </div>

      <div style={{padding: isMobile ? '20px 20px' : '40px 60px',
                   display: 'flex',
                   flexDirection: 'column',
                   gap: '10px',
                  }}>
        <span style={{fontWeight: '500',
                      fontSize: '20px',
                     }}>
          PRODUCT
        </span>

        <span>True Traffic ®</span>
        <span>Detection</span>
      </div>

      <div style={{padding: isMobile ? '20px 20px' : '40px 60px',
                   display: 'flex',
                   flexDirection: 'column',
                   gap: '10px',
                  }}>
        <span style={{fontWeight: '500',
                      fontSize: '20px',
                     }}>
          ARTICLES
        </span>
        <span>What is click fraud?</span>
        <span>How to detect click fraud?</span>
        <span>How to prevent click fraud?</span>
        <span>More articles</span>
      </div>
    </div>
  );
}

export default Footer;
