import React, { useState, useEffect, useRef } from 'react';
import { useMobile } from '../MobileContext';
import { tiers } from '../tiers';

import { ReactComponent as TriangleImage } from '../images/triangle.svg';
import { ReactComponent as ThumbImage } from '../images/thumb.svg';

const Slider = ({currentCap, setTier}) => {
  const { isMobile } = useMobile();
  const [value, setValue] = useState(0);
  const sliderRef = useRef(null);
  const bubbleRef = useRef(null);

  const [bubblePosition, setBubblePosition] = useState(0);
  const [text, setText] = useState('10,000 clicks per month');

  useEffect(() => {
    let tier = tiers.findIndex(tier => Math.round(tier.maxTraffic * tier.price) === currentCap)
    let value = tier * 100 / tiers.length;
    setValue(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setBubble = (value) => {
    const rect = sliderRef.current.getBoundingClientRect();

    let tier = Math.trunc(value * tiers.length / 100);
    if (tier === tiers.length) {
      tier = tiers.length - 1;
    }

    let milestone = tiers[tier]

    setTier(tier);

    let clicks = milestone.maxTraffic;

    setText(`${Number(clicks).toLocaleString('en-US')} clicks per month`);

    const bubbleRect = bubbleRef.current.getBoundingClientRect();

    let bubblePosition = rect.width * (value / 100) - bubbleRect.width / 2

    let offset = isMobile ? 10 : 20;
    if (bubblePosition < -offset) {
      bubblePosition = -offset;
    } else if (bubblePosition > rect.width - bubbleRect.width + offset) {
      bubblePosition = rect.width - bubbleRect.width + offset;
    }

    setBubblePosition(bubblePosition);
  }

  useEffect(() => {
    setBubble(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleMove = (e) => {
    const rect = sliderRef.current.getBoundingClientRect();
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;

    const newValue = Math.min(
      Math.max(((clientX - rect.left) / rect.width) * 100, 0),
      100
    );

    setValue(newValue);
    setBubble(newValue);
  };

  const handleEnd = () => {
    window.removeEventListener('mousemove', handleMove);
    window.removeEventListener('mouseup', handleEnd);
    window.removeEventListener('touchmove', handleMove);
    window.removeEventListener('touchend', handleEnd);
  };

  const handleStart = () => {
    window.addEventListener('mousemove', handleMove);
    window.addEventListener('mouseup', handleEnd);
    window.addEventListener('touchmove', handleMove);
    window.addEventListener('touchend', handleEnd);
  };

  return (
    <div ref={sliderRef}
         style={{height : '60px',
                }}>
      <div style={{backgroundColor: 'white',
                   alignItems: 'center',
                   position: 'relative',
                  }}>

        <div style={{display: 'flex',
                     flexDirection: 'row',
                    }}>

          <div style={{backgroundColor: '#009de6',
                       width: `${value}%`,
                       height: '8px',
                      }}>
          </div>

          <div style={{backgroundColor: 'lightgray',
                       width: `${100 - value}%`,
                       height: '8px',
                      }}>
          </div>
        </div>

        <TriangleImage className='triangle'
                       style={{width: '30px',
                               height: '30px',
                               position: 'absolute',
                               top: '150%',
                               left: `${value}%`,
                               transform: 'translate(-50%, 0)',
                              }}/>

        <ThumbImage className='thumb'
                    style={{width: '25px',
                            height: '25px',
                            position: 'absolute',
                            left: `${value}%`,
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                           }}
                    onMouseDown={handleStart}
                    onTouchStart={handleStart} />

        <div className='bubble'
             ref={bubbleRef}
             style={{width: 'fit-content',
                     whiteSpace: 'nowrap',
                     borderRadius: '5px',
                     padding: '10px',
                     position: 'absolute',
                     top: '340%',
                     left: `${bubblePosition}px`,
                    }}>
          {text}
        </div>
      </div>
    </div>
  );
}

export default Slider;
