import React from 'react';
import PercentageBox from '../PercentageBox';

import { ReactComponent as ClickIcon } from '../images/click.svg';
import { ReactComponent as BotIcon } from '../images/bot.svg';
import { ReactComponent as TotalWave } from '../images/total-wave.svg';
import { ReactComponent as BotWave } from '../images/bot-wave.svg';

const TrafficCard = ({title, numClicks, clickPercentage, percentage, icon}) => {
  let iconElement = null;
  switch (icon) {
  case 'click':
    iconElement = <ClickIcon style={{fill: '#009de6',
                                     width: '35px',
                                     height: '35px',
                                    }} />;
    break;
  case 'bot':
    iconElement = <BotIcon style={{fill: '#009de6',
                                   width: '35px',
                                   height: '35px',
                                  }} />;
    break;
  default:
    break;
  }

  return (
    <div
      style={{
        borderRadius: '10px',
        height: '150px',
        padding: '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 1,
        width: 'calc(100% - 60px)',
        position: 'relative',
      }}>

      {icon === 'bot' && (
        <BotWave style={{width: '100%',
                         height: '100%',
                         position: 'absolute',
                        }} />
      )}

      {icon === 'click' && (
        <TotalWave style={{width: '100%',
                           height: '100%',
                           position: 'absolute',
                          }} />
      )}

      <span style={{fontSize: '15px', fontWeight: 'bold'}}>
        {title}
      </span>

      <div style={{
             display: 'flex',
             flexDirection: 'row',
             alignItems: 'center',
             gap: '5px',
             fontWeight: 'bold',
           }}>
        {iconElement}
        <span style={{fontSize: '30px', }}>
          {numClicks.toLocaleString()}
        </span>
        {clickPercentage !== undefined && (
          <>
            <span style={{fontSize: '30px',
                          fontWeight: '100',
                         }}>
              |
            </span>
            <span style={{fontSize: '30px', }}>
              {clickPercentage.toFixed(2)}%
            </span>
          </>
        )}

        <PercentageBox percentage={percentage} />
      </div>

      <span style={{fontSize: '15px'}}>
        vs last month
      </span>
    </div>
  );
}

export default TrafficCard;
