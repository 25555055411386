import React from 'react';

const TimeSelector = ({ times, selected, setSelected }) => {

  return (
    <div className="time-selector"
         style={{
           display: 'flex',
           overflow: 'hidden',
         }}>
      {times.map((time, index) => (
        <div className={`${selected === time ? 'time-selected' : ''}`}
             key={index}
             onClick={() => setSelected(time)}
             style={{
               width: '30px',
               padding: '5px 10px',
               cursor: 'pointer',
               fontWeight: selected === time ? 'bold' : 'normal',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               transition: 'background-color 0.2s, color 0.2s'
             }}>
          {time}
        </div>
      ))}
    </div>
  );
};

export default TimeSelector;
