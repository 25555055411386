import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Input from './Input';
import PasswordInput from './PasswordInput';
import ImageCarousel from './ImageCarousel';
import CheckBox from '../CheckBox';
import VerifyModal from './VerifyModal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { ReactComponent as Spinner } from '../images/spinner2.svg';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useMobile } from '../MobileContext';
import { tiers } from '../tiers';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const SignUpForm = () => {
  const { isMobile } = useMobile();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [costCap, setCostCap] = useState(450);
  const [company, setCompany] = useState('');
  const [telephone, setTelephone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [accept, setAccept] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = React.useState(false);

  const validEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  function getEmailWarning (email) {
    if (!email || email.trim().length === 0) {
      return 'Email is required';
    }

    if (!validEmailRegex.test(email) ||
        email.includes('..')) {
      return 'Invalid email';
    }

    const [localPart, domain] = email.split('@');

    if (!localPart || !domain ||
        localPart.startsWith('.') ||
        localPart.endsWith('.') ||
        !domain.includes('.') ||
        domain.startsWith('.') ||
        domain.endsWith('.')) {
      return 'Invalid email';
    }

    const domainParts = domain.split('.');
    const tld = domainParts[domainParts.length - 1];
    if (tld.length < 2) {
      return 'Invalid email';
    }

    return '';
  }

  const validNameRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ' -]{2,50}$/;

  function getNameWarning (name) {
    if (!name || name.trim().length === 0) {
      return 'Name is required';
    }

    name = name.trim();

    if (name.length < 2) {
      return 'Name is too short';
    }

    if (name.length > 50) {
      return 'Name is too long';
    }

    if (validNameRegex.test(name)) {
      return '';
    }
    else {
      return 'Invalid name';
    }
  }

  function getPhoneWarning (phoneNumber) {
    if (!phoneNumber || phoneNumber.trim().length === 0) {
      return 'Phone number is required';
    }

    const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');

    if (!parsedNumber) {
      return 'Invalid phone number format';
    }

    if (!parsedNumber.isValid()) {
      return 'Invalid phone number';
    }

    return '';
  }

  function getPasswordWarning (password, target) {
    if (password.length < 8) {
      return 'Password is less than 8 characters';
    }
    else if (!password.match(/[A-Z]/)) {
      return 'Password does not contain an uppercase letter';
    }
    else if (!password.match(/[0-9]/)) {
      return 'Password does not contain a number';
    }
    else if (target && password !== target) {
      return 'Passwords do not match';
    }
    else {
      return '';
    }
  }

  const create = async () => {
    if (getEmailWarning(email)) {
      alert('Please enter a valid email address');
      return;
    }
    if (getNameWarning(name)) {
      alert('Please use a valid name');
      return;
    }
    if (getNameWarning(company)) {
      alert('Please enter a valid company name');
      return;
    }
    if (getPhoneWarning(telephone)) {
      alert('Please enter a valid phone number');
      return;
    }
    if (getPasswordWarning(password, null)) {
      alert('Please enter a valid password');
      return;
    }
    if (getPasswordWarning(confirmPassword, password)) {
      alert('Please confirm your password correctly');
      return;
    }
    if (!accept) {
      alert('Please accept the terms and conditions');
      return;
    }

    setProcessing(true);
    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name
      },
    });

    if (error) {
      alert('Please enter a valid card');
      return;
    }

    fetch(process.env.REACT_APP_SERVER_URL + '/api/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, name, costCap, company,
                             telephone, password,
                             paymentMethodId: paymentMethod.id })})
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setProcessing(false);
          setModalVisible(true);
        }
      });
  }

  return (
    <div style={{display: 'flex',
                 flexDirection: 'column',
                 backgroundColor: 'white',
                 height: '100%',
                }}>

      {modalVisible && (
        <VerifyModal setVisible={setModalVisible}/>
      )}

      <div style={{flex: 1,
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'space-around',
                   color: 'black',
                   padding: isMobile ? '20px' : '80px',
                   gap: '50px',
                  }}>

        {!modalVisible && (
          <form style={{width: isMobile ? '100%' : '30%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                       }}
                onSubmit={(e) => {
                  e.preventDefault();
                  create();
                }}>
            <div>
              <h1>Create an account</h1>
              <span style={{color: 'gray'}}>
                You are signing up to Polygraph's click fraud detection, blocking and analytics service for 25,000 ad clicks per month at USD $450 per month. You can cancel your subscription at any time.
              </span>
            </div>

            <div>
              <span>Email</span>
              <Input placeholder='Enter you email'
                     getWarning={getEmailWarning}
                     type='email'
                     onChange={(e) => {
                       setEmail(e.target.value);
                     }}/>
            </div>

            <div>
              <span>Full name</span>
              <Input placeholder='Enter your full name'
                     getWarning={getNameWarning}
                     onChange={(e) => {
                       setName(e.target.value);
                     }}/>
            </div>

            <div style={{display: 'flex',
                         gap: '10px',
                         flexDirection: 'column',
                        }}>
              <span>Monthly cost cap</span>

              <select style={{padding: '15px',
                              backgroundColor: 'transparent',
                              border: '1px solid #d3d3d3',
                              borderRadius: '5px',
                              fontSize: '16px',
                             }}
                      onChange={(e) => {
                        setCostCap(parseFloat(e.target.value));
                      }}>
                {tiers.map((tier) => {
                  let price = Math.round(tier.maxTraffic * tier.price);
                  return <option value={price}>${price}</option>
                })}
              </select>

              <span style={{color: 'gray'}}>
                * Minimum charge is $450
              </span>
            </div>

            <div style={{display: 'flex',
                         gap: '10px',
                         flexDirection: 'column',
                        }}>
              <span>Payment details</span>

              <div
                style={{ width: '100%',
                         padding: '15px',
                         boxSizing: 'border-box',
                         borderRadius: '5px',
                         border: '1px solid #d3d3d3',
                       }}>
                <CardElement options={{
                               style: {
                                 base: {
                                   fontSize: '16px',
                                   color: 'black',
                                   '::placeholder': {
                                     color: 'gray',
                                   },
                                 }}}} />
              </div>
            </div>

            <div>
              <span>Company name</span>
              <Input placeholder='Enter your company name'
                     getWarning={getNameWarning}
                     onChange={(e) => {
                       setCompany(e.target.value);
                     }}/>
            </div>

            <div>
              <span>Telephone</span>
              <Input placeholder='Enter your phone number'
                     getWarning={getPhoneWarning}
                     onChange={(e) => {
                       setTelephone(e.target.value);
                     }}/>
            </div>

            <div>
              <span>New Password</span>
              <PasswordInput placeholder='Enter new password'
                             showWarning={true}
                             onChange={(e) => {
                               setPassword(e.target.value);
                             }}/>
            </div>

            <div>
              <span>Confirm Password</span>
              <PasswordInput placeholder='Confirm new password'
                             showWarning={true}
                             target={password}
                             onChange={(e) => {
                               setConfirmPassword(e.target.value);
                             }}/>
            </div>

            <div style={{display: 'flex',
                         gap: '10px',
                         alignItems: 'center',
                        }}>
              <CheckBox
                checked={accept}
                onChange={(e) => {
                  setAccept(e.target.checked);
                }}/>

              <span style={{color: 'gray'}}>
                I accept the Terms & Conditions and Privacy Policy.
              </span>
            </div>

            <div style={{display: 'flex',
                         width: '100%',
                         justifyContent: 'center',
                        }}>
              <button style={{width: '300px',
                              padding: '15px',
                              backgroundColor: '#008fe9',
                              color: 'white',
                              border: 'none',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              fontSize: '16px',
                              fontWeight: 'bold',
                              fontFamily: 'inherit',
                             }}>
                {processing && (
                  <Spinner style={{
                             width: '30px',
                             height: '30px',
                             animation: 'spin 1s linear infinite',
                           }} />
                )}
                {!processing && 'Create'}
              </button>
            </div>

            <div style={{display: 'flex',
                         justifyContent: 'center',
                         gap: '5px',
                         alignItems: 'center',
                         color: 'black'
                        }}>
              <style>
                {`.custom-link:link {
                 color: #008fe9;
               }
               .custom-link:visited {
                 color: #008fe9;
               }
               .custom-link:hover {
                 color: #0056b3;
               }
               .custom-link:active {
                 color: #0056b3;
               }
               .custom-link {
                 text-decoration: underline;
                 text-underline-offset: 5px;
               }
             `}
              </style>
              Already have an account? <Link className='custom-link'
                                             to='/'>Log in</Link>
            </div>

          </form>
        )}

        {!isMobile && <ImageCarousel set2={true}/>}
      </div>
    </div>
  );
}

const SignUp = () => {
  return (
    <Elements stripe={stripePromise}>
      <SignUpForm/>
    </Elements>
  );
}
export default SignUp;
