import React, { useState, useEffect } from 'react';
import * as utils from '../../utils';
import ReactECharts from 'echarts-for-react';
import { useTheme } from '../../ThemeContext';
import WhenStartedModal from './WhenStartedModal';
import { useMobile } from '../../MobileContext';

import { ReactComponent as InfoIcon } from '../../images/info.svg';

const getOption = (isDarkMode, isMobile, site) => {
  let {bots, human, lastDay} = site.lifetimeCounts;
  let data = [];

  for (let i = 0; i < human.length; i++) {
    let total = human[i] + bots[i];

    if (total === 0) {
      data.push(0);
    }
    else {
      data.push(bots[i] / total);
    }
  }

  return {
    title: {
      show: false,
    },
    tooltip: {
      backgroundColor: isDarkMode ? '#21242e' : 'lightgray',
      borderWidth: 0,
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
      textStyle: {
        color: isDarkMode ? 'white' : 'black',
        fontSize: 15,
      },
      formatter: function (params) {
        let tooltipContent = '';

        const date = new Date(params[0].name + " 2024");
        const month = date.toLocaleString('default', { month: 'long' });
        const day = date.getDate();
        tooltipContent += `${month} ${day} <br/>`;

        params.forEach((item) => {
          const color = 'rgba(255, 77, 77, 1)';

          const value = item.value.toFixed(2);

          tooltipContent += `
          <span style="display:inline-block;width:10px;
           height:10px;background-color:${color};margin-right:5px;"></span>
          Fraud: ${value}<br/>
        `;
        });

        return tooltipContent;
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: utils.getFormattedDateArray(lastDay, data.length),
      axisLine: {
        lineStyle: {
          color: '#aaa',
        },
      },
      axisLabel: {
        color: isDarkMode ? 'white' : 'black',
        interval: isMobile ? 30 : 10,
      },
    },
    yAxis: {
      type: 'value',
      name: 'Fraud Level',
      nameTextStyle: {
        color: isDarkMode ? 'white' : 'black',
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        color: isDarkMode ? 'white' : 'black',
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: isDarkMode ? '#333' : '#ddd',
        },
      },
    },
    grid: {
      left: '1%',
      right: '2%',
      top: '15%',
      bottom: '10%',
      containLabel: true,
    },
    series: [
      {
        name: 'Fraud Level',
        type: 'line',
        data: data,
        smooth: true,
        showSymbol: false,
        symbolSize: 10,
        emphasis: {
          itemStyle: {
            color: '#009de6',
            borderColor: 'transparent',
            borderWidth: 0,
            symbolSize: 10,
          },
        },
        lineStyle: {
          width: 0,
        },
        areaStyle: {
          color: '#009de6',
        },
      },
    ],
  }
}

const WhenStartedGraph = ({site}) => {
  const { isDarkMode } = useTheme();
  const { isMobile } = useMobile();
  const [chartOption, setChartOption] = useState(getOption(isDarkMode, isMobile, site));
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    setChartOption(getOption(isDarkMode, isMobile, site));
  }, [isDarkMode, site, isMobile]);

  return (
    <>
      {modalVisible && <WhenStartedModal setVisible={setModalVisible} />}
      <div style={{height: '100%',
                   flexGrow: 1,
                   position: 'relative',
                  }}>
        <div style={{display: 'flex',
                     flexDirection: 'row',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                     padding: '10px',
                    }}>
          <span>THIS IS WHERE YOU STARTED</span>
          <InfoIcon className='image'
                    style={{width: '20px',
                            height: '20px',
                           }}
                    onClick={() => setModalVisible(true)} />
        </div>
        <ReactECharts option={chartOption}
                      notMerge={true}
                      style={{ height: '100%',
                               width: '100%',
                             }} />
      </div>
    </>
  );
}

export default WhenStartedGraph;
