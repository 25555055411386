import React from 'react';
import LineGraph from './LineGraph';
import BarGraph from './BarGraph';
import PieGraph from './PieGraph';
import { useTheme } from '../../ThemeContext'
import { useMobile } from '../../MobileContext';
import Modal from '../../Modal';

import { ReactComponent as ExpandIcon } from '../../images/expand.svg';
import { ReactComponent as MinimizeIcon } from '../../images/minimize.svg';
import { ReactComponent as LineIcon } from '../../images/graph.svg';
import { ReactComponent as BarIcon } from '../../images/bar.svg';
import { ReactComponent as PieIcon } from '../../images/pie.svg';

const TrafficGraph = ({specs}) => {
  const { isMobile } = useMobile();
  let { isDarkMode } = useTheme();

  const [expanded, setExpanded] = React.useState(false);
  const [which, setWhich] = React.useState('line');

  const icon = expanded ?
    <MinimizeIcon className="image"
                  style={{width: '20px',
                          height: '20px',
                         }}
                  onClick={() => setExpanded(false)} />
        :
  <ExpandIcon className="image"
              style={{width: '20px',
                      height: '20px',
                     }}
              onClick={() => setExpanded(true)} />;

  const graph = (
    <div style={{width: '100%',
                 height: '100%',
                 display: 'flex',
                 flexDirection: 'column',
                }}>
      <div style={{display: 'flex',
                   flexDirection: 'row',
                   alignItems: 'center',
                   justifyContent: 'space-between',
                   padding: '10px',
                  }}>
        <span>{specs.title}</span>
        {icon}
      </div>
      <div style={{display: 'flex',
                   flexDirection: isMobile ? 'column' : 'row',
                   boxSizing: 'border-box',
                   height: '100%',
                  }}>
        <div style={{display: 'flex',
                     flexDirection: isMobile ? 'row' : 'column',
                     alignItems: 'center',
                     justifyContent: 'center',
                     gap: '30px',
                     margin: '20px',
                    }}>
          <LineIcon style={{width: '20px',
                            height: '20px',
                            fill: which === 'line' ? '#009de6' : '#868686',
                            cursor: 'pointer',
                           }}
                    onClick={() => setWhich('line')} />
          <BarIcon style={{width: '20px',
                           height: '20px',
                           fill: which === 'bar' ? '#009de6' : '#868686',
                           cursor: 'pointer',
                          }}
                   onClick={() => setWhich('bar')} />
          <PieIcon style={{width: '20px',
                           height: '20px',
                           fill: which === 'pie' ? '#009de6' : '#868686',
                           cursor: 'pointer',
                          }}
                   onClick={() => setWhich('pie')} />
        </div>
        <div style={{width: '100%',
                     height: '100%',
                    }}>
          {which === 'line' && (
            <div style={{height: expanded ? '100%' : '400px'}}>
              <LineGraph specs={specs} />
            </div>
          )}
          {which === 'bar' && (
            <div style={{height: expanded ? '100%' : '400px'}}>
              <BarGraph specs={specs} />
            </div>
          )}
          {which === 'pie' && (
            <PieGraph specs={specs} />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {expanded && (
        <Modal isOpen={expanded}>
          <div style={{width: isMobile ? '100vw' : '80vw',
                       height: isMobile ? '100vh' : '80vh',
                       overflowY: 'auto',
                       backgroundColor: isDarkMode ? 'black' : 'white',
                       display: 'flex',
                       flexDirection: 'column',
                       justifyContent: 'space-between',
                      }}>
            {graph}
          </div>
        </Modal>
      )}

      {!expanded && graph}
    </>
  );
}

export default TrafficGraph;
