import React from 'react';

import { ReactComponent as UserIcon } from './images/user.svg';
import { ReactComponent as SettingsIcon } from './images/settings.svg';

const AccountButtons = ({setPage, selected}) => {
  return (
    <div style={{display: 'flex',
                 gap: '15px',
                }}>
      <UserIcon
        className={`image ${selected === 'profile' ? 'selected-box' : ''}`}
        style={{width: '20px',
                height: '20px',
                padding: '5px',
                borderRadius: '4px',
               }}
        onClick={() => setPage('profile')} />
      <SettingsIcon
        className={`image ${selected === 'settings' ? 'selected-box' : ''}`}
        style={{width: '20px',
                height: '20px',
                padding: '5px',
                borderRadius: '4px',
               }}
        onClick={() => setPage('settings')} />
    </div>
  );
};

export default AccountButtons;
