import React, { createContext, useContext, useState, useEffect } from "react";

const MobileContext = createContext();

function isMobileNavigator() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 768;
}

export function MobileProvider({ children }) {
  const [isMobile, setIsMobile] = useState(isMobileNavigator());

  useEffect(() => {
    const handleResize = () => setIsMobile(isMobileNavigator());
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <MobileContext.Provider value={{ isMobile }}>
      {children}
    </MobileContext.Provider>
  );
}

export function useMobile() {
  return useContext(MobileContext);
}
