import React, { useState } from 'react';
import Separator from '../Separator';
import { useMobile } from '../MobileContext';

import { ReactComponent as CheckIcon } from '../images/check.svg';
import { ReactComponent as InfoIcon } from '../images/info.svg';

const Item = ({text}) => {
  return (
    <div style={{display: 'flex',
                 alignItems: 'center',
                 gap: '10px',
                }}>
      <CheckIcon style={{width: '25px',
                         height: '25px',
                         flexShrink: 0,
                        }} />
      {text}
    </div>
  );
}

const PlanCard2 = ({ setSubscriptionsModalVisible, amount, cap }) => {
  const { isMobile } = useMobile();
  const [popupVisible, setPopupVisible] = useState(false);

  return (
    <div className='border-gradient'
         style={{
           borderRadius: '10px',
         }}>
      <div className='gradient'
           style={{
             borderRadius: '10px',
             ...(!isMobile && { padding: '10px' }),
             display: 'flex',
             flexDirection: isMobile ? 'column' : 'row',
             gap: '10px',
             justifyContent: 'space-between',
             margin: '1px',
           }}>
        <div style={{
               width: isMobile ? '100%' : '50%',
               padding: '20px',
               display: 'flex',
               flexDirection: 'column',
               justifyContent: 'space-between',
               boxSizing: 'border-box',
             }}>

          {amount && (
            <span style={{ fontWeight: 'bold', fontSize: '28px' }}>
              ${amount}
            </span>
          )}

          <div style={{position: 'relative'}}>
            <div className='cap-box'
                 style={{
                   width: '100%',
                   borderRadius: '5px',
                   padding: '10px',
                   display: 'flex',
                   flexDirection: 'column',
                   gap: '5px',
                   position: 'relative',
                 }}>
              <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                MONTHLY CAP
              </span>
              <span>${cap}</span>
              <InfoIcon style={{
                          width: '20px',
                          height: '20px',
                          fill: 'gray',
                          position: 'absolute',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          right: '10px',
                          cursor: 'pointer',
                        }}
                        onMouseEnter={() => setPopupVisible(true)}
                        onMouseLeave={() => setPopupVisible(false)}
              />
            </div>
            {popupVisible && (
              <span style={{position: 'absolute',
                            top: '100%',
                            left: '0',
                            fontSize: '12px',
                            color: '#666',
                            width: '100%',
                            textAlign: 'left',
                            padding: '5px',
                            backgroundColor: '#f9f9f9',
                            borderRadius: '5px',
                            zIndex: '100',
                           }}>
                Some information
              </span>
            )}
          </div>
        </div>

        <div style={{
               display: 'flex',
               alignItems: 'stretch',
             }}>
          <Separator vertical={!isMobile} />
        </div>

        {!isMobile && (
          <>
            <div style={{
                   width: '50%',
                   padding: '20px',
                   display: 'flex',
                   flexDirection: 'column',
                   justifyContent: 'center',
                 }}>
              <div style={{
                     display: 'flex',
                     flexDirection: 'column',
                     gap: '15px',
                   }}>
                <Item text="25,000 ad clicks per month" />
                <Item text="Apply for click fraud refunds" />
                <Item text="Prevent click fraud" />
              </div>
            </div>

            <div style={{
                   width: '50%',
                   padding: '20px',
                   display: 'flex',
                   flexDirection: 'column',
                   justifyContent: 'center',
                 }}>
              <div style={{
                     display: 'flex',
                     flexDirection: 'column',
                     gap: '15px',
                   }}>
                <Item text="Monitor unlimited websites" />
                <Item text="Detect click fraud" />
                <Item text="Dedicated account manager" />
              </div>
            </div>
          </>
        )}

        {isMobile && (
          <div style={{
                 width: '100%',
                 padding: '20px',
                 display: 'flex',
                 flexDirection: 'column',
                 justifyContent: 'center',
                 boxSizing: 'border-box',
               }}>
            <div style={{
                   display: 'flex',
                   flexDirection: 'column',
                   gap: '15px',
                   width: '100%',
                 }}>
              <Item text="25,000 ad clicks per month" />
              <Item text="Apply for click fraud refunds" />
              <Item text="Prevent click fraud" />
              <Item text="Monitor unlimited websites" />
              <Item text="Detect click fraud" />
              <Item text="Dedicated account manager" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanCard2;
