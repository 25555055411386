import React, { useEffect } from 'react';
import PlanCard2 from './PlanCard2';
import Slider from './Slider';
import { useMobile } from '../MobileContext';
import Modal from '../Modal';
import * as utils from '../utils';
import { tiers } from '../tiers';
import { useSites } from '../SitesContext';

import { ReactComponent as CloseIcon } from '../images/x.svg';
import { ReactComponent as Spinner } from '../images/spinner2.svg';

const SubscriptionsModal = ({setSubscriptionsModalVisible, subscription, incrementSubscriptionVersion}) => {
  const { isMobile } = useMobile();
  const [processing, setProcessing] = React.useState(false);
  const [tier, setTier] = React.useState(0);
  const [amountDue, setAmountDue] = React.useState(0);

  const handleUpdate = () => {
    setProcessing(true);

    let token = localStorage.getItem('token');
    let cap = Math.round(tiers[tier].maxTraffic * tiers[tier].price);

    if (!subscription || cap !== subscription.cap) {
      fetch(process.env.REACT_APP_SERVER_URL + '/api/change-subscription', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                   'Authorization': token
                 },
        body: JSON.stringify({ cap })
      })
        .then(res => res.json())
        .then(data => {

          if (!data.success) {
            alert('Could not set subscription');
            return;
          }
          else {
            incrementSubscriptionVersion();
          }
          setProcessing(false);
          setSubscriptionsModalVisible(false);
        });
    }
  };

  const getCap = (tier) => {
    let { maxTraffic, price } = tiers[tier]

    return Math.round(maxTraffic * price)
  }

  const { getTotalSince } = useSites();

  useEffect (() => {
    if (!subscription) return

    let totalClicks = getTotalSince(new Date(subscription.created_at))
    setAmountDue(utils.getPrice(totalClicks))
  }, [subscription, getTotalSince])

  return (
    <Modal isOpen={true}>
      <div style={{
             background: isMobile ? 'black' : 'linear-gradient(gray, transparent)',
             zIndex: '101',
             position: 'absolute',
             transform: 'translate(-50%, -50%)',
             top: '50%',
             left: '50%',
             width: isMobile ? '100%' : '1000px',
             borderRadius: '10px',
             maxHeight: '100vh',
             overflowY: 'auto',
           }}>
        <div className='modal'
             style={{
               margin: '1px',
               borderRadius: '10px',
               zIndex: '101',
               display: 'flex',
               justifyContent: 'center',
               flexDirection: 'column',
               padding: isMobile ? '10px' : '20px',
               gap: '20px',
               overflowX: 'hidden',
             }}>
          <div style={{
                 display: 'flex',
                 flexDirection: 'row',
                 alignItems: 'center',
                 justifyContent: 'space-between',
                 width: '100%',
               }}>
            <h2>Subscriptions</h2>
            <CloseIcon className='image'
                       style={{width: '20px',
                               height: '20px',
                              }}
                       onClick={() => {
                         setSubscriptionsModalVisible(false);
                       }}/>
          </div>
          {subscription.cap && <PlanCard2 amount={amountDue} cap={subscription.cap} />}

          <h3>Available plans</h3>
          <Slider currentCap={subscription.cap || 450} setTier={setTier}/>

          <PlanCard2 amount={null} cap={getCap(tier)}/>

          <span style={{fontSize: '14px'}}>
            Please contact team@polygraph.net for subscription details.
          </span>

          <div style={{display: 'flex',
                       flexDirection: 'row',
                       alignItems: 'center',
                       gap: '10px',
                       justifyContent: 'center',
                       height: '40px',
                      }}>
            <div style={{border: '1px solid #e0e0e0',
                         borderRadius: '5px',
                         cursor: 'pointer',
                         fontWeight: 'bold',
                         alignSelf: 'flex-end',
                         height: '100%',
                         boxSizing: 'border-box',
                         display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'center',
                         padding: '0 20px',
                         ...(isMobile ? { flexGrow: 1 } : { width: 'fit-content' }),
                        }}
                 onClick={() => {
                   setSubscriptionsModalVisible(false);
                 }}>
              Cancel
            </div>
            <div style={{backgroundColor: '#009de6',
                         color: 'white',
                         borderRadius: '5px',
                         cursor: 'pointer',
                         fontWeight: 'bold',
                         alignSelf: 'flex-end',
                         height: '100%',
                         boxSizing: 'border-box',
                         display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'center',
                         padding: '0 20px',
                         ...(isMobile ? { flexGrow: 1 } : { width: 'fit-content' }),
                        }}
                 onClick={handleUpdate}>
              <div style={{
                     textAlign: 'center',
                     color: 'white',
                   }}>
                {processing && (
                  <Spinner style={{
                             margin: '0 auto',
                             width: '60px',
                             height: '30px',
                             animation: 'spin 1s linear infinite',
                           }} />
                )}
                {!processing && 'Update'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SubscriptionsModal;
