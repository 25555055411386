import React, { useRef, useEffect } from 'react';
import { useTheme } from './ThemeContext';

import { ReactComponent as Radio0Dark } from './images/radio0.svg';
import { ReactComponent as Radio1Dark } from './images/radio1.svg';
import { ReactComponent as Radio2Dark } from './images/radio2.svg';
import { ReactComponent as Radio0Light } from './images/radio0-light.svg';
import { ReactComponent as Radio1Light } from './images/radio1-light.svg';
import { ReactComponent as Radio2Light } from './images/radio2-light.svg';
import { ReactComponent as TriangleImage } from './images/triangle.svg';

const TrafficButtons = ({gap, direction, site}) => {
  const ref = useRef(null);
  let [selected, setSelected] = React.useState(2);
  let { isDarkMode } = useTheme();
  let [popupVisible, setPopupVisible] = React.useState(false);

  const handleClick = (e) => {
    const clickX = e.clientX - ref.current.getBoundingClientRect().left;
    let level;

    if (clickX < 40) {
      setSelected(0);
      level = 'disabled';
    } else if (clickX < 70) {
      setSelected(1);
      level = 'detection_only';
    } else {
      setSelected(2);
      level = 'true_traffic';
    }

    let token = localStorage.getItem('token');

    if (token) {
      fetch(process.env.REACT_APP_SERVER_URL + '/api/set-detection-level', {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({website: site.website, level})
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            console.log('detection level set')
          }
        })
    }
  };

  let Radio0 = isDarkMode ? Radio0Dark : Radio0Light;
  let Radio1 = isDarkMode ? Radio1Dark : Radio1Light;
  let Radio2 = isDarkMode ? Radio2Dark : Radio2Light;

  useEffect(() => {
    if (site.detectionLevel === 'disabled') {
      setSelected(0);
    } else if (site.detectionLevel === 'detection_only') {
      setSelected(1);
    } else if (site.detectionLevel === 'true_traffic') {
      setSelected(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{display: 'flex',
                 flexDirection: direction !== undefined ? direction : 'column',
                 gap: gap !== undefined ? gap : '0px',
                 alignItems: 'center',
                 justifyContent: 'center',
                 position: 'relative',
                }}
         onMouseEnter={() => setPopupVisible(true)}
         onMouseLeave={() => setPopupVisible(false)}>
      <div ref={ref}
           style={{
             display: 'flex',
             flexDirection: 'row',
             cursor: 'pointer',
           }}
           onClick={handleClick}>
        {selected === 0 && <Radio0 style={{ width: '120px' }} />}
        {selected === 1 && <Radio1 style={{ width: '120px' }} />}
        {selected === 2 && <Radio2 style={{ width: '120px' }} />}
      </div>
      <div style={{display: 'flex',
                   width: '120px',
                   alignItems: 'center',
                   justifyContent: direction === 'row' ? 'flex-start' : 'center',
                  }}>
        {selected === 0 && 'Disabled'}
        {selected === 1 && 'Detection Only'}
        {selected === 2 && 'TrueTraffic'}
      </div>

      {popupVisible && selected === 2 && (
        <div className='bubble'
             style={{position: 'absolute',
                     width: '260px',
                     height: '50px',
                     padding: '10px',
                     top: 'calc(100% + 10px)',
                     left: '50%',
                     transform: 'translate(-50%, 0)',
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                     borderRadius: '5px',
                    }}>
          <TriangleImage className='triangle'
                         style={{width: '30px',
                                 height: '30px',
                                 position: 'absolute',
                                 top: '-15px',
                                 left: '50%',
                                 transform: 'translate(-50%, 0)',
                                }}/>
          <span>
            Bot Blocking, Prevent Spam
          </span>
          <span>
            Leads and Retrains AdNetwork
          </span>
        </div>
      )}
    </div>
  );
}

export default TrafficButtons;
