import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMobile } from './MobileContext';

import { ReactComponent as Logo } from './images/logo.svg';
import { ReactComponent as UserIcon } from './images/user.svg';
import { ReactComponent as ArrowIcon } from './images/rightArrow.svg';
import { ReactComponent as MenuIcon } from './images/menu.svg';
import { ReactComponent as XIcon } from './images/x.svg';

const Header = () => {
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const [menuVisible, setMenuVisible] = React.useState(false);

  return (
    <div style={{backgroundColor: 'black',
                 display: 'flex',
                 flexDirection: 'row',
                 justifyContent: 'space-between',
                 alignItems: 'center',
                 padding: isMobile ? '20px 20px' : '0px 50px',
                }}>
      <Logo className='image'
            style={{width: '150px',
                    height: 'auto',
                   }}
            onClick={() => navigate('/')} />

      {!isMobile && (
        <>
          <div style={{padding: '20px 80px',
                       display: 'flex',
                       gap: '50px',
                       alignItems: 'center',
                      }}>
            <select style={{ padding: '5px',
                             border: 'none',
                             color: 'white',
                             width: '90px',
                             cursor: 'pointer',
                             fontSize: '16px',
                             fontFamily: 'Schibsted Grotesk, sans-serif',
                             backgroundColor: 'transparent' }}
                    onChange={(e) => {
                      e.target.value = 'Product';
                    }}>
              <option hidden>Product</option>
              <option>True Traffic ®</option>
              <option>Detection</option>
            </select>

            <span>Plans</span>
            <span>About</span>
            <span>Articles</span>
            <span>Career</span>
          </div>

          <div style={{padding: '20px 80px',
                       display: 'flex',
                       flexDirection: 'row',
                       gap: '20px',
                      }}>
            <div style={{backgroundColor: 'white',
                         borderRadius: '3px',
                         color: 'black',
                         padding: '10px 20px',
                         whiteSpace: 'nowrap',
                         display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'center',
                         gap: '10px',
                         cursor: 'pointer',
                        }}
                 onClick={() => {
                   console.log('Request Audit');
                 }}>
              <span>Request Audit</span>
              <ArrowIcon className='image'
                         style={{width: '20px',
                                 height: '20px',
                                 stroke: '#009de6',
                                }} />
            </div>

            <div style={{backgroundColor: '#009de6',
                         borderRadius: '3px',
                         color: 'black',
                         whiteSpace: 'nowrap',
                         display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'center',
                         padding: '5px 12px',
                         cursor: 'pointer',
                        }}>
              <UserIcon className='image'
                        style={{width: '20px',
                                height: '20px',
                               }}
                        onClick={() => navigate('/')} />
            </div>
          </div>
        </>
      )}

      {isMobile && (
        <MenuIcon className='image'
                  style={{width: '20px',
                          height: '20px',
                         }}
                  onClick={() => setMenuVisible(true)} />
      )}

      {menuVisible && (
        <div style={{backgroundColor: 'white',
                     padding: '20px',
                     width: '95%',
                     height: '100vh',
                     position: 'absolute',
                     left: '0',
                     top: '0',
                     zIndex: '100',
                     display: 'flex',
                     flexDirection: 'column',
                     gap: '20px',
                     boxSizing: 'border-box',
                     color: 'black',
                    }}>
          <div style={{display: 'flex',
                       flexDirection: 'row',
                       justifyContent: 'space-between',
                       alignItems: 'center',
                      }}>
            <h2>Menu</h2>
            <XIcon className='image'
                   style={{width: '15px',
                           height: '15px',
                           fill: 'black',
                          }}
                   onClick={() => setMenuVisible(false)} />
          </div>

          <span>Product</span>
          <span>Benefits</span>
          <span>About</span>
          <span>Articles</span>
          <span>Protection</span>

          <div style={{display: 'flex',
                       flexDirection: 'row',
                       gap: '20px',
                      }}>
            <div style={{backgroundColor: '#009de6',
                         borderRadius: '3px',
                         width: '80%',
                         color: 'white',
                         padding: '10px 20px',
                         whiteSpace: 'nowrap',
                         display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'center',
                         gap: '10px',
                         cursor: 'pointer',
                        }}
                 onClick={() => {
                   console.log('Request Audit');
                 }}>
              <span>Request Audit</span>
              <ArrowIcon className='image'
                         style={{width: '20px',
                                 height: '20px',
                                 stroke: 'white',
                                }} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
