import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div style={{display: 'flex',
                 flexDirection: 'column',
                 height: '100vh',
                 fontFamily: 'Schibsted Grotesk, sans-serif',
                }}>

      <Header/>

      <main style={{flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#f6f6f6',
                   }}>
        <Outlet/>
      </main>

      <Footer/>
    </div>
  );
};

export default Layout;
