import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import * as utils from '../../utils';
import { useTheme } from '../../ThemeContext'
import { useMobile } from '../../MobileContext';

const getOption = (specs, isDarkMode, isMobile) => {
  const {values, lastDay} = specs;

  const botClicks = values.bot;
  const humanClicks = values.human;
  const totalBotClicks = botClicks.reduce((acc, curr) => acc + curr, 0);
  const totalHumanClicks = humanClicks.reduce((acc, curr) => acc + curr, 0);
  const botPercentage = (totalBotClicks / (totalBotClicks + totalHumanClicks)) * 100;
  const humanPercentage = (totalHumanClicks / (totalBotClicks + totalHumanClicks)) * 100;
  const humanLabel = `Human Traffic: ${totalHumanClicks} | ${humanPercentage.toFixed(2)}%`;
  const fakeLabel = `Bot Traffic: ${totalBotClicks} | ${botPercentage.toFixed(2)}%`
  let interval = botClicks.length > 35 ? 30 : 3;

  return {
    tooltip: {
      backgroundColor: isDarkMode ? '#21242e' : 'lightgray',
      borderWidth: 0,
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
      textStyle: {
        color: isDarkMode ? 'white' : 'black',
        fontSize: 15,
      },
      formatter: function (params) {
        let tooltipContent = '';

        const date = new Date(params[0].name + " 2024");
        const month = date.toLocaleString('default', { month: 'long' });
        const day = date.getDate();
        tooltipContent += `${month} ${day} <br/>`;

        params.forEach((item) => {
          const color = item.color;
          const name = item.seriesName.startsWith('Human') ? 'Human' : 'Bot';

          const value = item.value;
          tooltipContent += `
          <span style="display:inline-block;width:10px;
           height:10px;background-color:${color};margin-right:5px;"></span>
          ${name}: ${value}<br/>
        `;
        });

        return tooltipContent;
      },
    },
    legend: {
      data: [
        {icon: 'square', name: humanLabel},
        {icon: 'square', name: fakeLabel}
      ],
      textStyle: {
        color: isDarkMode ? 'white' : 'black',
        fontWeight: 'bold',
        fontSize: 15,
      },
      itemWidth: 10,
      itemHeight: 10,
      itemGap: 30,
      left: 'left',
      bottom: '0%',
      orient: isMobile ? 'vertical' : 'horizontal',
    },
    xAxis: {
      name: '',
      type: 'category',
      data: utils.getFormattedDateArray(lastDay, botClicks.length),
      axisLine: {
        lineStyle: {
          color: isDarkMode ? '#555' : '#bbb',
        },
      },
      axisLabel: {
        color: isDarkMode ? 'white' : 'black',
        boundaryGap: false,
        interval,
      },
      boundaryGap: false,
      splitLine: {
        show: true,
        lineStyle: {
          color: isDarkMode ? '#333' : '#ddd',
          type: 'dashed',
          width: 2,
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false,
      },
      axisLabel: {
        color: isDarkMode ? 'white' : 'black',
        formatter: (value) => {
          return value >= 1000 ? `${value / 1000}k` : value;
        },
      },
      splitLine: {
        lineStyle: {
          color: isDarkMode ? '#333' : '#ddd',
          type: 'dashed',
          width: 2,
        },
      },
    },
    grid: {
      left: '1%',
      right: '2%',
      top: '10%',
      bottom: isMobile ? '20%' : '10%',
      containLabel: true,
    },
    color: ['#009de6', '#FF5722'],
    series: [
      {
        name: humanLabel,
        symbol: 'circle',
        symbolSize: 10,
        type: 'line',
        data: humanClicks,
        smooth: true,
        showSymbol: false,
        lineStyle: {
          width: isMobile ? 2 : 3,
        },
      },
      {
        name: fakeLabel,
        symbol: 'circle',
        symbolSize: 10,
        type: 'line',
        data: botClicks,
        smooth: true,
        showSymbol: false,
        lineStyle: {
          width: isMobile ? 2 : 3,
        },
      },
    ],
    backgroundColor: 'transparent',
  }
}

const LineGraph = ({specs}) => {
  let { isDarkMode } = useTheme();
  const { isMobile } = useMobile();
  const [chartOption, setChartOption] = useState(getOption(specs, isDarkMode, isMobile));

  useEffect(() => {
    setChartOption(getOption(specs, isDarkMode, isMobile));
  }, [isDarkMode, isMobile, specs]);

  return (
    <ReactECharts option={chartOption}
                  notMerge={true}
                  style={{ height: '100%',
                           width: '100%' }} />
  );
}

export default LineGraph;
