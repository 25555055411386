import React from 'react';
import PercentageBox from '../../PercentageBox';

const Label = ({text, color, y, percentage, showRed, disabled}) => {
  return (
    <div style={{display: 'flex',
                 flexDirection: 'row',
                 alignItems: 'center',
                 justifyContent: 'center',
                 gap: '20px',
                 fontSize: '18px',
                 margin: '10px',
                }}>
      <div style={{width: '10px',
                   height: '10px',
                   backgroundColor: color,
                   borderRadius: '2px',
                  }} />
      <div style={{width: '150px'}}>
        {text}
      </div>

      {!disabled && (
        <div style={{width: '130px'}}>
          <PercentageBox percentage={percentage}
                         red={showRed && percentage > 0} />
        </div>
      )}
    </div>
  );
}

export default Label;
