import React, { useEffect } from 'react';
import { useDropDown } from './DropDownContext';
import { useMobile } from './MobileContext';

const DropDown = ({ id, title, options }) => {
  const { isMobile } = useMobile();
  const { getSelectedOption, setSelectedOption } = useDropDown();

  const handleChange = (e) => {
    setSelectedOption(id, e.target.value);
  };

  useEffect(() => {
    if (!getSelectedOption(id)) {
      setSelectedOption(id, options[0]);
    }
  }, [getSelectedOption, setSelectedOption, id, options]);

  return (
    <div style={{ display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
      <label htmlFor={`dropdown-${id}`}
             style={{ marginRight: '10px',
                      ...isMobile ? { width: '30%' } : { },
                    }}>
        {title}
      </label>
      <select
        id={`dropdown-${id}`}
        className="dropdown"
        name="options"
        style={{ padding: '5px',
                 ...isMobile ? { width: '70%' } : { },
                 border: 'none',
                 fontWeight: 'bold',
                 backgroundColor: 'transparent' }}
        value={getSelectedOption(id) || ''}
        onChange={handleChange}>
        {options.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
};

export default DropDown;
