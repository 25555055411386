import React from 'react';
import { useMobile } from '../MobileContext';
import Modal from '../Modal';

const MessageModal = ({title, message, color}) => {
  const { isMobile } = useMobile();

  return (
    <Modal isOpen={true}>
      <div style={{
             background: `linear-gradient(${color === 'green' ? '#22aa22' : '#ff7777'}, transparent)`,
             zIndex: '101',
             position: 'absolute',
             transform: 'translate(-50%, -50%)',
             top: '50%',
             left: '50%',
             width: isMobile ? '95%' : '400px',
             borderRadius: '10px',
           }}>
        <div className='modal'
             style={{
               margin: '1px',
               borderRadius: '10px',
               zIndex: '101',
               display: 'flex',
               justifyContent: 'center',
               flexDirection: 'column',
               padding: '20px',
               gap: '20px',
               background: `radial-gradient(circle at center top, ${color === 'green' ? '#113311' : '#772222'}, #1b1b1b)`,
             }}>
          <h3>{title}</h3>
          <span>{message}</span>
        </div>
      </div>
    </Modal>
  );
}

export default MessageModal;
