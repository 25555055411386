import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { useTheme } from '../../ThemeContext'
import { useMobile } from '../../MobileContext';

const getOption = (data, isDarkMode, isMobile) => {
  const totalValue = data.reduce((sum, item) => sum + item.value, 0);

  const updatedData = data.map(item => {
    const percentage = ((item.value / totalValue) * 100).toFixed(0).toString().padStart(2, ' ');

    return {
      ...item,
      name: `${percentage}% ${item.name}`,
    };
  });

  let borderColor = isDarkMode ? 'black' : 'white';
  let textColor = isDarkMode ? 'white' : 'black';

  return {
    title: {
      text: '',
      left: 'center',
      top: '20',
      textStyle: {
        fontWeight: 'bold',
      },
    },
    tooltip: {
      backgroundColor: isDarkMode ? '#21242e' : 'lightgray',
      borderWidth: 0,
      trigger: 'item',
      axisPointer: {
        type: 'none',
      },
      textStyle: {
        color: isDarkMode ? 'white' : 'black',
        fontSize: 15,
      },
      formatter: function (params) {
        const color = params.color;
        const text = params.name;

        return `
          <span style="display:inline-block;width:10px;
           height:10px;background-color:${color};margin-right:5px;"></span>
          ${text}<br/>
        `;
      },
    },
    legend: {
      show: data.length > 0,
      orient: 'vertical',
      ...(isMobile ? { top: '60%' } : { left: '50%', top: 'center' }),
      icon: 'square',
      itemWidth: 10,
      itemHeight: 10,
      itemGap: 15,
      textStyle: {
        color: textColor,
        fontSize: 18,
        fontFamily: 'Courier New, monospace',
      },
    },
    grid: {
      containLabel: true,
    },
    series: [
      {
        ...(isMobile ? { top: '-40%' } : { left: '-50%' }),
        name: 'Detection Types',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        color: ['#FFC107', '#FF5722', '#4CAF50', '#2196F3', '#9C27B0', '#607D8B'],
        itemStyle: {
          borderRadius: 5,
          borderWidth: 3,
          borderColor: borderColor,
        },
        label: {
          show: false,
          position: 'inside',
          formatter: '{d}%',
          color: '#fff',
          fontWeight: 'bold',
          fontSize: 18,
        },
        labelLine: {
          show: true,
        },
        data: updatedData,
      },
    ],
  };
}

function formatString (input) {
  return input
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .replace(/\b[A-Z]/, match => match.toUpperCase());
}

const Chart = ({site}) => {
  const { isMobile } = useMobile();
  let { isDarkMode } = useTheme();
  const [chartOption, setChartOption] = useState(getOption([], isDarkMode, isMobile));

  useEffect(() => {
    let categories = {};

    for (let entries of site.data) {
      for (let entry of Object.values(entries)) {
        let cs = entry[5];

        for (let category of cs) {
          if (category.type in categories) {
            categories[category.type] += category.count;
          } else {
            categories[category.type] = category.count;
          }
        }
      }
    }

    let data = [];
    let total = 0;
    for (let category in categories) {
      data.push({name: formatString(category), value: categories[category]});
      total += categories[category];
    }

    for (let i = 0; i < data.length; i++) {
      data[i].value = (data[i].value / total) * 100;
    }

    setChartOption(getOption(data, isDarkMode, isMobile));
  }, [isDarkMode, site.data, isMobile]);

  return <ReactECharts option={chartOption}
                       notMerge={true}
                       style={{ width: '100%',
                                height: isMobile ? '500px' : '100%',
                              }} />;
};

export default Chart;
