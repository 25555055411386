import React from 'react';
import { ReactComponent as Spinner } from '../images/spinner.svg';

const Loading = ({ text = 'Loading...' }) => {
  return (
    <div style={{
           position: 'fixed',
           top: 0,
           left: 0,
           width: '100%',
           height: '100%',
           backgroundColor: '#21242e',
           display: 'flex',
           alignItems: 'center',
           justifyContent: 'center',
           zIndex: 9999,
         }}>
      <div style={{
             textAlign: 'center',
             color: 'white',
           }}>
        <Spinner style={{
                   margin: '0 auto',
                   width: '80px',
                   height: '80px',
                   animation: 'spin 1s linear infinite',
                 }} />
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Loading;
