import React, { useEffect } from 'react';
import CardCard from './CardCard';
import Input from '../Input';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTheme } from '../ThemeContext';
import { useMobile } from '../MobileContext';
import Modal from '../Modal';

import { ReactComponent as Spinner } from '../images/spinner2.svg';
import { ReactComponent as CloseIcon } from '../images/x.svg';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const ChangeCardModalForm = ({setChangeCardModalVisible, incrementPaymentVersion}) => {
  const { isMobile } = useMobile();
  const [name, setName] = React.useState('');
  const [paymentMethod, setPaymentMethod] = React.useState(null);
  const [customerId, setCustomerId] = React.useState('');
  const elements = useElements();
  const stripe = useStripe();
  const [processing, setProcessing] = React.useState(false);
  const [cardInputFocused, setCardInputFocused] = React.useState(false);
  const handleFocus = () => setCardInputFocused(true);
  const handleBlur = () => setCardInputFocused(false);

  const { isDarkMode } = useTheme();
  let border = isDarkMode ? 'transparent' : '1px solid #e3e3e3';

  useEffect(() => {
    const token = localStorage.getItem('token');

    fetch(process.env.REACT_APP_SERVER_URL + '/api/get-users', {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          let customerId = data.users[0].stripeCustomerId;
          setCustomerId(customerId);

          fetch(process.env.REACT_APP_SERVER_URL + '/api/get-customer-payment-method', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ customerId }),
          })
            .then(res => res.json())
            .then(data => {
              setPaymentMethod(data.paymentMethod)
            });
        }
      })
  }, []);

  const handleUpdate = async () => {
    setProcessing(true);
    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name
      },
    });

    if (error) {
      alert('Invalid card details');
      setProcessing(false);
      return;
    }

    fetch(process.env.REACT_APP_SERVER_URL + '/api/update-payment-method', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        customerId,
        paymentMethodId: paymentMethod.id,
      }),
    }).then(res => res.json())
      .then(data => {
        if (data.success) {
          incrementPaymentVersion();
          setChangeCardModalVisible(false);
          setProcessing(false);
        }
      });
  }

  return (
    <Modal isOpen={true}>
      <div style={{
             background: 'linear-gradient(gray, transparent)',
             zIndex: '101',
             position: 'absolute',
             transform: 'translate(-50%, -50%)',
             top: '50%',
             left: '50%',
             borderRadius: '10px',
             width: isMobile ? '95%' : 'fit-content',
           }}>
        <div className='modal'
             style={{
               margin: '1px',
               borderRadius: '10px',
               zIndex: '101',
               display: 'flex',
               justifyContent: 'center',
               flexDirection: 'column',
               gap: '10px',
               borderSizing: 'border-box',
               padding: '10px',
               width: 'calc(100% - 22px)',
             }}>
          <div style={{
                 display: 'flex',
                 flexDirection: 'row',
                 alignItems: 'center',
                 justifyContent: 'space-between',
                 width: '100%',
               }}>
            <h2>Change Card</h2>
            <CloseIcon className='image'
                       style={{width: '20px',
                               height: '20px',
                              }}
                       onClick={() => {
                         setChangeCardModalVisible(false);
                       }}/>
          </div>

          <CardCard paymentMethod={paymentMethod} inModal={true} />

          <Input label='CARDHOLDER NAME'
                 value={name}
                 onChange={(e) => {
                   setName(e.target.value);
                 }} />

          <div className='input'
               style={{ width: '100%',
                        padding: '20px 20px',
                        boxSizing: 'border-box',
                        borderRadius: '5px',
                        border: cardInputFocused ? '1px solid #009de6' : border,
                      }}>
            <CardElement
              onFocus={handleFocus}
              onBlur={handleBlur}
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#ffffff',
                    '::placeholder': {
                      color: '#aaaaaa',
                    },
                  }}}} />
          </div>

          <div style={{display: 'flex',
                       flexDirection: 'row',
                       alignItems: 'center',
                       gap: '10px',
                       justifyContent: 'center',
                       height: '40px',
                      }}>
            <div style={{alignItems: 'center',
                         border: '1px solid #e0e0e0',
                         borderRadius: '5px',
                         cursor: 'pointer',
                         fontWeight: 'bold',
                         alignSelf: 'flex-end',
                         height: '100%',
                         boxSizing: 'border-box',
                         display: 'flex',
                         justifyContent: 'center',
                         padding: '0 20px',
                         ...(isMobile ? { flexGrow: 1 } : { width: 'fit-content' }),
                        }}
                 onClick={() => {
                   setChangeCardModalVisible(false);
                 }}>
              Cancel
            </div>
            <div style={{alignItems: 'center',
                         backgroundColor: '#009de6',
                         color: 'white',
                         borderRadius: '5px',
                         cursor: 'pointer',
                         fontWeight: 'bold',
                         alignSelf: 'flex-end',
                         height: '100%',
                         boxSizing: 'border-box',
                         display: 'flex',
                         justifyContent: 'center',
                         padding: '0 20px',
                         ...(isMobile ? { flexGrow: 1 } : { width: 'fit-content' }),
                        }}
                 onClick={handleUpdate}>
              <div style={{
                     textAlign: 'center',
                     color: 'white',
                   }}>
                {processing && (
                  <Spinner style={{
                             margin: '0 auto',
                             width: '60px',
                             height: '30px',
                             animation: 'spin 1s linear infinite',
                           }} />
                )}
                {!processing && 'Update'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const ChangeCardModal = ({setChangeCardModalVisible, incrementPaymentVersion}) => {
  return (
    <Elements stripe={stripePromise}>
      <ChangeCardModalForm setChangeCardModalVisible={setChangeCardModalVisible}
                           incrementPaymentVersion={incrementPaymentVersion} />
    </Elements>
  );
}

export default ChangeCardModal;
