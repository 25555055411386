import React from 'react';

import { ReactComponent as Moon } from './images/moon.svg';
import { ReactComponent as Sun } from './images/sun.svg';

const Switch = ({checked, onChange}) => {
  return (
    <div style={{backgroundColor: checked ? '#21242e' : 'white',
                 border: `1px solid ${checked ? '#363942' : '#88898d'}`,
                 width: '70px',
                 height: '35px',
                 borderRadius: '20px',
                 position: 'relative',
                 padding: '2px',
                }}
         onClick={() => onChange()}>

      <Moon style={{width: checked ? '22px' : '18px',
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                    top: '50%',
                    left: '25%',
                    fill: checked ? '#009de6' : '#88898d',
                   }} />

      <Sun style={{width: checked ? '18px' : '22px',
                   position: 'absolute',
                   transform: 'translate(-50%, -50%)',
                   top: '50%',
                   left: '75%',
                   fill: checked ? '#88898d' : '#009de6',
                  }} />


      <div style={{border: '1px solid #009de6',
                   background: 'radial-gradient(circle at center, transparent, #009de6 200%)',
                   width: '30px',
                   height: '30px',
                   borderRadius: '20px',
                   position: 'absolute',
                   transform: `translate(-50%, -50%)`,
                   top: '50%',
                   left: checked ? '25%' : '75%',
                  }}>
      </div>
    </div>
  );
}

export default Switch;
