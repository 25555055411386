import React, { useEffect } from 'react';
import AccountButtons from './AccountButtons';
import Question from './help/Question';
import { useMobile } from './MobileContext';

import { ReactComponent as SideIcon } from './images/sideArrow.svg';
import { ReactComponent as ChatIcon } from './images/chat.svg';
import { ReactComponent as MailIcon } from './images/mail.svg';
import { ReactComponent as PhoneIcon } from './images/phone.svg';

const Help = ({setPage}) => {
  const { isMobile } = useMobile();
  let [questions, setQuestions] = React.useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');

    fetch(process.env.REACT_APP_SERVER_URL + '/api/get-faq', {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setQuestions(data.faq);
        }
      })
      .catch(error => console.log(error));

  }, []);

  return (
    <div style={{
           flexGrow: 1,
           padding: '20px',
           overflowY: 'auto',
           height: '100%',
           display: 'flex',
           flexDirection: 'column',
           gap: '15px',
         }}>

      {!isMobile && (
        <div style={{ width: '100%',
                      display: 'flex',
                      flexDirection: 'row-reverse',
                    }}>
          <AccountButtons setPage={setPage} />
        </div>
      )}

      {isMobile && <h3>Frequently Asked Questions</h3>}
      {!isMobile && <h2>Frequently Asked Questions</h2>}

      {questions.map((q, i) => <Question key={i}
                                         question={q.question}
                                         answer={q.answer} />)}

      {isMobile && <h3>Help & Feedback</h3>}
      {!isMobile && <h2>Help & Feedback</h2>}

      <div style={{display: 'flex',
                   flexDirection: isMobile ? 'column' : 'row',
                   gap: '10px',
                   width: '100%',
                  }}>

        <div className='selected-box'
             style={{cursor: 'pointer',
                     padding: '20px',
                     borderRadius: '5px',
                     alignItems: 'center',
                     display: 'flex',
                     width: isMobile ? '100%' : '33%',
                     boxSizing: 'border-box',
                     justifyContent: 'space-between',
                    }}>
          <div style={{display: 'flex',
                       alignItems: 'center',
                      }}>
            <ChatIcon style={{width: '20px',
                              height: '20px',
                              marginRight: '10px',
                              fill: '#009de6',
                             }} />
            Live chat
          </div>
          <SideIcon style={{width: '12px',
                            height: '12px',
                            stroke: 'white'
                           }} />
        </div>

        <div className='selected-box'
             style={{cursor: 'pointer',
                     padding: '20px',
                     borderRadius: '5px',
                     alignItems: 'center',
                     display: 'flex',
                     width: isMobile ? '100%' : '33%',
                     boxSizing: 'border-box',
                     justifyContent: 'space-between',
                    }}>
          <div style={{display: 'flex',
                       alignItems: 'center',
                      }}>
            <MailIcon style={{width: '20px',
                              height: '20px',
                              marginRight: '10px',
                              fill: '#009de6',
                             }} />
            team@polygraph.net
          </div>
          <SideIcon style={{width: '12px',
                            height: '12px',
                            stroke: 'white'
                           }} />
        </div>

        <div className='selected-box'
             style={{cursor: 'pointer',
                     padding: '20px',
                     borderRadius: '5px',
                     alignItems: 'center',
                     display: 'flex',
                     width: isMobile ? '100%' : '33%',
                     boxSizing: 'border-box',
                     justifyContent: 'space-between',
                    }}>
          <div style={{display: 'flex',
                       alignItems: 'center',
                      }}>
            <PhoneIcon style={{width: '20px',
                               height: '20px',
                               marginRight: '10px',
                               fill: '#009de6',
                              }} />
            +49 (030) 2204 4851
          </div>
          <SideIcon style={{width: '12px',
                            height: '12px',
                            stroke: 'white'
                           }} />
        </div>

      </div>
    </div>
  );
};

export default Help;
