import React from 'react';

import Image1 from '../images/image1.png';
import Image2 from '../images/image2.png';
import Image3 from '../images/image3.png';
import Image4 from '../images/image4.png';
import Image5 from '../images/image5.png';
import Image6 from '../images/image6.png';

const ImageCarousel = ({set2}) => {
  let images = [Image1, Image2, Image3, Image4, Image5, Image6];
  const offset = set2 ? 3 : 0;

  return (
    <div style={{backgroundColor: 'lightgray',
                 width: '50%',
                 height: '100%',
                 borderRadius: '10px',
                 overflow: 'hidden',
                 position: 'relative',
                }}>
      <style>{`@keyframes carrousel {
                 0% {
                   transform: translateX(0%);
                 }
                 10% {
                   transform: translateX(-100%);
                 }
                 33% {
                   transform: translateX(-100%);
                 }
                 43% {
                   transform: translateX(-200%);
                 }
                 66% {
                   transform: translateX(-200%);
                 }
                 76% {
                   transform: translateX(-300%);
                 }
                 100% {
                   transform: translateX(-300%);
                 }
               }`}
      </style>
      <div style={{width: '100%',
                   height: '100%',
                   position: 'absolute',
                   animation: 'carrousel 20s linear infinite',
                   left: '-0%',
                  }}>
        {Array(4).fill().map((_, i) => {
          return (
            <img src={images[i % 3 + offset]}
                 alt='carousel'
                 key={i}
                 style={{width: '100%',
                         height: '100%',
                         objectFit: 'cover',
                         borderRadius: '5px',
                         position: 'absolute',
                         top: 0,
                         left: `${i * 100}%`,
                        }} />
          );
        })}
      </div>
    </div>
  );
};

export default ImageCarousel;
