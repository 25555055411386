import React from 'react';

import { ReactComponent as UpIcon } from './images/up.svg';
import { ReactComponent as DownIcon } from './images/down.svg';

const PercentageBox = ({percentage, red}) => {
  let percentageDisplay;

  if (percentage === null) {
    percentage = 0;
    percentageDisplay = '--';
  }
  else {
    if (percentage === Infinity) {
      percentageDisplay = '∞%';
    }
    else if (percentage === -Infinity) {
      percentageDisplay = '-∞%';
    }
    else {
      if (percentage > 0) {
        percentageDisplay = `+${percentage.toFixed(2)}%`;
      }
      else {
        percentageDisplay = `${percentage.toFixed(2)}%`;
      }
    }
  }

  return (
    <div className={`${red ? 'red' : 'blue'}-percentage-box`}
         style={{height: '10px',
                 display: 'flex',
                 alignItems: 'center',
                 justifyContent: 'space-between',
                 gap: '5px',
                 padding: '10px',
                 borderRadius: '5px',
                }}>
      {percentage > 0 ?
       <UpIcon style={{width: '15px',
                       height: '15px',
                       fill: red ? '#d42c2c' : '#009de6',
                      }}
       /> :
       <DownIcon style={{width: '15px',
                         height: '15px',
                         fill: red ? '#d42c2c' : '#009de6',
                        }}
       />
      }
      <span style={{fontSize: '20px',
                    fontWeight: 'bold',
                    color: red ? '#d42c2c' : '#009de6'}}>
        {percentageDisplay}
      </span>
    </div>
  );
}

export default PercentageBox;
