import React from 'react';
import { ReactComponent as OffImage } from './images/checkbox-off.svg';
import { ReactComponent as OnImage } from './images/checkbox-on.svg';

const CheckBox = ({onChange, checked}) => {

  const handleOnChange = () => {
    onChange({target: {checked: !checked}});
  }

  return (
    checked ?
      <OnImage style={{width: '20px',
                       height: '20px',
                       fill: 'black',
                      }}
               onClick={handleOnChange} />
    :
    <OffImage style={{width: '20px',
                      height: '20px',
                      fill: 'black',
                     }}
              onClick={handleOnChange} />
  );
}

export default CheckBox;
