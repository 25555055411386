import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import * as utils from '../../utils';
import { useTheme } from '../../ThemeContext'
import { useMobile } from '../../MobileContext';
import Modal from '../../Modal';

import { ReactComponent as ExpandIcon } from '../../images/expand.svg';
import { ReactComponent as MinimizeIcon } from '../../images/minimize.svg';

const getOption = (specs, isDarkMode, isMobile) => {
  let {values, lastDay} = specs;
  let length = values[0]?.data?.length || 0;
  let interval = length > 35 ? 30 : 3;

  if (values.length === 0) {
    values = [{name: 'No Data',
               data: [0]}];
  }

  return {
    tooltip: {
      confine: true,
      backgroundColor: isDarkMode ? '#21242e' : 'lightgray',
      borderWidth: 0,
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
      textStyle: {
        color: isDarkMode ? 'white' : 'black',
        fontSize: 15,
      },
      formatter: function (params) {
        let tooltipContent = '';

        const date = new Date(params[0].name + " 2024");
        const month = date.toLocaleString('default', { month: 'long' });
        const day = date.getDate();
        tooltipContent += `${month} ${day} <br/>`;

        let allItems = params.sort((a, b) => b.value - a.value);

        let n = 5;
        let items = allItems.slice(0, n);
        let otherItems = allItems.slice(n);

        let value = 0;
        otherItems.forEach((item) => {
          value += item.value;
        });

        items.forEach((item) => {
          const color = item.color;
          const name = item.seriesName;
          const value = item.value;
          tooltipContent += `
          <span style="display:inline-block;width:10px;
           height:10px;background-color:${color};margin-right:5px;"></span>
          ${name}: ${value}<br/>
        `;
        });

        tooltipContent += `Other: ${value}`;

        return tooltipContent;
      },
    },
    legend: {
      type: 'scroll',
      data: values.map((item) => {
        return {name: item.name,
                icon: 'square',
               };
      }),
      textStyle: {
        color: isDarkMode ? 'white' : 'black',
        fontWeight: 'bold',
        fontSize: 15,
      },
      itemWidth: 10,
      itemHeight: 10,
      itemGap: 30,
      left: 'left',
      bottom: '12%',
    },
    xAxis: {
      name: '',
      type: 'category',
      data: utils.getFormattedDateArray(lastDay, length),
      axisLine: {
        lineStyle: {
          color: isDarkMode ? '#555' : '#bbb',
        },
      },
      axisLabel: {
        color: isDarkMode ? 'white' : 'black',
        boundaryGap: false,
        interval,
      },
      boundaryGap: false,
    },
    yAxis: {
      name: 'Fraud Clicks',
      nameTextStyle: {
        color: isDarkMode ? 'white' : 'black',
      },
      type: 'value',
      axisLine: {
        show: false,
      },
      axisLabel: {
        color: isDarkMode ? 'white' : 'black',
        formatter: '{value}',
      },
      splitLine: {
        lineStyle: {
          color: isDarkMode ? '#333' : '#ddd',
          type: 'dashed',
          width: 2,
        },
      },
    },
    grid: {
      left: '3%',
      right: '2%',
      top: '10%',
      bottom: '22%',
      containLabel: true,
    },
    color: ['#009de6', '#ff8a00', '#8d89ff',
            '#FFC107', '#FF5722', '#4CAF50',
            '#2196F3', '#9C27B0', '#607D8B'],
    series: values.map((item) => {
      return {
        name: item.name,
        symbol: 'circle',
        symbolSize: 10,
        type: 'line',
        data: item.data,
        smooth: true,
        showSymbol: false,
        lineStyle: {
          width: isMobile ? 2 : 3,
        },
      };
    }),
    backgroundColor: 'transparent',
  }
}

const Graph = ({specs}) => {
  const { isMobile } = useMobile();
  let { isDarkMode } = useTheme();
  const [expanded, setExpanded] = React.useState(false);
  const [chartOption, setChartOption] = useState(getOption(specs, isDarkMode, isMobile));

  const icon = expanded ?
    <MinimizeIcon className="image"
                  style={{width: '20px',
                          height: '20px',
                         }}
                  onClick={() => setExpanded(false)} />
        :
  <ExpandIcon className="image"
              style={{width: '20px',
                      height: '20px',
                     }}
              onClick={() => setExpanded(true)} />;

  const graph = (
    <div style={{height: 'calc(100% - 40px)',
                }}>
      <div style={{display: 'flex',
                   flexDirection: 'row',
                   alignItems: 'center',
                   justifyContent: 'space-between',
                   padding: '20px',
                  }}>
        <span>{specs.title}</span>
        {icon}
      </div>
      <ReactECharts option={chartOption}
                    notMerge={true}
                    style={{ height: '95%',
                             width: '100%',
                             flexGrow: 1,
                           }} />
    </div>
  );

  useEffect(() => {
    setChartOption(getOption(specs, isDarkMode, isMobile));
  }, [isDarkMode, isMobile, specs]);

  return (
    <>
      {expanded && (
        <Modal isOpen={expanded}>
          <div style={{width: isMobile ? '100vw' : '80vw',
                       height: isMobile ? '100vh' : '80vh',
                       overflowY: 'auto',
                       backgroundColor: isDarkMode ? 'black' : 'white',
                       display: 'flex',
                       flexDirection: 'column',
                       justifyContent: 'space-between',
                      }} >
            {graph}
          </div>
        </Modal>
      )}

      {!expanded && graph}
    </>
  );
}

export default Graph;
