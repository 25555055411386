import React, { useEffect } from 'react';
import Separator from '../Separator';
import { ReactComponent as CloudIcon } from '../images/cloud.svg';
import * as utils from '../utils';
import { useMobile } from '../MobileContext';

const Item = ({text}) => {
  return (
    <span style={{width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                 }}>
      {text}
    </span>
  );
}

const MostRecentActivity = ({site}) => {
  const { isMobile } = useMobile();
  let [data, setData] = React.useState([]);

  const handleDownload = () => {
    let rows = ['Date/Time (Local)',
                'Date/Time (Unix)',
                'IP Address',
                'Source',
                'Keyword',
                'Referer',
                'Reason'];

    let content = rows.join(',') + '\n';

    data.forEach((item) => {
      rows.forEach((row) => {
        content += item[row] + ',';
      });
      content += '\n';
    });

    const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', 'data.csv');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    let result = [];

    for (let entries of site.data) {
      for (let entry of Object.values(entries)) {
        let as = entry[4];
        for (let activity of as) {
          result.push(activity);
        }
      }
    }

    result = result.sort((a, b) => b['Date/Time (Unix)'] - a['Date/Time (Unix)']);
    result = result.slice(0, 5);

    setData(result);
  }, [site]);

  return (
    <>
      <div style={{display: 'flex',
                   flexDirection: 'row',
                   gap: '20px',
                   justifyContent: 'space-between',
                   alignItems: 'center',
                  }}>
        <h3>Most recent activity</h3>

        <div style={{display: 'flex',
                     flexDirection: 'row',
                     gap: '10px',
                     alignItems: 'center',
                     justifyContent: 'center',
                    }}>

          <CloudIcon className='image'
                     style={{width: '20px',
                             height: '20px',
                             padding: '10px',
                             cursor: 'pointer',
                            }}
                     onClick={() => handleDownload()} />
        </div>
      </div>

      <div style={{display: 'flex',
                   flexDirection: 'column',
                   gap: '10px',
                   boxSizing: 'border-box',
                   height: '390px',
                   marginBottom: '50px',
                   overflow: 'hidden',
                   ...(isMobile ? {fontSize: '9px'} : {}),
                  }}>
        <div className='f-box'
             style={{
               display: 'flex',
               width: '100%',
               flexDirection: 'row',
               justifyContent: 'space-between',
               boxSizing: 'border-box',
               padding: '10px 5px',
               borderRadius: '5px',
             }}>
          {!isMobile && (
            <>
              <Item text='Date/Time (Local)' />
              <Item text='Date/Time (Unix)' />
            </>
          )}
          {isMobile && <Item text='Date' /> }

          {!isMobile && <Item text='IP Address' /> }
          {isMobile && <Item text='IP' /> }

          <Item text='Source' />
          <Item text='Keyword' />
          <Item text='Referer' />
          <Item text='Reason' />
        </div>

        {data.map((item, index) => {
          return (
            <div key={index}>
              <div style={{
                     display: 'flex',
                     width: '100%',
                     flexDirection: 'row',
                     justifyContent: 'space-between',
                     boxSizing: 'border-box',
                     margin: '5px',
                   }}>
                <Item text={utils.unixToDate(item['Date/Time (Unix)'])} />
                {!isMobile && <Item text={item['Date/Time (Unix)']} /> }
                <Item text={item['IP Address']} />
                <Item text={item['Source']} />
                <Item text={item['Keyword']} />
                <Item text={item['Referer']} />
                <Item text={item['Reason'].slice(0, 20)} />
              </div>
              <Separator />
            </div>
          );
        })}
      </div>
    </>
  );
}

export default MostRecentActivity;
