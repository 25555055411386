import React from 'react';
import Shield from '../Shield';
import TrafficButtons from '../TrafficButtons';
import PercentageBox from '../PercentageBox';
import IconBox from '../IconBox';
import * as utils from '../utils';

import { ReactComponent as DragHandleIcon } from '../images/dragHandle.svg';
import { ReactComponent as BotIcon } from '../images/bot.svg';

const SiteCard = ({ site, dragHandleProps, setPage }) => {
  let botClicks = 0;
  let humanClicks = 0;

  for (let i = 0; i < site.data.length; i++) {
    for (const value of Object.values(site.data[i])) {
      humanClicks += value[0];
      botClicks += value[1];
    }
  }

  let totalClicks = botClicks + humanClicks;
  let clickPercentage = 0;

  if (totalClicks !== 0) {
    clickPercentage = (botClicks / totalClicks) * 100;
  }

  let percentage = site.oldCounts ? utils.percentageIncrease(site.oldCounts[1], botClicks) : null;

  return (
    <div style={{
           padding: '20px',
           display: 'flex',
           flexDirection: 'row',
           justifyContent: 'space-between',
           gap: '20px',
         }}>

      <div style={{display: 'flex',
                   flexDirection: 'column',
                   gap: '20px',
                   width: '50%',
                   cursor: 'pointer',
                  }}
           onClick={() => setPage('site_' + site.id)} >
        <div style={{width: '500px',
                     height: '50px',
                     display: 'flex',
                     flexDirection: 'row',
                     gap: '10px',
                     alignItems: 'center',
                    }}>
          <div {...dragHandleProps} style={{ cursor: 'grab' }}>
            <DragHandleIcon style={{ width: '15px',
                                     height: '15px',
                                     fill: 'gray'
                                   }}/>
          </div>
          <IconBox website={site.website} />
          <span style={{fontWeight: 'bold',
                        fontSize: '20px',
                       }}>
            {site.website}
          </span>

        </div>
        <div style={{width: '300px',
                     height: '50px',
                     display: 'flex',
                     flexDirection: 'row',
                     gap: '10px',
                     alignItems: 'center',
                    }}>
          <Shield fraudLevel={site.fraudLevel} />
          {site.fraudLevel} fraud level
        </div>
      </div>

      <div style={{display: 'flex',
                   flexDirection: 'column',
                   gap: '20px',
                   width: '30%',
                  }}>
        <div style={{width: '300px',
                     height: '50px',
                     display: 'flex',
                     flexDirection: 'row',
                     gap: '10px',
                     alignItems: 'center',
                     justifyContent: 'center',
                    }}>
          <BotIcon style={{ fill: '#009de6',
                            width: '30px',
                            height: '30px',
                          }} />
          <span style={{fontSize: '20px', }}>
            {botClicks.toLocaleString()}
          </span>
          {clickPercentage !== undefined && (
            <>
              <span style={{fontSize: '20px', }}>
                |
              </span>
              <span style={{fontSize: '20px', }}>
                {clickPercentage.toFixed(2)}%
              </span>
            </>
          )}
        </div>
        <div style={{width: '300px',
                     height: '50px',
                     display: 'flex',
                     flexDirection: 'row',
                     gap: '10px',
                     alignItems: 'center',
                     justifyContent: 'center',
                    }}>
          <PercentageBox percentage={percentage} red={percentage > 0} />
          <span>vs last month</span>
        </div>
      </div>

      <div style={{width: '20%',
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'flex-end',
                  }}>
        <TrafficButtons gap={'30px'} site={site}/>
      </div>
    </div>
  );
}

export default SiteCard;
