import React, { useEffect } from 'react';
import DropDown from './DropDown';
import OverView from './site-overview/Overview';
import Analytics from './site-overview/Analytics';
import MostRecentActivity from './site-overview/MostRecentActivity';
import DetectionTypes from './site-overview/DetectionTypes';
import AccountButtons from './AccountButtons';
import * as utils from './utils';
import { useSites } from './SitesContext';
import { useMobile } from './MobileContext';

const SiteOverview = ({site, setPage}) => {
  const { isMobile } = useMobile();
  let [campaigns, setCampaigns] = React.useState([]);
  let [platforms, setPlatforms] = React.useState([]);
  const { getCampaigns, getPlatforms } = useSites();

  useEffect(() => {
    setCampaigns(getCampaigns(site.website));
    setPlatforms(getPlatforms(site.website));
  }, [site, getCampaigns, getPlatforms])

  return (
    <div style={{
           flexGrow: 1,
           overflowY: 'auto',
           padding: '20px',
         }}>
      <div style={{
             display: 'flex',
             flexDirection: 'row',
             justifyContent: 'space-between',
           }}>
        <div style={{
               display: 'flex',
               flexDirection: isMobile ? 'column' : 'row',
               gap: '20px',
               width: '100%',
             }}>
          <DropDown id='period' title='Period' options={utils.generatePeriodOptions()} />
          <DropDown id='platform' title='Platform' options={platforms} />
          <DropDown id='campaign' title='Campaign' options={campaigns} />
        </div>

        {!isMobile &&  <AccountButtons setPage={setPage} />}
      </div>

      <OverView site={site} />
      <Analytics site={site} />
      <MostRecentActivity site={site} />
      <DetectionTypes site={site} />

    </div>
  );
}

export default SiteOverview;
