import React from 'react';
import IconBox from '../../IconBox';
import TrafficButtons from '../../TrafficButtons';
import Shield from '../../Shield';

const TitleCard = ({site}) => {
  return (
    <div className='gradient'
         style={{borderRadius: '10px',
                 gap: '10px',
                 display: 'flex',
                 flexDirection: 'column',
                 padding: '10px',
                 alignItems: 'flex-start',
                }}>
      <div style={{display: 'flex',
                   flexDirection: 'row',
                   alignItems: 'center',
                   width: '100%',
                   justifyContent: 'space-between',
                   padding: '10px',
                   gap: '10px',
                   cursor: 'pointer',
                   borderRadius: '5px',
                   boxSizing: 'border-box',
                  }}>
        <div style={{display: 'flex',
                     flexDirection: 'row',
                     gap: '10px',
                     alignItems: 'center',
                    }}>
          <IconBox website={site.website} />
          <span style={{fontSize: '20px',
                       }}>
            {site.website}
          </span>
        </div>
        <Shield fraudLevel={site.fraudLevel} />
      </div>
      <TrafficButtons direction='row' site={site}/>
    </div>
  );
}

export default TitleCard;
