import React from 'react'
import PercentageBox from '../../PercentageBox';
import * as utils from '../../utils';

const TrafficWhenStartedCard = ({site}) => {
  const getPercentage = values => {
    if (values.length >= 60) {
      let first30 = values.slice(0, 30);
      let last30 = values.slice(values.length - 30, values.length);

      let first30Sum = first30.reduce((a, b) => a + b, 0);
      let last30Sum = last30.reduce((a, b) => a + b, 0);

      return utils.percentageIncrease(first30Sum, last30Sum);
    }
    return null;
  }

  let {bots, human} = site.lifetimeCounts;
  let humanPercentage = getPercentage(human) || 0;
  let botPercentage = getPercentage(bots) || 0;

  return (
    <>
      <div className='gradient'
           style={{width: '100%',
                   height: '100%',
                   borderRadius: '10px',
                   boxSizing: 'border-box',
                   padding: '20px',
                   display: 'flex',
                   flexDirection: 'column',
                   gap: '10px',
                  }}>
        <span>TRAFFIC CHANGE SINCE BEGINNING</span>
        <div style={{display: 'flex',
                     gap: '10px',
                    }}>

          <div style={{width: '50%',
                       display: 'flex',
                       flexDirection: 'column',
                       gap: '10px',
                      }}>
            <span>HUMAN TRAFFIC</span>
            <PercentageBox percentage={humanPercentage} />
          </div>

          <div style={{width: '50%',
                       display: 'flex',
                       flexDirection: 'column',
                       gap: '10px',
                      }}>
            <span>BOT TRAFFIC</span>
            <PercentageBox percentage={botPercentage} />
          </div>
        </div>
        <span style={{fontSize: '12px',
                      color: '#888',
                     }}>
          * The data shows the progress since the beginning of using Polygraph compared to the selected period.
        </span>
      </div>
    </>
  )
}

export default TrafficWhenStartedCard
