import React, { useEffect, useState } from 'react';
import TimeSelector from './TimeSelector';
import ReactECharts from 'echarts-for-react';
import * as charts from 'echarts';
import { useTheme } from '../../ThemeContext';
import { useMobile } from '../../MobileContext';

function getMarkpoint (value, colors, minutes) {
  return (
    {
      data: [
        { coord: [minutes - 1, value],
          symbol: 'circle',
          symbolSize: 30,
          itemStyle: {
            color: new charts.graphic.RadialGradient(0.5, 0.5, 0.5, [
              { offset: 0, color: colors[0] },
              { offset: 1, color: 'rgba(0, 0, 0, 0)' },
            ]),
          },
        },

        { coord: [minutes - 1, value],
          symbol: 'circle',
          symbolSize: 10,
          itemStyle: {
            color: colors[0],
          },
        },

        { coord: [minutes - 1, value],
          symbol: 'circle',
          symbolSize: 6,
          itemStyle: {
            color: colors[1]
          },
        },
      ],
    }
  );
}

function getMinutesAxis (minutes) {
  let array = [...Array(minutes).keys()]
  array = array.map(x => -(minutes - x - 1));
  array.push('');
  return array;
}

const getOption = (data, isDarkMode, time) => {
  let humanData = data.map(x => x.human);
  let botData = data.map(x => x.bot);

  let minutes;
  if (time === '1h') {
    minutes = 60;
  } else {
    minutes = parseInt(time.slice(0, -1));
  }

  return {
    axisPointer: {
      type: 'none',
    },
    title: {
      text: '',
    },
    grid: {
      left: '0%',
      right: '0%',
      top: '10%',
      bottom: '15%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: '#777',
        },
      },
      axisLabel: {
        color: '#aaa',
      },
      data: getMinutesAxis(minutes),
    },
    yAxis: {
      type: 'value',
      name: 'Clicks',
      nameTextStyle: {
        color: isDarkMode ? 'white' : 'black',
        fontSize: 15,
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: isDarkMode ? '#333' : '#ddd',
        },
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        color: isDarkMode ? 'white' : 'black',
      },
    },
    series: [
      {
        name: 'Bots',
        type: 'line',
        data: botData.slice(-minutes),
        smooth: true,
        color: 'rgb(255, 77, 77)',
        lineStyle: {
          width: 2,
          color: new charts.graphic.LinearGradient(0, 0, 1, 0, [
            { offset: 0, color: 'rgba(255, 77, 77, 0.2)' },
            { offset: 1, color: 'rgba(255, 77, 77, 1)' },
          ]),
        },
        showSymbol: false,
        markPoint: getMarkpoint(botData[botData.length - 1],
                                ['rgb(255, 77, 77)', isDarkMode ? 'black' : 'white'],
                                minutes),
      },
      {
        name: 'Human',
        type: 'line',
        data: humanData.slice(-minutes),
        smooth: true,
        color: '#009de6',
        lineStyle: {
          width: 2,
          color: new charts.graphic.LinearGradient(0, 0, 1, 0, [
            { offset: 0, color: 'rgba(0, 157, 230, 0.2)' },
            { offset: 1, color: '#009de6' },
          ]),
        },
        showSymbol: false,
        markPoint: getMarkpoint(humanData[humanData.length - 1],
                                ['#009de6', isDarkMode ? 'black' : 'white'],
                                minutes),
      }
    ],
    legend: {
      data: ['Human', 'Bots'],
      bottom: '5%',
      left: '0%',
      textStyle: {
        color: isDarkMode ? 'white' : 'black',
        fontSize: 15,
      },
      itemWidth: 8,
      itemHeight: 8,
      icon: 'square',
    },
  }
}

const LiveBotTrackingGraph = () => {
  const { isMobile } = useMobile();
  const { isDarkMode } = useTheme();
  let [data, setData] = useState(new Array(60).fill({bot: 0, human: 0}));

  let times = ['1h', '30m', '20m', '10m'];
  const [selected, setSelected] = useState(times[0]);
  const [chartOption, setChartOption] = useState(getOption(data, isDarkMode, selected));

  function updateData () {
    const token = localStorage.getItem('token');

    fetch(process.env.REACT_APP_SERVER_URL + '/api/get-live-data', {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({website: 'polygraph.net'}),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setData(data.data);
        }
      })
  }

  useEffect(() => {
    updateData();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => updateData(), 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setChartOption(getOption(data, isDarkMode, selected));
  }, [isDarkMode, data, selected]);

  return (
    <div style={{width: '100%',
                 height: isMobile ? '300px' : '100%',
                 borderRadius: '10px',
                 display: 'flex',
                 flexDirection: 'column',
                 boxSizing: 'border-box',
                }}>

      <div style={{display: 'flex',
                   flexDirection: 'row',
                   justifyContent: 'space-between',
                  }}>
        LIVE BOT TRACKING
        <TimeSelector times={times} selected={selected} setSelected={setSelected} />
      </div>
      <div style={{flex: 1}}>

        <ReactECharts option={chartOption}
                      notMerge={true}
                      style={{ width: '100%',
                               height: '100%' }} />
      </div>
    </div>
  );
};

export default LiveBotTrackingGraph;
