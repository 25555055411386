import React from 'react';
import { ReactComponent as ShieldIcon } from './images/shield.svg';
import { ReactComponent as ShieldCheckIcon } from './images/shield-check.svg';

const Shield = ({fraudLevel}) => {
  if (fraudLevel === 'High') {
    return (
      <ShieldIcon style={{ fill: '#d42c2c',
                           width: '30px',
                           height: '30px' }} />

    );
  }
  else if (fraudLevel === 'Medium') {
    return (
      <ShieldIcon style={{ fill: '#d97430',
                           width: '30px',
                           height: '30px'  }} />
    );
  }
  else {
    return (
      <ShieldCheckIcon style={{ fill: '#009de6',
                                width: '30px',
                                height: '30px' }} />
    );
  }
}

export default Shield;
