import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {

  if (!isOpen) return null;

  return (
    <div style={{position: 'fixed',
                 top: 0,
                 left: 0,
                 width: '100vw',
                 height: '100vh',
                 backgroundColor: 'rgba(0, 0, 0, 0.8)',
                 display: 'flex',
                 alignItems: 'center',
                 justifyContent: 'center',
                 zIndex: 1000,
                }} onClick={onClose}>

      <div style={{maxHeight: '100vh',
                   overflowY: 'auto',
                   backgroundColor: 'white',
                  }}
           onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
}


export default Modal;
