import React from 'react';
import IconBox from '../IconBox';
import ScrollingText from '../ScrollingText';

import { ReactComponent as DragHandleIcon } from '../images/dragHandle.svg';

const Tab = ({ site, dragHandleProps, index, page, setPage }) => {
  return (
    <div className={`${page === 'site_' + site.index ? '' : 'un'}selected-box`}
         style={{
           display: 'flex',
           flexDirection: 'row',
           alignItems: 'center',
           padding: '10px',
           gap: '10px',
           cursor: 'pointer',
           borderRadius: '5px',
           color: '#aaa',
         }}
         onClick={() => setPage('site_' + site.id)}>
      <div {...dragHandleProps} style={{ cursor: 'grab' }}>
        <DragHandleIcon style={{ width: '15px',
                                 height: '15px',
                                 fill: 'gray'
                               }}/>
      </div>
      <IconBox website={site.website}/>
      <ScrollingText text={site.website} width={170} />
    </div>
  );
};

export default Tab;
