import React, { useState } from 'react';
import { useTheme } from './ThemeContext';

const Input = ({label, icon, placeholder, value, onChange, type, borderColor}) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const { isDarkMode } = useTheme();

  let color = isDarkMode ? 'transparent' : '#e3e3e3';

  if (isFocused) {
    if (borderColor) {
      color = borderColor
    }
    else {
      color = '#009de6';
    }
  }

  return (
    <div className='input'
         style={{borderRadius: '5px',
                 marginTop: '10px',
                 overflow: 'auto',
                 whiteSpace: 'pre-wrap',
                 border: `1px solid ${color}`,
                 position: 'relative',
                 boxSizing: 'border-box',
                 width: '100%',
                }}>
      {label && (
        <span style={{fontSize: '10px',
                      paddingLeft: '10px',
                      fontWeight: 'bold' }}>
          {label}
        </span>
      )}

      <input type={type ? type : 'text'}
             onFocus={handleFocus}
             onBlur={handleBlur}
             placeholder={placeholder}
             value={value}
             onChange={onChange}
             style={{width: '100%',
                     padding: '10px',
                     boxSizing: 'border-box',
                     border: 'none',
                     outline: 'none',
                     backgroundColor: 'transparent',
                     color: 'inherit',
                     fontFamily: 'inherit',
                    }} />
      {icon && (
        <div style={{position: 'absolute',
                     right: '10px',
                     transform: 'translate(0, -50%)',
                     top: '50%',
                    }}>
          {icon}
        </div>
      )}
    </div>
  );
}

export default Input;
